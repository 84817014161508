import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
  useDisclosure,
  Stack,
  Fieldset
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { useEnvironment } from "../../context/EnvironmentContext";
import e from "express";
import { Toaster, toaster } from "../ui/toaster";
import { Checkbox } from "../ui/checkbox";
import { DialogBackdrop, DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";


interface Application {
  id: string;
  ttl: number;
  enabled: boolean | undefined;
}
interface UserAppsDialogProps {
  user: User;
  cb: Function;
}
export interface Type {
  id: string,
  name: string,
}
export interface User {
  id: string,
  account_id: string,
  applications: []
  created: string,
  created_by_id: string
  department: string
  email_address: string,
  first_name: string,
  last_name: string,
  full_name: string,
  groups: [],
  initiated: boolean,
  status: number,
  type: Type,
  type_id: string,
  user_type_id: string,
}


export default function UserAppPermissionsModal(props: UserAppsDialogProps) {
  const { open, onOpen, onClose } = useDisclosure();
  const [applications, setApplications] = useState<Application[]>([
    { id: "echo", ttl: 1235, enabled: false },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [changes, setChanges] = useState<any[]>([]);
  const CacheContext = useCache();
  const [state_change, setState_change] = useState<number>(0); //doesn't do anything but without it the checkbox won't update! 

  // useEffect(() => {
  //   console.log(state_change)
  // },[state_change]);

  const webHelpers = useWebHelper();
  const handleClickOpen = () => {
    setChanges([]);
    webHelpers.GetAsync(
      "/api/management/applications",
      "auth"
    ).then((data: any) => {
      setApplications(data);
      data.forEach((each: any) => {
        props.user.applications.forEach((app: Application) => {
          if (app.id == each.id) {
            each.enabled = true;
          }
        });
      });
      onOpen();
    }).catch((error) => {
      toaster.create({

        title: `Error fetching applications, error: [${error}]`,
        type: "error",

      });
    });

  };

  const handleClose = () => {
    changes.forEach((each) => {
      if (each[1] === false) {
        revokeApp(each[0]);
      }
      else {
        assignApp(each[0]);
      }
    })
    onClose();
    props.cb();
  }

  function handleRadioChange(appId: string, value: boolean) {
    console.log("appid = ", appId, " , value = ", value)
    let search = changes.some(item => appId === item[0]);
    if (search) {
      changes[changes.findIndex((item => item[0] === appId))][1] = value;
    }
    else {
      setChanges(changes => [...changes, [appId, value]])
    }
    console.log(changes)
  }
  function assignApp(id: string) {
    webHelpers.PostAsync(
      "/api/management/application/" + id + "/assign/" + props.user.id,
      "auth",
      {}
    ).then((res) => {
      props.cb();
    }).catch((error) => {

      toaster.create({

        title: `Error setting applications, error: [${error}]`,
        type: "error",

      });
    });
  }

  function revokeApp(id: string) {
    webHelpers.PostAsync(
      "/api/management/application/" + id + "/revoke/" + props.user.id,
      "auth",
      {}
    ).then((res) => {
      props.cb();
    }).catch((error) => {
      toaster.create({

        title: `Error removing applications, error: [${error}]`,
        type: "error",

      });
    });
  }

  function userHasApplicationCheck(id: string) {
    let found = false;
    props.user.applications.forEach((app: Application) => {
      if (app.id == id) {
        found = true;

      }
    });
    return found;
  }

  useEffect(() => {
    if (props.user.applications !== null && props.user.applications !== undefined) { //this check prevents a rendering error
      applications.forEach(each => {
        props.user.applications.forEach((app: Application) => {
          if (app.id == each.id) {
            each.enabled = true;
          };
        });
      });
    }

    //@ts-ignore
  }, [applications])

  return (
    <DialogRoot
      open={open}
      // onOpenChange={onClose}
      aria-describedby="alert-dialog-slide-description"
      size={{ base: "md", lg: "md" }}
      role={"dialog"}
      motionPreset={"scale"}
      placement={"center"}
      closeOnInteractOutside
      onInteractOutside={onClose}
    >
      <Toaster/>
      <DialogTrigger asChild>
        <Button onClick={handleClickOpen} padding={"10px"} margin={"5px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
          <MdOutlineSettingsApplications />
          Apps
        </Button>
      </DialogTrigger>
      {/* <DialogBackdrop /> */}
      <DialogContent>
        <DialogTitle paddingLeft={"20px"} paddingTop={"20px"}>{"Manage User Applications"}</DialogTitle>
        <DialogBody paddingLeft={"20px"} paddingTop={"20px"}>
          <Stack
            direction="column"
            wordSpacing={1}
            style={{ padding: 1, alignItems: "left", justifyContent: "left" }}
          >

            {applications.map((app: Application) => (
              <Fieldset.Root>
                <Fieldset.HelperText>{app.id}</Fieldset.HelperText>
                <Checkbox

                  style={{ alignContent: "center" }}
                  checked={app.enabled === true}
                  onChange={() => {
                    handleRadioChange(
                      app.id,
                      !(app.enabled)
                    )
                    app.enabled = !(app.enabled)
                    setState_change(state_change + 1)

                  }
                  }
                  value="a"
                  inputProps={{ "aria-label": app.id }}
                />
              </Fieldset.Root>
            ))
            }

          </Stack>
        </DialogBody>
        <DialogCloseTrigger onClick={onClose} />
        <DialogFooter>
          <Button onClick={handleClose}>Finish</Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}
