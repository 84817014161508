import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Select, Input, Flex, Text, useDisclosure,
    createListCollection,
    EnvironmentContext,
    Group,
    InputAddon
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillFilter, AiOutlinePlus, AiOutlineSync } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";

import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";

import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import { FaFilter } from "react-icons/fa";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTrigger } from "../ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";
import { BiSearch } from "react-icons/bi";
import TableControls from "./tableControls";
import NewKey from "../user/newKey";
import NewUser from "../user/newUser";
import Refresh from "../refresh";
import { SearchBox } from "../searchBox";
import { Toaster, toaster } from "../ui/toaster";


interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
interface IUserTypes {
    id: string,
    name: string,
}


interface TableControlProps {
    handleEnvChange: Function,
    handleSearchChange: Function,
    handleProfileChange: Function,
    handleRowsPerPageChange: Function,
    syncHandler: Function,
    search: string,
    selectedType: string,
    userTypes: IUserTypes[] | undefined,
    refresh: Function
    handleLoadingChange: Function,
    rowsPerPage: number,
    page: number,
    maxPage: number,
    setPage: Function,
    showUserTypes: Boolean,
    showTwilioSync: Boolean,
}
export default function TableControls2(props: TableControlProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [isSyncing, setIsSyncing] = useState<boolean>(false);

    const EnvironmentContext = useEnvironment();


    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();

    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        skills[test_object.skill_id] = test_object;

    }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }



    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    const enviromentCollection = React.useMemo(() => {
        let test = createListCollection({
            items: [

            ]
        })
        EnvironmentContext.environments.map((each: IEnvironment) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })
        console.log("environemnt collection ", test.items);
        return (test)
    }, [EnvironmentContext])

    const userTypesCollection = React.useMemo(() => {
        let test = createListCollection({ items: [] })

        props.userTypes?.map((each: IUserTypes) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })

        return test
    }, [props.userTypes])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })
    const syncHandler = () => {
        setIsSyncing(true);
        const promise = new Promise<void>((resolve, reject) => {
            webHelpers.PostAsync('/api/workers/roles/sync', "zeus-api", {}).then((data: any) => {
                console.log("data: ", data);
                if (data === null) {
                    setIsSyncing(false);
                    resolve();

                }
                else {

                    setIsSyncing(false);
                    reject();
                }
            }).catch((error) => {
                setIsSyncing(false);
                console.log("sync error : ", error)
                if (error.response === "Api returned 200, unable to parse data into type specified during call" && error.error !== null) {
                    resolve();
                }
                else {
                    reject();
                }

            })
        });
        toaster.promise(promise, {
            success: {
                title: "Successfully Synced to Twilio!",
                type: "success",
                

            },
            error: {
                title: "Sync Failed",
                type: "error",
            },
            loading: { title: "Syncing with Twilio..." }
        })

    };

    return (
        <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
            {/* @ts-ignore */}
            <Toaster />
            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"} display={{ base: "none", lg: "flex" }}>
                

                <SelectRoot size={"sm"} w={"137px"} collection={enviromentCollection} onValueChange={(value) => props.handleEnvChange(value)} value={[EnvironmentContext.selectedEnvironment.id]} defaultValue={["live"]} zIndex={100}
                    paddingLeft={"5px"} marginTop={"-3px"}>

                    <SelectTrigger w={"130px"}>
                        <SelectValueText placeholder="Select Env"></SelectValueText>
                    </SelectTrigger>
                    <SelectContent w={"100px"}><>
                        {/* @ts-ignore */}
                        {enviromentCollection.items.map((each: any) =>
                            <SelectItem item={each} key={each.id}>{each.label}</SelectItem>
                        )}
                    </>

                    </SelectContent>
                </SelectRoot>
            </Flex>
            <SearchBox search={props.search} handleSearchChange={props.handleSearchChange} />
            {/* <Group attached display={{ base: "none", lg: "flex" }} marginLeft={"10px"} verticalAlign={"middle"}>
                <InputAddon justifyContent={"center"} height={"30px"} w={"30px"} marginTop={"8.5px"} borderColor={"backgroundLightGrey"} background={"backgroundLightGrey"}><BiSearch size={"18px"} /></InputAddon>
                <Input bg={"backgroundDarkGrey"} height={"30px"} placeholder={" Search Bar"} value={props.search} onChange={(e) => props.handleSearchChange(e)} width={"200px"} ></Input>

            </Group> */}
            <Text marginLeft={"10px"} marginTop={"10px"} display={props.showUserTypes ? { base: "none", lg: "flex" } : "none"} marginRight={"1px"}>User Type:</Text>
            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"} display={{ base: "none", lg: (props.showUserTypes ? "flex" : "none") }}>
                <SelectRoot collection={userTypesCollection} onValueChange={(value) => props.handleProfileChange(value)} value={[props.selectedType]} width={"100px"} marginTop={"-5px"} marginLeft={"5px"} size={"md"}>
                    <SelectTrigger>
                        <SelectValueText placeholder="Select User Type"></SelectValueText>
                    </SelectTrigger>
                    <SelectContent justifyContent={"center"} alignContent={"center"}>
                        {userTypesCollection.items.map((workflow: any) => (
                            <SelectItem item={workflow} key={workflow.value}>{workflow.label}</SelectItem>
                        ))}
                    </SelectContent>
                </SelectRoot>
            </Flex>
            <Text marginLeft={"10px"} marginTop={"10px"} marginRight={"5px"} display={{ base: "none", lg: "flex" }}>Rows per Page:</Text>
            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"} display={{ base: "none", lg: "flex" }}>
                <SelectRoot collection={rowsCollection} onValueChange={(e) => props.handleRowsPerPageChange(e)} value={[String(props.rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                    <SelectTrigger w={"50px"}>
                        <SelectValueText placeholder={String(props.rowsPerPage)}></SelectValueText>
                    </SelectTrigger>
                    <SelectContent display={"absolute"}>
                        {rowsCollection.items.map((each) =>
                            <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                        )}
                    </SelectContent>
                </SelectRoot>
            </Flex>
            <Text marginLeft={"10px"} marginTop={"10px"} marginRight={"5px"} display={{ base: "none", lg: "flex" }}>Page {props.page + 1} of {props.maxPage} </Text>
            <Button display={{ base: "none", lg: "flex" }} bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                props.page !== 0 && props.setPage(props.page - 1);
                props.handleLoadingChange(true);
            }} disabled={props.page === 0}><AiFillCaretLeft /></Button>
            <Button display={{ base: "none", lg: "flex" }} size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => {
                props.page !== props.maxPage - 1 && props.setPage(props.page + 1)
                props.handleLoadingChange(true);
            }} disabled={props.page === props.maxPage - 1}><AiFillCaretRight /></Button>
            <Button display={props.showTwilioSync ? "flex" : "none"} size={"sm"} bg={"AbleBlue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} onClick={() => { syncHandler(); setIsSyncing(true); }} loading={isSyncing} marginLeft={{ base: "10px", lg: "30px" }}
                _loading={{ bg: "AbleYellow", color: "AbleBlue" }} loadingText={"Syncing"}>
                <AiOutlineSync /> Sync With Twilio
            </Button>
            <Refresh cb={() => props.refresh()} />

        </Flex>
    );
}
