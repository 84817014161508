import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io"
import { IoPersonRemoveSharp } from "react-icons/io5";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";

interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}


export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export default function EnableDisable(props: UserAppsDialogProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }
    function resetPassword() {
        let payload = { 'user_id': props.user.id }
        webHelpers.PostAsync('/api/management/password/reset/request', 'auth', payload).then((data: any) => {
            if (data.status === 200) {
                toaster.create({

                    title: `Password reset request sent!`,
                    type: "success",

                });
            }
            else {
                toaster.create({

                    title: `Unable to reset this User's password! [${data.status}]`,
                    type: "error",

                });
            }
        }).catch((error) => {
            handleClose();
        })
    }
    const enableUser = () => {
        webHelpers.PostAsync("/api/management/user/" + props.user.id + "/enable", 'auth', "",).then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                toaster.create({

                    title: `Error running API call, could not enable user`,
                    type: "error",

                });
            }
            else {
                props.cb();
                handleClose();
            }
        })
    }
    const disableUser = () => {
        webHelpers.PostAsync("/api/management/user/" + props.user.id + "/disable", 'auth', "",).then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                toaster.create({

                    title: `Error running API call, could not disable user`,
                    type: "error",

                });

            }
            else {
                props.cb(props.user.id);
                handleClose();
            }
        })
    }

    const handleClose = () => {
        onClose();
    }
    return (
        <>
            <Toaster />
            {String(props.user.status) === "Active" ?
                <Button onClick={handleClickOpen} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}><IoPersonRemoveSharp />Disable</Button>
                :
                <Button onClick={handleClickOpen} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}><IoMdPersonAdd />Enable</Button>
            }
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                placement={"center"}
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{String(props.user.status) === "Active" ? "Disable User? " : "Enable User?"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        {String(props.user.status) === "Active" ?
                            <>
                                <Text id="modal-modal-description" style={{ marginTop: 2 }}>
                                    Are you sure you would like to disable this user?
                                </Text>
                                <Button
                                    margin={"10px"}
                                    onClick={disableUser}

                                >
                                    Yes
                                </Button>
                            </>
                            :
                            <>
                                <Text id="modal-modal-description" style={{ marginTop: 2 }}>
                                    Are you sure you want to enable this user?
                                </Text>
                                <Button
                                    onClick={enableUser}
                                >
                                    Yes
                                </Button>
                            </>
                        }
                        <Button
                            onClick={handleClose}

                        >
                            No
                        </Button>

                    </DialogBody>
                </DialogContent>

            </DialogRoot>
        </>
    );
}
