import { createListCollection, useDisclosure } from "@chakra-ui/react";
import { Table, Spinner, Select, Flex, Image, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateGroup from "../../components/groups/createGroup";
import EditGroupModal from "../../components/groups/editGroup";
import CreateTimeprofile from "../../components/timeprofiles/createTimeprofile";
import ViewTimeprofile from "../../components/timeprofiles/editTimeprofile";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import Refresh from "../../components/refresh";
import { useColorMode } from "../../components/ui/color-mode";
import { Toaster, toaster } from "../../components/ui/toaster";
import { Button } from "../../components/ui/button";
import TableControls2 from "../../components/table/tableControlsComponent";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}

interface IGroups {
    id: string,
    is_security: boolean,
    name: string,
}
export interface ITimeProfile {
    id: string;
    is_bank_holiday_aware: boolean;
    name: string;
    days: {
        id: string;
        day: string;
        start_time: string;
        end_time: string;
    }
    cb: Function;
}
const defaultTimeprofile: ITimeProfile[] = [
    {
        id: "1234",
        is_bank_holiday_aware: true,
        name: "default time_profile",
        days: {
            id: "5678",
            day: "Monday",
            start_time: "10:00",
            end_time: "10:00"
        },
        cb: () => { return; }
    }
];

export const TimeprofilesPage = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<ITimeProfile[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [nextPage, setNextPage] = useState<ITimeProfile[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const { colorMode, toggleColorMode } = useColorMode();

    const { open, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();


    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {

        }
    }, [])
    function goToNextPage(pageToLoad: number, searchQuery: string) { //used for infinite scrolling
        webHelpers.GetAsync('/api/workflow/timeprofiles/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                console.log(data);
                //setNextPage(Object.values(data))
                //@ts-ignore
                (Object.values(data)).forEach((element: ITimeProfile) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                if (Math.ceil(data.length / rowsPerPage) === 0) {
                    setMaxPages(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                else {
                    setMaxPages(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    const searchTimeprofiles = () => {

        webHelpers.GetAsync('/api/workflow/timeprofiles/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
            //webHelpers.GetAsync('/api/workflow/timeprofiles', 'helios-api').then((data: any) => {
            console.log(data);
            if (data === undefined || data === null || data.status === 400) {
                setRows(defaultTimeprofile);
                setLoading(false);
                toaster.create({

                    title: `unable to fetch timeprofiles`,
                    type: "error",

                });
            }
            else {
                setLoading(false);
                //@ts-ignore
                setRows(Object.values(data));
                //@ts-ignore
                if (Math.ceil(data.length / rowsPerPage) === 0) {
                    setMaxPages(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                else {
                    setMaxPages(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                // return (toast({
                //     
                //     title: `Some server error creating this event subscriber`,
                //     type: "error",
                //     
                // }))
            }
        }).catch((error) => {
            console.log("error", error)
            setLoading(false);
            toaster.create({

                title: `Could not fetch timeprofiles [${error.status}]`,
                type: "error",

            });
        })
    }
    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchTimeprofiles()
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchTimeprofiles()
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    const refresh = () => {
        setIgnore(true);
        searchTimeprofiles();
        setLoading(true);
        setNextPageNo(page);

    }
    const handleProfileChange = (event: any) => {
        setLoading(true);
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
    }
    useEffect(() => {
        onToggle();
    }, [loading]);

    useEffect(() => {
        setRows([])
        searchTimeprofiles();
    }, [EnvironmentContext.selectedEnvironment])

    const enviromentCollection = React.useMemo(() => {
        return createListCollection({
            items: [
                EnvironmentContext.environments.map((each: IEnvironment) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [EnvironmentContext])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    return (

        // <ScaleFade initialScale={1} in={isPageOpen}>

        <>
            <Toaster />
            <Heading marginTop={"20px"} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
            >Timeprofile Management</Heading>
            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                <TableControls2 handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={() => console.log("")} syncHandler={() => console.log("")} search={search} selectedType={selectedType}
                    userTypes={undefined} showTwilioSync={false} showUserTypes={false} handleLoadingChange={setLoading} handleRowsPerPageChange={handleChangeRowsPerPage} refresh={Refresh} page={page} maxPage={maxPages} setPage={setPage} rowsPerPage={rowsPerPage} />

                <CreateTimeprofile cb={() => searchTimeprofiles()} />
            </Flex>
            <Flex direction={'row'} h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                {/* table to show list of users */}

                <>
                    {!loading ?
                        // <ScaleFade initialScale={1} in={!isOpen} reverse={loading}>
                        <Table.Root variant={"line"} width={"90%"} justifyContent={"center"} display={"flex"} marginTop={"10px"}>
                            <InfiniteScroll
                                dataLength={rows.length}
                                next={() => {

                                    if (nextPageNo < page) {
                                        setNextPageNo(page + 1);
                                        goToNextPage(page + 1, search)
                                    }
                                    else {
                                        goToNextPage(nextPageNo + 1, search)
                                        setNextPageNo(nextPageNo + 1);
                                    }
                                }}
                                hasMore={nextPageNo <= maxPages - 2}
                                loader={
                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                        <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                        {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                    </Flex>
                                }
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                    </p>
                                }
                                scrollThreshold={1}
                            >
                                <Table.Caption>
                                    <Text>
                                        {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                    </Text>
                                </Table.Caption>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColumnHeader>Name</Table.ColumnHeader>
                                        <Table.ColumnHeader>Id</Table.ColumnHeader>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {rows.map((each) =>
                                        <Table.Row>
                                            <Table.Cell paddingRight={"300px"}>{each.name}</Table.Cell>
                                            <Table.Cell paddingRight={"300px"}>{each.id}</Table.Cell>
                                            {/* edit modal goes here  */}
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell><ViewTimeprofile timeProfile={each} cb={searchTimeprofiles} /></Table.Cell>
                                        </Table.Row>
                                    )}

                                </Table.Body>
                            </InfiniteScroll>


                        </Table.Root>
                        // </ScaleFade>
                        :
                        // <ScaleFade initialScale={0.5} in={isOpen} reverse={!loading}>
                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                            {/* <Spinner color="AbleBlue" marginTop={"300px"}></Spinner> */}
                            {colorMode === 'light' ?
                                <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                                :
                                <Spinner color="AbleYellow" marginTop={"100px"} marginBottom={"100px"} size={"xl"} />
                            }
                        </Flex>
                        // {/* </ScaleFade> */}

                    }
                </>

                {/* <ScaleFade initialScale={0.1} in={defaultView}>
                    </ScaleFade> */}

            </Flex>
        </>

        // </ScaleFade>
    )
}

