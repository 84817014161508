import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { Provider } from './components/ui/provider';
import logo from './logo.svg';
import '../src/App.css';
import { SignIn } from './pages/login';
import { BrowserRouter } from 'react-router-dom';
import { PageRoutes } from './routes/Routing';
import CookieConsent from 'react-cookie-consent';
import { DefaultErrorBoundary } from './context/ErrorBoundary';
import "@fontsource/fira-code";
import { system } from './theme';
import { defaultSystem } from '@chakra-ui/react';

function App() {
    
  return (
    <>
      <Provider>
        <div className="App">
          <DefaultErrorBoundary>
            <BrowserRouter>
              <PageRoutes />
            </BrowserRouter>
          </DefaultErrorBoundary>
        </div>
      </Provider>

      <CookieConsent buttonStyle={{ background: "AbleBlue", color: "white" }}>
        This website uses cookies to enhance the user experience.
        <p style={{ fontSize: "12px" }}>By continuing to use this service, you accept the use of these cookies.</p>
      </CookieConsent>
    </>
  );
}

export default App;
