import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export default function PasswordReset(props: User) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }
    function resetPassword() {
        let payload = { 'user_id': props.id }
        webHelpers.PostAsync('/api/management/password/reset/request', 'auth', payload,).then((data: any) => {
            console.log("password reset response : ", data);
            if (data.status === 200) {
                toaster.create({

                    title: `Password reset request sent`,
                    type: "success",

                });
            }
            else {
                toaster.create({

                    title: `Unable to send out password reset request`,
                    type: "error",

                });
            }
        }).catch((error) => {
            console.log("caught error", error)
            handleClose();
        })
    }
    const handleClose = () => {
        onClose();
    }

    return (
        <>
            <Toaster />
            <Button onClick={handleClickOpen} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                <MdOutlineSettingsApplications />Reset Password
            </Button>
            <DialogRoot
                open={open}
                role="dialog"
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                placement={"center"}
                closeOnInteractOutside
            >

                <DialogContent>
                    <DialogTitle>{"Reset User Password?"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Text>This will send an email to the user asking them to reset their password, the link expires in 15 minutes.</Text>
                    </DialogBody>
                    <DialogFooter padding={"10px"}>
                        <Button onClick={resetPassword}>Reset Password</Button>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
