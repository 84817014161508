import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { useDisclosure, Tabs,
    Dialog,
    createToaster,
    Spinner
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import RoleManagement from "../role/rolesComponent";
import PermissionManagement from "../permissions/permissionComponent";
import GroupManagement from "../groups/groupsComponent";
import { Toaster, toaster } from "../ui/toaster";
import { DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],

export default function EditUserModal(props: UserAppsDialogProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    //const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const webHelpers = useWebHelper();

    const handleClickOpen = () => {
        setChanges([]);
        
        setLoading(true);
        webHelpers.GetAsync('/api/worker/roles/' + props.user.id, "zeus-api").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                toaster.create({
                    title: `Unable to get information for this user [${data.status}]`,
                    type: "error",
                });
                // Some fallback ui needed here.
            }
            else {
                setRoles(data);
                setLoading(false);
                onOpen();
            }
        }).catch((error) => {
            toaster.create({
                title: `Error fetching roles, error: [${error}]`,
                type: "error",
            });
        });

    };

    const onHandleClose = () => {
        onClose();
    }

    function updateGroups() {
        webHelpers.GetAsync('/api/management/user/' + props.user.id + '/groups', 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {

                // Some fallback ui needed here.
            }
            else {
                setGroups(data);
            }
        })
    };

    useEffect(() => {
        if (props.user.applications !== null && props.user.applications !== undefined) { //this check prevents a rendering error
            applications.forEach(each => {
                props.user.applications.forEach((app: Application) => {
                    if (app.id == each.id) {
                        each.enabled = true;
                    }
                });
            });
        }
    }, [applications])
    return (
        <>

            <DialogRoot
                open={open}
                // onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "md", lg: "xl" }}
                placement={"top"}
                onInteractOutside={onClose}

            >
                <Dialog.Trigger asChild>
                    <Button onClick={handleClickOpen} padding={"10px"} margin={"5px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                        <MdOutlineSettingsApplications />
                        Edit
                    </Button>
                </Dialog.Trigger>
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle paddingLeft={"20px"} paddingTop={"20px"}>{"Edit " + props.user.full_name}</DialogTitle>
                    <DialogBody padding={"20px"}>
                        {/* roles / permissions / groups */}
                        <Tabs.Root defaultValue={"roles"} position="relative" variant={"plain"}>
                            <Tabs.List bg={"backgroundGrey"} rounded={13} p={1}>
                                <Tabs.Trigger padding={"5px"} value="roles">Roles</Tabs.Trigger>
                                <Tabs.Trigger padding={"5px"} value="permissions">Permissions</Tabs.Trigger>
                                <Tabs.Trigger padding={"5px"} value="groups">Groups</Tabs.Trigger>
                                <Tabs.Indicator rounded={12}
                                />
                            </Tabs.List>
                            <Tabs.Content value="roles">
                                {roles !== null && roles !== undefined ?
                                    <RoleManagement cb={props.cb} roles={roles} user_id={props.user.id} user_name={props.user.account_id} />
                                    :
                                    <Spinner />
                                }
                            </Tabs.Content>
                            <Tabs.Content value={"permissions"}>
                                {roles !== null && roles !== undefined ?
                                    <PermissionManagement cb={props.cb} roles={roles} user_id={props.user.id} user_name={props.user.account_id} />
                                    :
                                    <Spinner />
                                }
                            </Tabs.Content>
                            <Tabs.Content value={"groups"}>
                                {roles !== null && roles !== undefined ?
                                    <GroupManagement cb={props.cb} roles={roles} user_id={props.user.id} user_name={props.user.account_id} />
                                    :
                                    <Spinner />
                                }
                            </Tabs.Content>
                        </Tabs.Root>
                    </DialogBody>
                    <DialogFooter>
                        <DialogTrigger>
                            <Button onClick={onHandleClose}>Finish</Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
