import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Table, Select, Flex, Text, useDisclosure,
    createListCollection,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "./addSkillRow";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";



interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

export default function AddSkill(props: IAddSkillProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const [currentSkills, setCurrentSkills] = useState(props.role.details);
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    // const [open, setOpen] = useState(false);
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);

    const [maxPages, setMaxPages] = useState<number>(1);

    const webHelpers = useWebHelper();



    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(event.value[0]);
        setPage(0);
    };

    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    useEffect(() => {
        onClearSkills();
    }, [open])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    return (
        <>
            <Button onClick={() => {
                onOpen();
                setPage(0)
                populateSkills();
            }}>
                <IoMdAdd />Add Skill
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "lg", lg: "lg" }}

            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Add Skills"}</DialogTitle>
                    <DialogBody>
                        <Flex direction={"row"} justifyContent={"right"}>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }} >Rows per Page:</Text>
                            <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                <SelectTrigger w={"50px"}>
                                    <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                </SelectTrigger>
                                <SelectContent display={"absolute"} portalled={false}>
                                    {rowsCollection.items.map((each) =>
                                        <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                    )}
                                </SelectContent>
                            </SelectRoot>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages} </Text>
                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                            }} disabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1)
                            }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                        <Table.Root variant={"line"}>
                            <Table.Caption>Current Environment : {CacheContext.lookUpCacheItem("environment")}</Table.Caption>
                            <Table.Header>
                                <Table.Row>
                                    {/* this will show all skill that are possible to see */}
                                    <Table.ColumnHeader>Skill Name</Table.ColumnHeader>
                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Skill Level</Table.ColumnHeader>
                                    <Table.ColumnHeader></Table.ColumnHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {pageSkills.map((skill) =>
                                    <AddSkillRow {...skill} handleAddedSkills={props.handleAddSkills} />
                                )}
                            </Table.Body>
                        </Table.Root>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={onClose}>Finish</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
