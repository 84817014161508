import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    createListCollection,
    Select, Table, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { forEachChild } from "typescript";
import AddSkillMobile from "./addSkillMobile";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger } from "../ui/select";


interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
    handleAddedSkills: Function,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

export default function AddSkillRow(props: Skill) {
    const { open, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [level, setLevel] = useState<Level>(props.levels[0]);

    const webHelpers = useWebHelper();
    const [label, setLabel] = useState<string>(props.levels[0].name);

    useEffect(() => {
        console.log("props: ", props);
    }, [])
    const addSkill = () => {
        if (level !== undefined) {
            const addedSkill: ISkillToAdd = {
                skill_id: props.id,
                level_id: level.id,
                name: props.name,
                level_name: level.name,
            }
            props.handleAddedSkills(addedSkill)

        } else {
            console.log(`Please choose a level before clicking Add`, { 'variant': 'warning' });
            toaster.create({

                title: `Please choose a level before clicking Add`,
                type: "warning",

            })

        }
    }
    const handleLevelChange = (e: any) => {
        console.log(props.levels);
        //setLabel(e.value[0]);
        props.levels.forEach(element => {
            if (element.id === e.value[0]) {
                setLevel(element);
                setLabel(element.name)
            }

        });
    }

    useEffect(() => {
        onClearSkills();
    }, [open])

    const test = React.useMemo(() => {
        let x = createListCollection({
            items: [

            ]
        })
        props.levels.map((each: Level) => {
            //@ts-ignore
            x.items.push({ label: each.name, value: each.id, order: each.order, skillId: each.skill_id, key: each.key })
        })
        return (x)
        // return createListCollection({
        //     items: [
        //         props.levels.map((each: Level) => {
        //             return { label: each.name, value: each.id, order: each.order, skillId: each.skill_id, key: each.key }
        //         })
        //     ]
        // })
    }, [props.levels])

    return (
        <>
            <Toaster />
            <Table.Row bg={"backgroundGrey"} >

                { }
                <Table.Cell>{props.name}</Table.Cell>
                <Table.Cell>
                    <SelectRoot collection={test} value={[label]} onValueChange={handleLevelChange} defaultValue={[props.levels[0].name]} display={{ base: "none", lg: "revert" }} zIndex={100}>
                        <SelectTrigger defaultValue={props.levels[0].name}>
                            <Text>{label} </Text>
                        </SelectTrigger>
                        <SelectContent portalled={false}>
                            {test.items.map((each: any) =>
                                <SelectItem item={each}>{each.label}</SelectItem>
                            )}
                        </SelectContent>
                    </SelectRoot>
                </Table.Cell>
                <Table.Cell>

                    <Button onClick={addSkill}
                        bg={"AbleBlue"}
                        color={"white"}
                        display={{ base: "none", lg: "revert" }}
                        _hover={{ color: "AbleYellow" }}>Add Skill
                    </Button>
                    <AddSkillMobile {...props} />


                </Table.Cell>
            </Table.Row>
        </>
    );
}
