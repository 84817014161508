import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Input, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";


interface newUserProps {
    cb: Function,
}

export default function NewUser(props: newUserProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [department, setDepartment] = useState<string>("");
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setDepartment("");
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    useEffect(() => {
        console.log(forcePassword);
    }, [forcePassword])

    const handleSubmit = () => {
        if (lastName !== undefined && lastName !== undefined) {
            let modifiedFirstName = firstName.toLowerCase()
            modifiedFirstName = modifiedFirstName.charAt(0).toUpperCase() + modifiedFirstName.slice(1)

            let modifiedLastName = lastName.toLowerCase()
            modifiedLastName = lastName.charAt(0).toUpperCase() + modifiedLastName.slice(1);

            const account_id = modifiedFirstName + '.' + modifiedLastName;
            const payload = {
                'user_type_id': "803130a7-7a5c-43d3-b850-bf8ecba20cd9",
                'account_id': account_id,
                'department': department,
                'email_address': email,
                'first_name': firstName,
                'force_password_change': forcePassword,
                'last_name': lastName,
                'password': password,
            }
            webHelpers.PutAsync('/api/management/user', 'auth', payload).then((data: any) => {

            })
            props.cb();
            handleClose();
        }
    }

    const handleFirstNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFirstName(e.currentTarget.value)
    }
    const handleLastNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setLastName(e.currentTarget.value)
    }
    const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
        if (String(e.currentTarget.value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            setIsValidEmail(true);
        }
        else {
            setIsValidEmail(false);
        }
    }
    const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }
    const handleDepartmentChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDepartment(e.currentTarget.value)
    }

    return (
        <>

            <DialogRoot
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "lg", lg: "md" }}
            >
                <Dialog.Backdrop />
                <DialogTrigger marginTop={"10px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        onClick={handleClickOpen}>
                        <BiPlus />
                        Create User
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New User"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>

                            <Input bg={"backgroundDarkGrey"} paddingLeft={"10px"} placeholder="First Name" type="text" autoComplete="new-password" value={firstName} onChange={handleFirstNameChange}></Input>
                            <Input bg={"backgroundDarkGrey"} paddingLeft={"10px"} marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Last Name" value={lastName} onChange={handleLastNameChange}></Input>
                            <Input bg={"backgroundDarkGrey"} paddingLeft={"10px"} marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Email" value={email} onChange={handleEmailChange}></Input>
                            {/* need to validate above field, just not sure how to do it in chakra v3 "onInvalid={!isValidEmail}" */}
                            {/* autocomplete is janky, take a look at it */}
                            <Input bg={"backgroundDarkGrey"} paddingLeft={"10px"} marginTop={"10px"} autoComplete="new-password" type="password" placeholder="Password" value={password} onChange={handlePasswordChange}></Input>
                            <Input bg={"backgroundDarkGrey"} paddingLeft={"10px"} marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Department" value={department} onChange={handleDepartmentChange}></Input>
                            <Checkbox marginTop={"10px"} marginLeft={"3px"} checked={forcePassword} onChange={handleForcePasswordChange}>Force Password Change? </Checkbox>
                        </Fieldset.Root>


                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => handleSubmit()}
                            disabled={firstName === "" || lastName === "" || email === "" || password === "" || department === "" || isValidEmail === false}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create User</Button>
                        <DialogTrigger>
                            <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
