import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Table, Input, Flex, Text, Accordion, AccordionItem, useDisclosure, Tabs, Tooltip, Toast, Spinner,
    createListCollection
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone, AiFillCaretLeft, AiFillCaretRight, AiOutlineMinus } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "../role/roleRow";
import NewRole from "../role/newRole";
import { StringMappingType } from "typescript";
import SkillsView from "../skills/skillsView";
import GetUserConfirm from "../confirm";
import { title } from "process";
import TableControlsPermissions from "../table/tableControlsPermissions";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";
import { SearchBox } from "../searchBox";



export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IGroups {
    id: string,
    is_security: boolean,
    name: string,
}

export default function GroupManagement(props: RoleManagementProps) {
    const CacheContext = useCache();
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [rows, setRows] = useState<IGroups[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userPermissions, setUserPermissions] = useState<any[]>([]);
    const [userPermissionsFiltered, setUserPermissionsFiltered] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userGroups, setUserGroups] = useState<IGroups[]>([]);
    const { open, onToggle } = useDisclosure();


    const webHelpers = useWebHelper();

    function searchGroups(search: string) {
        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No groups found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
                setLoading(false);
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            console.log("no groups match search criteria \"" + search + "\"");
        });
    }
    function searchUserGroups() {
        webHelpers.GetAsync('/api/management/user/' + props.user_id + '/groups', "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("could not find any groups associated with user ", props.user_name);
            }
            else {
                if (Math.floor(data.length / rowsPerPage) === 0) {
                    setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage) + 1);
                }
                else {
                    setMaxPagesFirstTab(Math.floor(data.length / rowsPerPage) + 1);
                }
                setUserGroups(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error fetching user groups")
        })
    }
    useEffect(() => {
        console.log("rows collection = ", rowsCollection)
        webHelpers.GetAsync('/api/management/groups/' + rowsPerPage + '/' + (page + 1), 'auth',).then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(data.groups);
                setMaxPages(data.max_pages);
            }
            else {
                console.log(`${data.status}: Unable to find groups under name`, { 'variant': 'error' })
            }
        })
        searchUserGroups();
    }, [page, rowsPerPage]);

    function handleDelete(group_id: string) {
        webHelpers.PostAsync('/api/management/group/' + group_id + '/revoke/' + props.user_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Group successfully revoked', { 'variant': 'success' });
                searchUserGroups();
            }
            else {
                console.log(`${data.status}: Unable to revoke this Group.`, { 'variant': 'error' })
            }
        })
        setLoading(false);
        return (
            toaster.create({

                title: `group ${group_id} has been removed from user ${props.user_name}`,
                type: "success",

            })
        )
    }
    function handleAssign(id: string) {
        webHelpers.PostAsync('/api/management/group/' + id + '/assign/' + props.user_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
            }
            else {
                console.log(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' });
            }
        })
        setLoading(false);
        searchUserGroups();
        return (
            toaster.create({

                title: `user ${props.user_name} has been added to group ${id}`,
                type: "success",

            })
        )

    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchGroups(event.target.value);
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        console.log(event)
        setRowsPerPage(event.value[0]);
        setPage(0);
    };

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    useEffect(() => {
        console.log("rowspp collection= ", rowsCollection)
    })

    return (
        <>
            <Toaster />
            {rows !== null && rows !== undefined ?

                <div>
                    <Tabs.Root position="relative" defaultValue={"Active Groups"} size={"lg"} variant={"subtle"} w={"full"}
                        onChange={() => {
                            // populateSkills();
                            setPage(0);
                            onToggle();
                        }

                        }>
                        <Tabs.List >
                            <Tabs.Trigger padding={"10px"} onClick={() => setPage(0)} borderRightRadius={0} value="Active Groups" bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Active Groups</Tabs.Trigger>
                            <Tabs.Trigger padding={"10px"} onClick={() => setPage(0)} borderLeftRadius={0} value="Add Groups" bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Add Groups</Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="Active Groups">
                            {/* <ScaleFade initialScale={1} in={!isOpen}> */}
                            <Flex direction={"row"} justifyContent={"right"}>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                    <SelectRoot defaultValue={["5"]} collection={rowsCollection} onValueChange={handleChangeRowsPerPage} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"} zIndex={100}>
                                        <SelectTrigger w={"50px"}>
                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                        </SelectTrigger>
                                        <SelectContent display={"absolute"} portalled={false}>
                                            {rowsCollection.items.map((each) =>
                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </SelectRoot>
                                </Flex>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPageFirstTab} </Text>
                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPageFirstTab - 1 && setPage(page + 1);
                                    setLoading(true);
                                }} disabled={page === maxPageFirstTab - 1}><AiFillCaretRight /></Button>
                            </Flex>
                            {!loading ?

                                <Table.Root variant={"line"} marginRight={"20px"} w={"full"}>
                                    {userGroups.length === 0 ?
                                        <Text textAlign={"center"}>No Groups Found! </Text>
                                        :
                                        <>
                                            <Table.Header >
                                                <Table.Row bg={"backgroundDarkGrey"} >
                                                    {/* this will show all skill that are possible to see */}
                                                    <Table.ColumnHeader textAlign={"left"}>Group Name</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Security</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-21px"}>
                                                    {userGroups.map(permission => (
                                                        <Flex justifyContent={"space-between"} w={"100%"} key={permission.id}>
                                                            <Table.Cell display={{ base: "revert", lg: "none" }} marginTop={{ base: "10px", lg: "0px" }}>
                                                                {permission.name}
                                                            </Table.Cell>

                                                            <Table.Cell display={{ base: "revert", lg: "none" }} >
                                                                <Button
                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                    bg={"AbleBlue"}
                                                                    color={"white"}
                                                                    marginLeft={{ base: "-20px", lg: "30px" }}
                                                                    _hover={{ color: "AbleYellow" }}
                                                                    loading={loading}>Delete</Button></Table.Cell>
                                                        </Flex>
                                                    ))}
                                                </Accordion.Root>
                                                {userGroups.map((group) =>
                                                    <Table.Row display={{ base: "none", lg: "revert" }} key={group.id} bg={"rgba(0,0,0,0)"}>
                                                        <Table.Cell>{group.name}</Table.Cell>
                                                        <Table.Cell>{group.is_security ? "Yes" : "No"}</Table.Cell>
                                                        <Table.Cell w={"150px"} >
                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                <GetUserConfirm
                                                                    confirmFunction={() => handleDelete(group.id)}
                                                                    declineFunction={() => console.log("")}
                                                                    buttonText="Remove"
                                                                    buttonWarning={"Are you sure you want to remove this group from " + props.user_name} />
                                                                {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                            </Flex>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </>
                                    }
                                </Table.Root>
                                :
                                <Flex width={"100%"} justifyContent={"center"}>
                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                </Flex>
                            }
                            {/* </ScaleFade> */}
                        </Tabs.Content>
                        <Tabs.Content value="Add Groups">
                            {/* <ScaleFade initialScale={1} in={isOpen}> */}
                            <Flex direction={"row"} justifyContent={"right"}>
                                <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                                <SearchBox search={search} handleSearchChange={handleSearchChange} />
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                    <SelectRoot defaultValue={["5"]} collection={rowsCollection} onValueChange={handleChangeRowsPerPage} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"} zIndex={100}>
                                        <SelectTrigger w={"50px"}>
                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                        </SelectTrigger>
                                        <SelectContent display={"absolute"} portalled={false}>
                                            {rowsCollection.items.map((each) =>
                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </SelectRoot>
                                </Flex>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages} </Text>
                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPages - 1 && setPage(page + 1)
                                    setLoading(true);
                                }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                            </Flex>
                            {/* need to have a section or method of showing already selected skills */}
                            {!loading ?
                                <Table.Root variant={"line"}>
                                    {rows.length === 0 ?
                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                        :
                                        <>
                                            <Table.Header>
                                                <Table.Row bg={"backgroundDarkGrey"}>
                                                    {/* this will show all skill that are possible to see */}
                                                    <Table.ColumnHeader textAlign={"left"}>Group Name</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Security</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }}>
                                                    {rows.map(permission => (
                                                        <Accordion.Item value={permission.id} key={permission.id}>
                                                            <Accordion.ItemTrigger w={"100%"} justifyContent={"space-between"}>
                                                                <Flex justifyContent={"space-between"} w={"100%"}>
                                                                    <Table.Cell>{permission.name}</Table.Cell>
                                                                    {/* <AccordionIcon /> */}
                                                                </Flex>
                                                            </Accordion.ItemTrigger>
                                                            <Accordion.ItemContent>
                                                                <Table.Cell display={{ base: "revert", lg: "none" }} marginLeft={"30px"} >
                                                                    <Button
                                                                        onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                        bg={"AbleBlue"}
                                                                        color={"white"}
                                                                        display={{ base: "revert", lg: "none" }}
                                                                        marginLeft={{ base: "0px", lg: "0px" }}
                                                                        _hover={{ color: "AbleYellow" }}
                                                                        loading={loading}>+ Assign Permission</Button>
                                                                </Table.Cell>
                                                            </Accordion.ItemContent>
                                                        </Accordion.Item>
                                                    ))}
                                                </Accordion.Root>
                                                {rows.map((permission) =>
                                                    <Table.Row display={{ base: "none", lg: "revert" }} justifyContent={"space-between"} bg={"rgba(0,0,0,0)"} key={permission.id}>
                                                        <Table.Cell>
                                                            {permission.name}
                                                        </Table.Cell>
                                                        <Table.Cell display={{ base: "none", lg: "revert" }} textOverflow={"ellipsis"}>{permission.is_security ? "Yes" : "No"}</Table.Cell>
                                                        <Table.Cell w={"150px"}>
                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                <Button
                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                    bg={"AbleBlue"}
                                                                    color={"white"}
                                                                    marginLeft={{ base: "-45px", lg: "0px" }}
                                                                    display={{ base: "none", lg: "flex" }}
                                                                    _hover={{ color: "AbleYellow" }}
                                                                    justifySelf={"left"}
                                                                    loading={loading}>+ Assign Permission</Button>
                                                            </Flex>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </>
                                    }
                                </Table.Root>
                                :
                                <Flex width={"100%"} justifyContent={"center"}>
                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                </Flex>
                            }
                            {/* </Sc0aleFade> */}
                        </Tabs.Content>
                    </Tabs.Root>
                </div>

                :
                <Flex>
                    <Text textAlign={"center"}>No Permissions Found !</Text>
                </Flex>
            }
        </>
    );
}
