import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Input, useDisclosure,
    Fieldset,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { Toaster, toaster } from "../ui/toaster";
import { Checkbox } from "../ui/checkbox";
import { Button } from "../ui/button";
import { DialogActionTrigger, DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";

interface ICreateGroupProps {
    cb: Function
}

export default function CreateGroup(props: ICreateGroupProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    function createGroup() {
        webHelpers.PutAsync('/api/management/group', 'auth', { 'name': name, 'is_security': isSecurity }).then((data: any) => {
        })
        handleClose();
        props.cb();
        toaster.create({

            title: `New Group Successfully Created`,
            type: "success",

        });
    }

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    return (
        <>
            <Toaster />
            <DialogRoot
                // open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                placement={"center"}
            >
                <DialogTrigger marginTop={"10px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={"30px"}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        marginTop={"10px"}
                    // onClick={handleClickOpen}
                    >
                        <BiPlus /> Create Group
                    </Button>
                </DialogTrigger>
                <DialogBackdrop />
                <DialogContent>
                    <DialogTitle>{"Create New Group"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>
                            <Input placeholder=" Name" type="text" autoComplete="new-password" bg={"backgroundDarkGrey"} marginTop={"5px"} value={name} onChange={handleNameChange}></Input>
                            <Checkbox marginTop={"10px"} marginLeft={"3px"} checked={isSecurity} onChange={() => setIsSecurity(!isSecurity)}>Security? </Checkbox>
                        </Fieldset.Root>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={createGroup}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create Group</Button>
                        <DialogActionTrigger>
                            <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogActionTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
