import * as React from "react";
import {
    Dialog,DialogCloseTrigger,useDisclosure,
} from '@chakra-ui/react';
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../ui/button";

interface ConfirmProps {
    confirmFunction: Function,
    declineFunction: Function,
    buttonText: string,
    buttonWarning: string,
}

export default function GetUserConfirm(props: ConfirmProps) {

    const { open, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef(null)

    return (
        <>
            <Button onClick={onOpen}
            bg={"AbleBlue"}
            color={"white"}
            _hover={{ color: "AbleYellow" }}>{props.buttonText}</Button>
            <DialogRoot
                //leastDestructiveRef={cancelRef} literally what does this do
                onOpenChange={onClose}
                open={open}
                placement={"center"}
                //isCentered
            >
                <Dialog.Backdrop />

                <DialogContent>
                    <DialogTitle>Are you Sure?</DialogTitle>
                    <DialogCloseTrigger />
                    <DialogBody padding={"20px"}>
                        {props.buttonWarning}
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => {
                            props.declineFunction();
                            onClose();
                        }}>
                            No
                        </Button>
                        <Button colorScheme='red' ml={3} onClick={() => {
                            props.confirmFunction();
                            onClose();
                        }}>
                            Yes
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    )

}