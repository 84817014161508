import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Table, Select, Input, Flex, Text, useDisclosure,
    createListCollection
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";

import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";
import SelectedSkillsForRole from "./selectedSkillsForRole";
import e from "express";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";


interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}

export default function NewRole(props: NewRoleProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [test_array, setTest_array] = useState<SkillTest[]>([]);

    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        // setTest_array(test_array => [...test_array, test_object]);

        skills[test_object.skill_id] = test_object;
        let found = false;
        test_array.forEach(element => {
            if (element.level_name === test_object.level_name) {
                found = true;
            }
        });
        if (found === false) {
            setTest_array(test_array => [...test_array, test_object]);
            toaster.create({

                title: `Added skill ${test_object.level_name}`,
                type: "success",

            })
        }
        else {
            toaster.create({

                title: `You have already added this skill to the new Role`,
                type: "warning",

            })
        }
        console.log(test_object)

    }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(event.value[0]);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.value[0]);
    }

    const handleSubmit = () => {
        if (text !== '' && selectedOutbound !== undefined) {

            let newSkills: ISkill[] = [];
            Object.values(skills).forEach(skill => {
                newSkills.push({ skill_id: skill.skill_id, level_id: skill.level_id });
            });

            let data = {
                'name': text,
                'outbound_number_id': selectedOutbound,
                'skills': newSkills,
                'worker_id': props.userId,
            }
            webHelpers.PutAsync('/api/worker/role', 'zeus-api', data).then((data: any) => {
                console.log("create role result ", data);
                if (data.status === 400) {
                    toaster.create({

                        title: `There was an error trying to create this role [${data.status}]`,
                        type: "error",

                    })
                }
                else {
                    //setCurrentPotentialSkills([])
                    props.cb();
                    toaster.create({

                        title: `${text} Created!`,
                        type: "success",

                    })
                }
            })
        }
        else {
            toaster.create({

                title: `Please ensure you have added all relevant fields`,
                type: "warning",

            })
        }
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    useEffect(() => {
        console.log("skills have changed")
    }, [skills]);

    const test = React.useMemo(() => {
        let x = createListCollection({
            items: [

            ]
        })
        outboundNumbers.map((each: OutboundNumbers) => {
            //@ts-ignore
            x.items.push({ label: each.name, value: each.id, phoneNumber: each.phoneNumber })
        })
        return x
    }, [outboundNumbers])

    return (
        <>
            <Toaster />
            <Button bg={"AbleBlue"} color={"white"} _hover={{ color: "AbleYellow" }} marginTop={"10px"}
                onClick={handleClickOpen}>
                <AiOutlinePlus />Create Role
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"lg"}
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Create a new Role"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Flex direction={"row"} marginBottom={"20px"} marginTop={"10px"}>
                            <Flex direction={"column"} width={"45%"}>
                                <Text> Enter the name of the new role. </Text>
                                <Input placeholder="Name" value={text} onChange={handleTextChange}></Input>
                            </Flex>
                            <Flex direction={"column"} width={"50%"} marginLeft={"30px"}>
                                <Text>Select the Outbound Phone Number</Text>
                                <Select.Root collection={test} value={[String(selectedOutbound)]} onValueChange={handleSelectedOutboundChange} placeholder="Select an entry from the list below">
                                    <SelectTrigger>
                                        <SelectValueText placeholder="Select the Outbound Phone Number"></SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent portalled={false}>
                                        {test.items.map((each: any) => (
                                            <SelectItem item={each} key={each.value}>{each.label + " " + each.phoneNumber}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select.Root>
                            </Flex>
                        </Flex>
                        <Flex direction={"row"} justifyContent={"right"}>
                            <SelectedSkillsForRole skills={test_array} />
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                            <SelectRoot onValueChange={handleChangeRowsPerPage} value={[String(rowsPerPage)]} defaultValue={["5"]} width={"100px"} display={{ base: "none", lg: "revert" }} collection={rowsCollection}>
                                <SelectTrigger>
                                    <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                </SelectTrigger>
                                <SelectContent portalled={false}>
                                    {rowsCollection.items.map((each) =>
                                        <SelectItem item={each} key={each.label}>{each.label}</SelectItem>
                                    )}
                                </SelectContent>
                            </SelectRoot>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                            <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                            }} disabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1)
                            }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}
                        <Table.Root variant={"line"}>
                            <Table.Caption>Current Environment : {CacheContext.lookUpCacheItem("environment")}</Table.Caption>
                            <Table.Header>
                                <Table.Row>
                                    {/* this will show all skill that are possible to see */}
                                    <Table.ColumnHeader>Skill Name</Table.ColumnHeader>
                                    <Table.ColumnHeader>Skill Level</Table.ColumnHeader>
                                    <Table.ColumnHeader>
                                    </Table.ColumnHeader>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>

                                {pageSkills.map((skill) =>

                                    <AddSkillRow {...skill} handleAddedSkills={addSkills} />
                                )}
                            </Table.Body>
                        </Table.Root>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => { handleSubmit(); handleClose(); }}>Create</Button>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
