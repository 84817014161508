import { Box} from "@chakra-ui/react";
import {
    Flex, Link, Image, Text, Button
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { useAuth } from '../../context/AuthContext';
import { GiHamburgerMenu } from "react-icons/gi";
import { DashboardErrorBoundary } from "../../context/ErrorBoundary";
import { GrSettingsOption } from "react-icons/gr";
import { IoPeopleOutline, IoSettings } from "react-icons/io5";
import { IoIosGitNetwork, IoIosSettings } from "react-icons/io";
import { useColorMode } from "../ui/color-mode";
import { MenuContent, MenuItem, MenuItemGroup, MenuRoot, MenuSeparator, MenuTrigger } from "../ui/menu";
import { BsPerson } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { BiPhoneCall } from "react-icons/bi";
import { FaCalendar, FaRegClock } from "react-icons/fa";
import { MdAppBlocking, MdAppShortcut, MdPendingActions } from "react-icons/md";
import { bg } from "date-fns/locale";



interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number
}



export const PageContainer = (props: IPageContainerProps) => {
    const [listJobBox, setListJobBox] = useState<JSX.Element[]>([]);
    const [listItems, setListItems] = useState<IWorkResult[]>([]);
    const [listItems2, setListItems2] = useState<IWorkResult[]>([]);
    const [listItems3, setListItems3] = useState<IWorkResult[]>([]);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [noActiveJobs, setNoActiveJobs] = useState<boolean>(true);
    const { colorMode, toggleColorMode } = useColorMode();



    const authContext = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Viabl | " + props.pageTitle;
    }, []);

    const handleLogOut = () => {
        let response = authContext.signOut();
    }


    const goToDashboard = () => {
        navigate("/dashboard");
        document.title = "Viabl | Dashboard"
    }

    const handleStaffClick = () => {
        navigate("/staff")
        document.title = "Viabl | Staff Management"
    }
    const handleWorkflowClick = () => {
        navigate("/workflows")
        document.title = "Viabl | Workflow Management"
    }
    const handleLateInvoiceClick = () => {
        navigate("/lateinvoicedate")
        document.title = "Viabl | Late Invoice Dates"
    }
    const handleBlocklistClick = () => {
        navigate("/blocklist")
        document.title = "Viabl | Blocklist Management"
    }
    const handleCallInteractionClick = () => {
        navigate("/interactions")
        document.title = "Viabl | Call Interactions"
    }
    const handleTimeprofileClick = () => {
        navigate("/timeprofiles")
        document.title = "Viabl | Timeprofile Management"
    }
    const handleGoBack = () => {
        navigate("/dashboard")
        document.title = "Viabl | Dashboard"
    }
    const handleUserClick = () => {
        navigate("/staff/users");
        document.title = "Viabl | User Management"
    }
    const handleGroupClick = () => {
        navigate("/staff/groups");
        document.title = "Viabl | Group Management"
    }
    const handlePermissionClick = () => {
        navigate("/staff/permissions")
        document.title = "Viabl | Permission Management"
    }
    const handleOutcomeClick = () => {
        navigate("/outcomes");
        document.title = "Viabl | Outcome Management"
    }

    return (
        <>
            <Box w='100%' bg={{ base: "AbleBlue", lg: "black-translucent" }} bgGradient={colorMode === 'light' ? { base: "black-translucent", lg: 'linear-gradient(90deg, rgba(6,87,156,1) 49%, rgba(245,250,254,1) 86%, rgba(255,255,255,1) 95%);' } : { base: "black-translucent", lg: 'linear-gradient(90deg, rgba(4,54,98,1) 45%, rgba(26,32,44,1) 100%)' }} borderBottomColor={"AbleYellow"} borderBottomWidth={"3px"} shadow={colorMode === "light" ? "base" : "none"}>
                <Flex direction={"row"} h={{ base: "100px", lg: '150px' }} width={"100%"}>

                    <Flex direction={"column"} marginLeft={{ base: "10px", lg: "50px" }} width={"100%"} marginTop={"10px"}>

                        <Flex direction={"row"} justifyContent={{ base: "space-between", lg: "left" }}>

                            <MenuRoot>
                                <MenuTrigger>
                                    <Button
                                        justifySelf={"right"}
                                        display={{ base: "flex", lg: "none" }}
                                        as={Button}
                                        marginTop={"10px"}
                                        bg={"AbleBlue"}
                                        _hover={{ bg: "AbleYellow", color: "AbleYellow" }}
                                        _active={{ bg: "AbleYellow" }}
                                        aria-label='Options'
                                        color={"white"}
                                        height={"-moz-max-content"}
                                        marginLeft={"10px"}
                                    >
                                        <GiHamburgerMenu color="white" size={"50px"} />
                                    </Button>
                                </MenuTrigger>
                                <MenuContent minWidth='240px' padding={"5px"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} color={"white"} borderColor={colorMode === "light" ? "AbleYellow" : "#808080"} marginRight={"100px"}>
                                    <MenuItemGroup title='Staff' textAlign={"left"} marginLeft={"10px"}>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"} value='Users' onClick={handleUserClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><BsPerson/> Users</MenuItem>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"} value='Groups' onClick={handleGroupClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><IoPeopleOutline/> Groups</MenuItem>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"} value='Permissions' onClick={handlePermissionClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><RiFilePaper2Line/> Permissions</MenuItem>
                                    </MenuItemGroup>
                                    <MenuSeparator  bgColor={colorMode === "light" ? "accent" : "backgroundLightGrey"} />
                                    <MenuItemGroup title='Workflows (Desktop Only)' textAlign={"left"} marginLeft={"10px"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} color={"white"}>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  disabled={true} value='Workflows' onClick={handleWorkflowClick} textAlign={"center"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><IoIosGitNetwork/> Workflows</MenuItem>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  disabled={true} value='Timeprofiles' onClick={handleTimeprofileClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><FaRegClock/>Timeprofiles</MenuItem>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  disabled={true} value='Outcomes' onClick={handleOutcomeClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><MdPendingActions/>Outcomes</MenuItem>
                                    </MenuItemGroup>
                                    <MenuSeparator />
                                    <MenuItemGroup title='Phone Numbers' textAlign={"left"} marginLeft={"10px"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} color={"white"}>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  value='Blocklist' onClick={handleBlocklistClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><MdAppBlocking/> Blocklist</MenuItem>
                                    </MenuItemGroup>
                                    <MenuSeparator />
                                    <MenuItemGroup title='Accounting' textAlign={"left"} marginLeft={"10px"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} color={"white"}>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  value='Blocklist' onClick={handleLateInvoiceClick} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}><FaCalendar/> Late Invoice Dates</MenuItem>
                                    </MenuItemGroup>
                                    <MenuSeparator />
                                    <MenuItemGroup title='Settings' textAlign={"left"} marginLeft={"10px"} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} color={"white"}>
                                        <MenuItem color={"primaryTextInverted"} padding={"5px"}  value='Blocklist' onClick={toggleColorMode} bg={colorMode === 'light' ? "AbleBlue" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "AbleYellow", color: "AbleBlue" } : { bg: "backgroundHighlight", color: "white" }}>Toggle Theme</MenuItem>
                                    </MenuItemGroup>
                                </MenuContent>
                            </MenuRoot>
                            <Text color={"white"} fontSize={{ base: "3xl", md: "4xl", lg: "5xl", xl: "6xl" }} textAlign={"left"} marginRight={{ base: "10px", lg: "0px" }}>Management Tools</Text>
                        </Flex>
                        <Text as={"i"} color={"white"} fontSize={{ base: "md", lg: "2xl" }} textAlign={{ base: "right", lg: "left" }} marginTop={{ base: "0px", md: "30px", lg: "0px" }} marginRight={{ base: "10px", lg: "0px" }}>Welcome, {authContext.user?.user_name} {" - "}

                            <Link color={"AbleYellow"} onClick={handleLogOut}>
                                Sign Out
                            </Link>
                            <Flex direction={"row"}>
                                {/* <Text width={"160px"} color={"white"} as={"b"}>Environment : </Text> */}
                            </Flex>
                        </Text>

                    </Flex>
                    <Flex justifyContent={"right"} width={{ base: "0px", lg: "40%" }}>
                        <Image src={viablLogo} align={"right"} display={{ base: "none", lg: "flex" }} height={"full"} alignSelf={"right"}></Image>
                    </Flex>
                </Flex>
            </Box>
            <Box w='100%' bg={colorMode === 'light' ? 'AbleYellow' : "backgroundLightGrey"} borderBottomColor={"AbleYellow"} borderBottomWidth={"3px"} shadow={"base"} display={{ base: "none", lg: "revert" }}>
                <Flex direction={"row"} h='45px' width={"100%"} marginTop={"-5px"}>

                    <Flex direction={"row"} marginLeft={{ base: "10px", lg: "50px" }} width={"100%"} marginTop={"0px"}>
                        <Flex>
                            <MenuRoot>
                                <MenuTrigger asChild>
                                    <Button borderRadius={0} marginLeft={"-30px"} height={"47.5px"} paddingLeft={"10px"} paddingRight={"10px"} as={Button} bg={colorMode === 'light' ? 'AbleYellow' : "rgba(0,0,0,0)"} colorScheme={"blue"} _hover={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} _active={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"lg"}>
                                        <Text >
                                            Staff
                                        </Text>
                                    </Button>
                                </MenuTrigger>
                                <MenuContent bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} color={"white"} padding={"5px"}>
                                    <MenuItem cursor={"pointer"} padding={"5px"} value='Users' onClick={handleUserClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><BsPerson/> Users</MenuItem>
                                    <MenuItem cursor={"pointer"} padding={"5px"} value='Groups' onClick={handleGroupClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><IoPeopleOutline/> Groups</MenuItem>
                                    <MenuItem cursor={"pointer"} padding={"5px"} value='Permissions' onClick={handlePermissionClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"}
                                        _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}> <RiFilePaper2Line/> Permissions</MenuItem>
                                </MenuContent>
                            </MenuRoot>
                        </Flex>
                        <MenuRoot>
                            <MenuTrigger asChild>
                                <Button height={"47.5px"} paddingLeft={"10px"} paddingRight={"10px"} borderRadius={0} as={Button} bg={colorMode === 'light' ? 'AbleYellow' : "rgba(0,0,0,0)"} colorScheme={"blue"} _hover={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} _active={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"lg"}>
                                    <Text >
                                        Phone Numbers
                                    </Text>
                                </Button>
                            </MenuTrigger>
                            <MenuContent padding={"5px"} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} color={"white"} >
                                <MenuItem cursor={"pointer"} padding={"5px"} value='Blocklist' onClick={handleBlocklistClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><MdAppBlocking/> Blocklist</MenuItem>
                                <MenuItem cursor={"pointer"} padding={"5px"} value='Blocklist' onClick={handleCallInteractionClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? {bg: "bg.emphasized", color: "textPrimaryInverted"} : {bg: "backgroundHighlight", color: "white"}}><MdAppShortcut/> Call Interactions</MenuItem>                                                                        
                            </MenuContent>
                        </MenuRoot>
                        <MenuRoot>
                            <MenuTrigger asChild>


                                <Button height={"47.5px"} paddingLeft={"10px"} paddingRight={"10px"} borderRadius={0} as={Button} bg={colorMode === 'light' ? 'AbleYellow' : "rgba(0,0,0,0)"} colorScheme={"blue"} _hover={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} _active={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"lg"}>
                                    <Text >
                                        Workflows
                                    </Text>
                                </Button>
                            </MenuTrigger>
                            <MenuContent padding={"5px"} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} color={"white"} >
                                <MenuItem padding={"5px"} value='Workflows' onClick={handleWorkflowClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><IoIosGitNetwork/> Workflows</MenuItem>
                                <MenuItem padding={"5px"} value='Timeprofiles' onClick={handleTimeprofileClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><FaRegClock/> Time Profiles</MenuItem>
                                <MenuItem padding={"5px"} value='Outcomes' onClick={handleOutcomeClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><MdPendingActions/> Outcomes</MenuItem>
                            </MenuContent>
                        </MenuRoot>
                        <MenuRoot>
                            <MenuTrigger asChild>
                                <Button height={"47.5px"} paddingLeft={"10px"} paddingRight={"10px"} borderRadius={0} as={Button} bg={colorMode === 'light' ? 'AbleYellow' : "rgba(0,0,0,0)"} colorScheme={"blue"} _hover={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} _active={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"lg"}>
                                    <Text >
                                        Accounting
                                    </Text>
                                </Button>
                            </MenuTrigger>
                            <MenuContent padding={"5px"} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} color={"white"} >
                                <MenuItem padding={"5px"} value='Late Invoice Dates' onClick={handleLateInvoiceClick} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}><FaCalendar/> Late Invoice Dates</MenuItem>
                            </MenuContent>
                        </MenuRoot>
                        <MenuRoot >
                            <MenuTrigger asChild>
                                <Button height={"47.5px"} paddingLeft={"10px"} paddingRight={"10px"} borderRadius={0} as={Button} bg={colorMode === 'light' ? 'AbleYellow' : "rgba(0,0,0,0)"} colorScheme={"blue"} _hover={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} _active={colorMode === 'light' ? { bgColor: "AbleBlue", color: "white" } : { bgColor: "rgba(0,0,0,0)", color: "white" }} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"lg"}>
                                    <IoIosSettings size={"30px"} />
                                </Button></MenuTrigger>
                            <MenuContent padding={"5px"} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} color={"white"} >
                                <MenuItem padding={"5px"} value='Late Invoice Dates' onClick={toggleColorMode} bg={colorMode === 'light' ? "backgroundGrey" : "backgroundLightGrey"} _hover={colorMode === 'light' ? { bg: "bg.emphasized", color: "textPrimaryInverted" } : { bg: "backgroundHighlight", color: "white" }}>Toggle Theme</MenuItem>
                            </MenuContent>
                        </MenuRoot>
                        {/* <Button borderRadius={0} marginLeft={"-85px"} onClick={handleBlocklistClick} bgColor={"AbleYellow"} colorScheme={"blue"} _hover={{ bgColor: "AbleBlue", color: "white" }} _active={{ bg: "#ECB500" }} color={"AbleBlue"} size={"lg"}>Blocklist</Button>
                        <Button borderRadius={0} onClick={handleWorkflowClick} bgColor={"AbleYellow"} colorScheme={"blue"} _hover={{ bgColor: "AbleBlue", color: "white" }} _active={{ bg: "#ECB500" }} color={"AbleBlue"} size={"lg"}>Workflows</Button>
                        <Button borderRadius={0} onClick={handleTimeprofileClick} bgColor={"AbleYellow"} colorScheme={"blue"} _hover={{ bgColor: "AbleBlue", color: "white" }} _active={{ bg: "#ECB500" }} color={"AbleBlue"} size={"lg"}>Timeprofiles</Button> */}
                    </Flex>
                </Flex>
            </Box>

            <DashboardErrorBoundary>
                {props.children}
            </DashboardErrorBoundary>
        </>
    )
}