import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Flex, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdEdit, MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillCaretLeft, AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { CodeEditor } from "../code/codeEditor";
import GetUserConfirm from "../confirm";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Switch } from "../ui/switch";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
    refreshEventsFunction: Function,
}
interface Event {
    name: string,
    id: string,
}
interface IOutcomeData {
    is_completion_outcome: boolean,
    is_quick_outcome: boolean,
    is_reschedule_outcome: boolean
}
interface StepOutcomeProps {
    stepId: string,
    outcomeId: string,
    cb: Function,
    mode: string // either "edit" or "add"
    data: IOutcomeData

}
interface IOutcome {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface IStepOutcomes {
    definitionId: string,
    id: string,
    isCompletionOutcome: boolean,
    isQuickOutcome: boolean,
    isRescheduleOutcome: boolean,
    outcomeDefinition: string | null,
    outcomeDefinitionId: string,
    step: string | null,
    stepId: string,
    workflow: string | null
}

export default function AddOutcome(props: StepOutcomeProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [isCompletionOutcome, setIsCompletionOutcome] = useState<boolean>(props.data.is_completion_outcome);
    const [isQuickOutcome, setIsQuickOutcome] = useState<boolean>(props.data.is_quick_outcome);
    const [isRescheduleOutcome, setIsRescheduleOutcome] = useState<boolean>(props.data.is_reschedule_outcome);

    const CacheContext = useCache();
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPagesFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [outcomes, setOutcomes] = useState<IOutcome[]>([])
    const [stepOutcomes, setStepOutcomes] = useState<IStepOutcomes[]>([]);

    const webHelpers = useWebHelper();

    const handleClickOpen = () => {
        setIsCompletionOutcome(props.data.is_completion_outcome);
        setIsQuickOutcome(props.data.is_quick_outcome);
        setIsRescheduleOutcome(props.data.is_reschedule_outcome);
        onOpen();
        searchStepOutcomes();
    }
    const handleClose = () => {
        onClose();
        props.cb();
    }
    const searchStepOutcomes = () => {
        console.log("test")
        webHelpers.GetAsync<any>("/api/workflow/step/" + props.stepId + "/outcomes", "helios-api").then((res) => {
            console.log("Outcomes for this step ", res);
        })
    }
    const AddOutcomeToStep = () => {
        console.log("adding outcome")
        let payload = {
            is_completable: isCompletionOutcome,
            is_quick_outcome: isQuickOutcome,
            is_reschedulable: isRescheduleOutcome,
        }
        console.log(payload)
        // console.log("running : ","/api/workflow/step/" + props.stepId + "/outcome/" + props.outcomeId )
        if (props.mode === "add") {
            webHelpers.PutAsync("/api/workflow/step/" + props.stepId + "/outcome/" + props.outcomeId, "helios-api", payload
            ).then((res) => {
                // console.log("link response = ", res)
                handleClose();
                return (
                    toaster.create({

                        title: "Successfully added outcome to workflow step",
                        type: "success",
                    })
                )
            }).catch((error) => {
                console.log("error adding outcome to workflow step ", error);
                return (
                    toaster.create({

                        title: "Failed to add outcome to workflow step [" + error.status + "" + "]",
                        type: "error",

                    })
                )
            })
        }
        else if (props.mode === "edit") {
            webHelpers.PostAsync("/api/workflow/step/" + props.stepId + "/outcome/" + props.outcomeId, "helios-api", payload
            ).then((res) => {
                // console.log("link response = ", res)
                handleClose();
                return (
                    toaster.create({

                        title: "Successfully saved changes to linked outcome",
                        type: "success",
                    })
                )
            }).catch((error) => {
                console.log("error adding outcome to workflow step ", error);
                return (
                    toaster.create({

                        title: "Failed to save changed to linked outcome [" + error.status + "" + "]",
                        type: "error",

                    })
                )
            })
        }
    }

    return (
        <>
            <Toaster />
            <DialogRoot
                //open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"xs"}
                placement={"center"}
                role="alertdialog"
            >
                <DialogTrigger>
                    <Button size={props.mode === "add" ? "sm" : "md"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={props.mode === "add" ? "10px" : "0px"}
                    // onClick={handleClickOpen}
                    >   {props.mode === "add" ? <BiAddToQueue /> : <MdEdit />}
                        {props.mode === "add" ? "Add Outcome" : "Edit"}
                    </Button>
                </DialogTrigger>
                <DialogBackdrop />
                <DialogContent>
                    <DialogTitle>{"Outcome Properties"}<br />
                    </DialogTitle>
                    <DialogBody padding={"10px"} asChild>
                        <Fieldset.Root>
                            <Fieldset.HelperText><Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Please select the properties of the outcome you want to apply.</Text></Fieldset.HelperText>
                            {/* <Flex direction={"column"} justifyContent={"center"} w={"100%"}> */}
                            <Flex justifyContent={"space-between"}>
                                <Text marginTop={"15px"} w={"125px"}>Is Completable?</Text>
                                <Button w={"75px"} overrideBgColor bg={isCompletionOutcome ? "AbleYellow" : "AbleBlue"} disabled={isCompletionOutcome} onClick={() => setIsCompletionOutcome(!isCompletionOutcome)}>Yes</Button>
                                <Button w={"75px"} overrideBgColor bg={!isCompletionOutcome ? "AbleYellow" : "AbleBlue"} disabled={!isCompletionOutcome} onClick={() => setIsCompletionOutcome(!isCompletionOutcome)}>No</Button>
                            </Flex>
                            <Flex justifyContent={"space-between"}>
                                <Text marginTop={"15px"} w={"125px"}>Is Quick Outcome?</Text>
                                <Button w={"75px"} overrideBgColor bg={isQuickOutcome ? "AbleYellow" : "AbleBlue"} disabled={isQuickOutcome} onClick={() => setIsQuickOutcome(!isQuickOutcome)}>Yes</Button>
                                <Button w={"75px"} overrideBgColor bg={!isQuickOutcome ? "AbleYellow" : "AbleBlue"} disabled={!isQuickOutcome} onClick={() => setIsQuickOutcome(!isQuickOutcome)}>No</Button>
                            </Flex>
                            <Flex justifyContent={"space-between"}>
                                <Text marginTop={"15px"} w={"125px"}>Is Reschedulable?</Text>
                                <Button w={"75px"} overrideBgColor bg={isRescheduleOutcome ? "AbleYellow" : "AbleBlue"} disabled={isRescheduleOutcome} onClick={() => setIsRescheduleOutcome(!isRescheduleOutcome)}>Yes</Button>
                                <Button w={"75px"} overrideBgColor bg={!isRescheduleOutcome ? "AbleYellow" : "AbleBlue"} disabled={!isRescheduleOutcome} onClick={() => setIsRescheduleOutcome(!isRescheduleOutcome)}>No</Button>
                            </Flex>
                            {/* <Checkbox cursor={"pointer"} checked={isCompletionOutcome} onChange={() => setIsCompletionOutcome(!isCompletionOutcome)}>Is Completable?</Checkbox> */}
                            {/* <Checkbox cursor={"pointer"} size={"lg"} checked={isQuickOutcome} onCheckedChange={() => setIsQuickOutcome(!isQuickOutcome)}          >Is Quick Outcome?</Checkbox>
                                <Checkbox cursor={"pointer"} size={"lg"} checked={isRescheduleOutcome} onCheckedChange={() => setIsRescheduleOutcome(!isRescheduleOutcome)}>Is Reschedulable?</Checkbox> */}
                            {/* </Flex> */}
                        </Fieldset.Root>

                    </DialogBody>
                    <DialogFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={AddOutcomeToStep}>{props.mode === "add" ? "Add Outcome" : "Save Changes"}</Button>
                        </Flex>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
