import * as React from 'react';
import { useState } from 'react';


import {
    Dialog, Select, Input, Flex, Text,
    createListCollection,
    Box
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";

import { validateLocaleAndSetLanguage } from "typescript";
import { BiAddToQueue, BiCalendar } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { ILateInvoiceDate } from '.';
import { GiSettingsKnobs } from 'react-icons/gi';
import { Button } from '../../components/ui/button';
import { InputGroup } from '../../components/ui/input-group';
import { Checkbox } from '../../components/ui/checkbox';
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '../../components/ui/select';

interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface IAssembly {
    assembly_name: string;
    is_default: boolean;
}
const defaultWorkflow: IAssembly[] = [
    {
        assembly_name: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
export default function UpdateLateInvoiceDate(props: ILateInvoiceDate) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [name, setName] = useState<string>(props.name);
    const [endDate, setEndDate] = useState(props.end_date);
    const [targetDate, setTargetDate] = useState(props.target_date);
    const [startDate, setStartDate] = useState(props.start_date === null ? null : props.start_date);
    const [classID, setClassID] = useState(props.class_id);
    const [startDateNull, setStartDateNull] = useState<boolean>(props.start_date === null ? true : false);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<IAssembly[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Assembly");
    const webHelpers = useWebHelper();

    function handleClose()   {
        setOpen(false);
    }
    function handleOpen() {
        setOpen(true);
        
        console.log("open = ", open);
    }

    const handleChangeName = (event: any) => {
        setName(event.target.value);
    }
    const handleChangeEndDate = (event: any) => {
        setEndDate(event.currentTarget.value);
    }
    const handleChangeTargetDate = (event: any) => {
        setTargetDate(event.currentTarget.value);
    }
    const handleChangeClassID = (event: any) => {
        setClassID(event.currentTarget.value);
    }
    const handleChangeStartDate = (event: any) => {
        setStartDate(event.currentTarget.value);
    }
    const handleSubmit = () => {
        let data = {
            id: props.id,
            name: name,
            end_date: endDate,
            target_date: targetDate,
            class_id: classID,
            start_date: startDateNull === true ? null : startDate,

        }
        console.log("updating")
        webHelpers.PostAsync("/api/accounting/claims/lateinvoicing/update", "sales", data).then((res) => {
            console.log("success", res)
            setOpen(false);
            props.cb();
        })
    }
    const optionCollection = createListCollection({
        items: [
            { label: "2018/2019", value: "2018-2019" },
            { label: "2019/2020", value: "2019-2020" },
            { label: "2020/2021", value: "2020-2021" },
            { label: "2021/2022", value: "2021-2022" },
            { label: "2022/2023", value: "2022-2023" },
            { label: "2023/2024", value: "2023-2024" },
            { label: "2024/2025", value: "2024-2025" },
            { label: "2025/2026", value: "2025-2026" },
        ]
    })
    // const handleSubmit = () => {
    //     props.cb(newNamespace);
    //     setOpen(false);
    // }
    return (
        <>

            <DialogRoot
                open={open}
                // onOpenChange={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                size={"xl"}
            >
                <Dialog.Backdrop />
                <DialogTrigger>
                    <Button size={"md"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ color: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"-1px"}
                        className='button-test' onClick={() => setOpen(true)}><GiSettingsKnobs />Edit</Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>
                        <div className="modal-header">
                            <Text >
                                Edit Late Invoice Date
                            </Text>
                            {/* Add an edit department bit here at some point */}
                        </div>
                        <Text as={"i"} fontSize={"sm"}>
                            Update the Late Invoice Date properties
                        </Text>
                    </DialogTitle>
                    <DialogBody padding={"10px"}>
                        <InputGroup startElement={<BiCalendar size={"30px"} />} background={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} borderWidth={"2px"} borderRadius={"lg"} height={"40px"}>
                            <SelectRoot
                                collection={optionCollection}
                                id="number-type"
                                value={[name]}
                                onValueChange={handleChangeName}
                                w={"130px"}
                                borderLeftRadius={1}
                                marginLeft={"35px"}
                                size={"lg"}
                            >
                                <SelectTrigger zIndex={10} >
                                    <SelectValueText placeholder='Select Tax Year'></SelectValueText>
                                </SelectTrigger>
                                <SelectContent portalled={false} zIndex={10}>
                                    {optionCollection.items.map((each: any) =>
                                        <SelectItem item={each} key={each.value} >{each.label}
                                        </SelectItem>)}
                                </SelectContent>
                            </SelectRoot>
                        </InputGroup>
                        <Flex direction={"row"} marginBottom={"10px"} zIndex={0}>
                            <Text w={"230px"} marginTop={"13px"} marginRight={"10px"} textAlign={"center"}>
                                End Date:
                            </Text>
                            <Box background={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} borderWidth={"2px"} borderRadius={"lg"}>
                                <Input w={"255px"} type='datetime-local' value={endDate} onChange={handleChangeEndDate}></Input>
                            </Box>

                            <Text w={"150px"} marginTop={"12px"} marginLeft={"10px"}>
                                Target Date:
                            </Text>
                            <InputGroup background={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} borderWidth={"2px"} borderRadius={"lg"}>
                                <Input w={"335px"} type='datetime-local' value={targetDate} onChange={handleChangeTargetDate} marginLeft={"0px"}></Input>
                            </InputGroup>
                        </Flex>
                        <Flex direction={"row"} zIndex={-10}>
                            <Text w={"167px"} marginTop={"7px"} textAlign={"center"}>Class ID: </Text>
                            <InputGroup marginBottom={"10px"} background={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} borderWidth={"2px"} borderRadius={"lg"}>
                                <Input w={"355px"} value={classID} onChange={handleChangeClassID} ></Input>

                            </InputGroup>
                        </Flex>
                        <Checkbox checked={startDateNull} onChange={() => setStartDateNull(!startDateNull)} w={"250px"} marginLeft={"175px"}>Let StartDate be null? </Checkbox>
                        <InputGroup marginBottom={"10px"} background={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} borderWidth={"2px"} borderRadius={"lg"}>
                            <Input type='datetime-local' value={String(startDate)} onChange={handleChangeStartDate} marginLeft={"10px"} disabled={startDateNull}></Input>
                        </InputGroup>
                    </DialogBody>
                    <DialogFooter>
                        <Button bg={"AbleBlue"} color={"white"} onClick={handleSubmit} _hover={{ bg: "darkblue" }}>
                            Save Changes
                        </Button>
                        <DialogTrigger>
                            <Button marginLeft={"10px"} colorScheme='red' onClick={() => setOpen(false)}>
                                Cancel
                            </Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>

            </DialogRoot>
        </>
    );
}