import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Input, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";

interface CreatePermissionProps {
    cb: Function,
}
export default function CreatePermission(props: CreatePermissionProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const createPermission = () => {
        webHelpers.PutAsync('/api/management/permission', 'auth', { id: name, name: description }).then((data: any) => {
            setLoading(false);
            if (data.id !== name || data.name !== description) {

                toaster.create({

                    title: `${data.status}: Unable to create permission with ID: ${name}, please if check this permission doesn't already exist.`,
                    type: "error",

                });
            }
            else {
                handleClose();
                props.cb();
                toaster.create({

                    title: `Permission ${name} successfully created!`,
                    type: "success",

                });
            }

        }).catch((error) => {
            setLoading(false);
            toaster.create({

                title: `${error.status}: Unable to create permission with ID: ${name}, please if check this permission doesn't already exist.`,
                type: "error",

            });
        })
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <Toaster />
            <DialogRoot
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Dialog.Backdrop />
                <DialogTrigger marginTop={"10px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={"10px"}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        onClick={handleClickOpen}>

                        <BiPlus /> Create Permission
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New Permission"}</DialogTitle>
                    <DialogBody padding={"10px"}>

                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>

                            <Input placeholder="ID" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            <Input placeholder="Description" type="text" autoComplete="new-password" value={description} onChange={handleDescriptionChange}></Input>

                        </Fieldset.Root>


                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => {
                            setLoading(true);
                            createPermission();

                        }}
                            loading={loading}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create Permission</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
