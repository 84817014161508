import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Select, Input, Flex, Text, useDisclosure,
    createListCollection,
    EnvironmentContext
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillFilter, AiOutlinePlus, AiOutlineSync } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";

import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";

import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import { FaFilter } from "react-icons/fa";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";


interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
interface IUserTypes {
    id: string,
    name: string,
}


interface TableControlProps {
    handleEnvChange: Function,
    handleSearchChange: Function,
    handleProfileChange: Function,
    syncHandler: Function,
    search: string,
    selectedType: string,
    userTypes: IUserTypes[],
}
export default function TableControls(props: TableControlProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [isSyncing, setIsSyncing] = useState<boolean>(false);

    const EnvironmentContext = useEnvironment();


    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();

    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        skills[test_object.skill_id] = test_object;

    }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event:any) => {
        setRowsPerPage(event.value[0]);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }



    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    const enviromentCollection = React.useMemo(() => {
        let test = createListCollection({
            items: [

            ]
        })
        EnvironmentContext.environments.map((each: IEnvironment) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })
        console.log("environemnt collection ", test.items);
        return (test)
    }, [EnvironmentContext])

    const userTypesCollection = React.useMemo(() => {
        let test = createListCollection({ items: [] })

        props.userTypes?.map((each: IUserTypes) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })

        return test
    }, [props.userTypes])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    return (
        <>

            <DialogRoot
                open={open}
                // onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"full"}
            >
                <Dialog.Backdrop />
                <DialogTrigger marginTop={"10px"}>
                    <Button bg={"AbleBlue"} color={"white"} _hover={{ color: "AbleYellow" }} size={"sm"}
                        onClick={handleClickOpen} marginLeft={"10px"} marginRight={"10px"}>
                        <FaFilter />Filter
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Filter Results"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Flex direction={"column"} justifyContent={"center"} marginTop={"10px"}>
                            <Flex direction={"row"} justifyContent={"space-between"}>
                                <Text marginTop={"7px"}>Environment: </Text>
                                <SelectRoot size={"sm"} w={"137px"} collection={enviromentCollection} onValueChange={(value) => props.handleEnvChange(value)} value={[EnvironmentContext.selectedEnvironment.id]} defaultValue={["live"]} zIndex={100}
                                    paddingLeft={"5px"} marginTop={"-3px"}>

                                    <SelectTrigger w={"130px"}>
                                        <SelectValueText placeholder="Select Env"></SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent w={"100px"} portalled={false}><>
                                        {/* @ts-ignore */}
                                        {enviromentCollection.items.map((each: any) =>
                                            <SelectItem item={each} key={each.id}>{each.label}</SelectItem>
                                        )}
                                    </>

                                    </SelectContent>
                                </SelectRoot>
                            </Flex>
                            <Flex direction={"row"} justifyContent={"space-between"} marginTop={"10px"}>
                                <Text marginTop={"5px"}>Search: </Text>
                                <Input placeholder="Search Bar" value={props.search} onChange={(value) => props.handleSearchChange(value)} width={"250px"} marginRight={"20px"}></Input>
                            </Flex>

                            <Flex direction={"row"} justifyContent={"space-between"} marginTop={"10px"}>
                                <Text marginTop={"7px"} marginRight={"5px"}>User Type:</Text>
                                <SelectRoot collection={userTypesCollection} onValueChange={(value) => props.handleProfileChange(value)} value={[props.selectedType]} width={"100px"} marginTop={"-5px"} marginLeft={"5px"} size={"md"}>
                                    <SelectTrigger>
                                        <SelectValueText placeholder="Select User Type"></SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent justifyContent={"center"} alignContent={"center"}>
                                        {userTypesCollection.items.map((workflow: any) => (
                                            <SelectItem item={workflow} key={workflow.value}>{workflow.label}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </SelectRoot>
                            </Flex>
                            <Flex direction={"row"} justifyContent={"space-between"} marginTop={"10px"}>
                                <Text marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                                <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                    <SelectTrigger w={"50px"}>
                                        <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent display={"absolute"}>
                                        {rowsCollection.items.map((each) =>
                                            <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                        )}
                                    </SelectContent>
                                </SelectRoot>
                            </Flex>
                            <Button onClick={handleClose} colorScheme="blue" bg={"AbleBlue"} marginTop={"100px"}>Apply Filter</Button>

                        </Flex>
                        {/* need to have a section or method of showing already selected skills */}

                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
