import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Input, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { useColorMode } from "../ui/color-mode";
import { DialogActionTrigger, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { Button } from "../ui/button";



interface CloneRoleDialogProps {
    cloneRoleFunction: Function;
    isSelected: boolean;
}

export default function CloneRole(props: CloneRoleDialogProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const { colorMode, toggleColorMode } = useColorMode();


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }


    return (
        <>
            <Button bg={props.isSelected ? "AbleBlue" : "backgroundDarkGrey"} color={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"}
                _hover={props.isSelected ? { bg: "AbleBlue", color: "AbleYellow" } : { bg: "backgroundDarkGrey", color: "AbleYellow" }}
                onClick={handleClickOpen} overrideBgColor={true}>
                <Text color={colorMode === 'light' ? props.isSelected ? "white" : "AbleBlue" : props.isSelected ? "white" : "white"} display={"flex"} flexDir={"row"}>
                    <MdOutlineSettingsApplications /> Clone
                </Text>
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Clone Role"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Text>Enter the name of the new role.</Text>
                        <Input bg={"backgroundDarkGrey"} borderColor={"backgroundLightGrey"} marginTop={"5px"} borderWidth={"2px"} placeholder=" Name" value={text} onChange={handleTextChange}></Input>
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger>
                            <Button colorScheme="red">Cancel</Button>
                        </DialogActionTrigger>
                        <Button onClick={() => { props.cloneRoleFunction(text); handleClose(); }}>Clone</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
