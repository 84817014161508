import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Select, Input, Flex, Text, useDisclosure,
    createListCollection,
    Box
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import ManageEvents from "./manageEvents";
import { CodeEditor } from "../code/codeEditor";
import { BiAddToQueue } from "react-icons/bi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { setTimeout } from "timers/promises";
import { ITimeProfile } from "../../pages/timeProfiles";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";

interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface ICreateStepProps {
    workflow: IWorkflows,
    timeprofiles: any[],
    cb: Function,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}

export default function AddStep(props: ICreateStepProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);

    const CacheContext = useCache();

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [cooldown, setCooldown] = useState<string>("");
    const [execution_type, setExecutionType] = useState<string>("CodeBased");
    const [timeprofile, setTimeprofile] = useState<string>("");
    const [twilioTaskType, setTwilioTaskType] = useState<string | null>("");
    const [twilioTaskTypeKey, setTwilioTaskTypeKey] = useState<string | null>("");
    const [hours, setHours] = useState<string>("");
    const [minutes, setMinutes] = useState<string>("");
    const [seconds, setSeconds] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [tag, setTag] = useState<string | null>("");

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleCooldownChange = (e: React.FormEvent<HTMLInputElement>) => {
        setCooldown(e.currentTarget.value);
    }
    const handleExecutionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setExecutionType(e.currentTarget.value);
    }
    const handleTimeProfileChange = (e: any) => {
        // setTimeprofile(e.currentTarget.value);
        // console.log("value to set", e.currentTarget.value);
        // console.log("new timeprofile value = ", timeprofile);
    }
    const handleTwilioTaskType = (e: React.FormEvent<HTMLInputElement>) => {
        setTwilioTaskType(e.currentTarget.value);
    }
    const handleTwilioTaskTypeKey = (e: React.FormEvent<HTMLInputElement>) => {
        setTwilioTaskTypeKey(e.currentTarget.value);
    }
    const handleHoursChange = (e: React.FormEvent<HTMLInputElement>) => {
        setHours(e.currentTarget.value.slice(0, 2));
    }
    const handleMinutesChange = (e: React.FormEvent<HTMLInputElement>) => {
        setMinutes(e.currentTarget.value.slice(0, 2));
    }
    const handleSecondsChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSeconds(e.currentTarget.value.slice(0, 2));
    }
    const handleTagChange = (e: React.FormEvent<HTMLInputElement>) => {
        setTag(e.currentTarget.value);
    }



    const webHelpers = useWebHelper();

    function buildTwilioStep() {

        if (twilioTaskTypeKey === '' || twilioTaskType === '') {
            toaster.create({

                title: `Please ensure you supply a TwilioSid and Twilio Task Type`,
                type: "warning",

            });
        } else {
            let payload = {
                'workflow_id': props.workflow.id,
                'name': name,
                'description': description,
                'execution_type': execution_type,
                'cooldown_time': hours + ":" + minutes + ":" + seconds,
                'twilio_task': {
                    'workflow_sid': twilioTaskTypeKey,
                    'echo_interaction_type': twilioTaskType
                },
                'tag': tag,
                'events': [],
            }
            createStep(payload);
        }
    }
    function buildCodeBasedStep() {

        let payload = {
            'workflow_id': props.workflow.id,
            'name': name,
            'description': description,
            'execution_type': execution_type,
            'execution_code': code,
            'cooldown_time': hours + ":" + minutes + ":" + seconds,
            'tag': tag,
            "time_profile_id": timeprofile,
            'events': [],
        }
        createStep(payload);
    }

    function createStep(payload: any) {
        console.log("payload = ", payload)
        webHelpers.PutAsync('/api/workflow/step', 'helios-api', payload).then((data: any) => {
            if (data.response === 400) {
                setLoading(false);
                toaster.create({

                    title: `Unable to create step`,
                    type: "error",

                });

            }
            else {
                setLoading(false);
                onClose();
                props.cb(props.workflow);
            }
        })
    }
    const handleSave = () => {
        setLoading(true);

        if (execution_type === "CodeBased") {
            buildCodeBasedStep();
        }
        else if (execution_type === "TwilioTask") {
            buildTwilioStep();
        }
        else {
            console.log("could not discern execution type")
        }
    }

    const handleClickOpen = () => {
        onOpen();
        setName("");
        setDescription("");
        setHours("");
        setMinutes("");
        setSeconds("");
        setTag("");
        setTimeprofile("");
        setCode("");
    }

    const timeProfilesCollection = React.useMemo(() => {
        let test = createListCollection({ items: [] })

        props.timeprofiles.map((each: Timeprofiles) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })

        return test
    }, [props.timeprofiles])

    const collection = React.useMemo(() => {
        return createListCollection({
            items: [
                props.timeprofiles.map((each: any) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [props.timeprofiles])

    return (
        <>
            <Toaster />
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}
                onClick={handleClickOpen} position={"absolute"} right={5}
            >
                <IoMdAddCircleOutline />
                Create Step
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Create New Step"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Flex direction="column">
                            <Flex direction="row" marginBottom={"10px"} justifyContent={"space-between"}>
                                <Text marginTop={"7px"} marginRight={"5px"} w={"75px"} >Name:</Text>
                                <Box w={"full"} borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"} width={"380px"}>
                                    <Input value={name} onChange={handleNameChange} placeholder={"Name"} colorPalette={"red"} />
                                </Box>
                            </Flex>
                            <Flex direction="row" marginBottom={"10px"} justifyContent={"space-between"}>
                                <Text marginTop={"7px"} marginRight={"5px"} w={"75px"} >Description:</Text>
                                <Box w={"full"} borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"} width={"380px"}>
                                    <Input value={description} onChange={handleDescriptionChange} placeholder="Description"></Input>
                                </Box>
                            </Flex>
                            <Flex direction={"row"} marginBottom={"10px"} justifyContent={"space-around"}>
                                <Text marginRight={"5px"} marginLeft={"8px"} marginTop={"7px"}>Cooldown:</Text>
                                <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                                    <Input value={hours} width={"100px"} textAlign={"center"} onChange={handleHoursChange}  ></Input>
                                </Box>
                                <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                                    <Input value={minutes} width={"100px"} textAlign={"center"} onChange={handleMinutesChange}></Input>
                                </Box>
                                <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                                    <Input value={seconds} width={"100px"} textAlign={"center"} onChange={handleSecondsChange}></Input>
                                </Box>
                            </Flex>
                            <Flex direction="row" justifyContent={"left"} >
                                <Text marginTop={"5px"} marginRight={"0px"} w={"65px"} marginLeft={"50px"}>Tag : </Text>
                                <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"} w={"380px"}>
                                    <Input value={tag !== null ? tag : ""} w={"fill"} placeholder="Enter a tag" onChange={handleTagChange} />
                                </Box>
                            </Flex>
                            <Flex direction={"row"} marginTop={"10px"}>
                                <Text w={"125px"} marginTop={"5px"} marginRight={"5px"}>Time Profile:</Text>
                                <SelectRoot collection={timeProfilesCollection} value={[timeprofile]} onValueChange={handleTimeProfileChange} marginBottom={"10px"} placeholder="Please select a time profile">
                                    <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                                        <SelectTrigger w={"380px"}>
                                            <SelectValueText placeholder="Select a time profile"></SelectValueText>
                                        </SelectTrigger>
                                    </Box>
                                    <SelectContent portalled={false}>
                                        {timeProfilesCollection.items.map((each: any) =>
                                            <SelectItem onClick={() => setTimeprofile(each.value)} item={each} key={each.value}> {each.label}</SelectItem>
                                        )}
                                    </SelectContent>
                                </SelectRoot>
                            </Flex>
                            {/* <Text>{timeprofile} </Text> */}
                            {/* <Flex direction={"row"} marginBottom={"10px"}>
                                <Text>Execution Type: {execution_type === "TwilioTask" ? " Twilio-Based" : "Code-Based"}</Text>
                                {execution_type === "TwilioTask" ?
                                    <Switch isChecked={execution_type === "TwilioTask"} onChange={() => setExecutionType("CodeBased")} marginTop={"2px"} marginLeft={"10px"} />
                                    :
                                    <Switch isChecked={execution_type === "TwilioTask"} onChange={() => setExecutionType("TwilioTask")} marginTop={"2px"} marginLeft={"10px"} />
                                }
                            </Flex> */}
                            {/* {execution_type === "TwilioTask" ?
                                <>
                                    <Flex direction="row" justifyContent={"left"} marginBottom={"10px"}>
                                        <Text marginTop={"5px"}>Twilio Task Type Key: </Text>
                                        <Input value={twilioTaskTypeKey !== null ? twilioTaskTypeKey : "N/A"} onChange={handleTwilioTaskTypeKey} width={"175px"} marginLeft={"25px"} />
                                    </Flex>
                                    <Flex direction="row" justifyContent={"left"}>
                                        <Text marginTop={"5px"}>Twilio Task Type: </Text>
                                        <Input value={twilioTaskType !== null ? twilioTaskType : "N/A"} onChange={handleTwilioTaskType} width={"175px"} marginLeft={"55px"} />
                                    </Flex>
                                </>
                                :
                                <>
                                    {execution_type !== "TwilioTask" &&
                                        <Flex height={"50%"}>
                                            <CodeEditor code_id={""} stepFriendlyName={name} buttonText="Edit Code" mode={"create-event"} cb={setCode} intital_value={code} quick_outcome_code_id={null}/>
                                        </Flex>
                                    }
                                </>
                            } */}
                            <Flex justifyContent={"center"}>
                                <Button marginLeft={"10px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"20px"} loading={loading}
                                    onClick={handleSave} disabled={name === "" || description === "" || hours === "" || minutes === "" || seconds === "" || tag === "" || timeprofile === ""}>Create Step</Button>
                            </Flex>
                        </Flex>

                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
