import { createListCollection, useDisclosure } from "@chakra-ui/react";
import { Spinner, Table, Select, Input, Flex, Image, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateWorkflow from "../../components/workflows/createWorkflow";
import EditWorkflow from "../../components/workflows/editWorkflow";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import Refresh from "../../components/refresh";
import "./loading.css"
import { useColorMode } from "../../components/ui/color-mode";
import { Button } from "../../components/ui/button";
import TableControls2 from "../../components/table/tableControlsComponent";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}
export const WorkflowsPage = () => {
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IWorkflows[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
    const [nextPage, setNextPage] = useState<IWorkflows[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);

    const { open, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();
    const { colorMode, toggleColorMode } = useColorMode();
    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {
            console.log("environment is null?")
        }
    }, [])
    function goToNextPage(pageToLoad: number, searchQuery: string) { //used for infinite scrolling
        webHelpers.GetAsync('/api/workflows/?page=' + (pageToLoad + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.workflows)
                data.workflows.forEach((element: IWorkflows) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.maximum_pages)
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function searchWorkflows(searchQuery: string) {
        if (searchQuery !== '') {
            webHelpers.GetAsync('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                    // setRows(defaultWorkflow);
                }
                else {
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)
                }
            }).catch((error) => {
                console.log("error fetching workflows (with search), error message: ", error);
            })
        }
        else {
            webHelpers.GetAsync('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)
                }
            }).catch((error) => {
                console.log("error fetching workflows, error message : ", error);
                setLoading(false);
            })
        }

    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchWorkflows(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchWorkflows(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);

    const refresh = () => {
        setIgnore(true);
        searchWorkflows("");
        setLoading(true);
        setNextPageNo(page);

    }

    const handleProfileChange = (event: any) => {
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
    }

    const handleNavigate = (target: string) => {
        navigate("/workflows/view/" + EnvironmentContext.selectedEnvironment.id + "/" + target);
    }

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        setLoading(true);
    }, [])

    const enviromentCollection = React.useMemo(() => {
        return createListCollection({
            items: [
                EnvironmentContext.environments.map((each: IEnvironment) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [EnvironmentContext])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })



    return (
        <>


            <Flex direction={"column"} className="animate__animated animate__fadeIn">
                <>
                    <Heading marginTop={"20px"} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
                    >Workflow Management</Heading>
                    <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                        <TableControls2 showTwilioSync={false} showUserTypes={false} handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={handleProfileChange} syncHandler={() => console} search={search} selectedType={selectedType}
                            userTypes={userTypes} handleLoadingChange={setLoading} handleRowsPerPageChange={handleChangeRowsPerPage} refresh={refresh} page={page} maxPage={maxPages} setPage={setPage} rowsPerPage={rowsPerPage} />
                        <Flex marginTop={"10px"}>
                            <CreateWorkflow cb={() => { setLoading(true); searchWorkflows(search) }} />
                        </Flex>

                    </Flex>
                    <Flex direction={'row'} h={loading ? "fit-content" : 750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                        {/* table to show list of users */}
                        <Text display={rows.length === 0 && !loading ? "flex" : "none"} className={"animated__animated animate__fadeIn"} marginTop={"150px"} fontSize={"xl"}>No Workflows Found!</Text>
                        <>
                            <Flex display={loading ? "flex" : "none"} direction={"column"} justifyContent={"center"} marginTop={"170px"} className="animate__animated animate__fadeIn">
                                <div className="scene">
                                    <div className="cube-wrapper">
                                        <div className="cube">
                                            <div className="cube-faces">
                                                <div className="cube-face shadow"></div>
                                                <div className="cube-face bottom"></div>
                                                <div className="cube-face top"></div>
                                                <div className="cube-face left"></div>
                                                <div className="cube-face right"></div>
                                                <div className="cube-face back"></div>
                                                <div className="cube-face front"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Text as={"b"} fontSize={"md"} marginTop={"60px"}>Currently fetching workflows</Text>
                                <Text as={"i"} fontSize={"sm"}>{"(This may take a moment)"}</Text>
                            </Flex>
                            <Table.Root variant={"line"} display={rows.length === 0 || loading ? "none" : "flex"} className="animate__animated animate__fadeIn" w={"100%"} justifyContent={"center"} size={"lg"}>
                                <InfiniteScroll

                                    dataLength={rows.length}
                                    next={() => {

                                        if (nextPageNo < page) {
                                            setNextPageNo(page + 1);
                                            goToNextPage(page + 1, search)
                                        }
                                        else {
                                            goToNextPage(nextPageNo + 1, search)
                                            setNextPageNo(nextPageNo + 1);
                                        }
                                    }}
                                    hasMore={nextPageNo <= maxPages - 2}
                                    loader={
                                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                            <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                            {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                        </Flex>
                                    }
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                        </p>
                                    }
                                    scrollThreshold={1}
                                >
                                    <Table.Caption>
                                        <Text>
                                            {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                        </Text>
                                    </Table.Caption>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.ColumnHeader>Name</Table.ColumnHeader>
                                            <Table.ColumnHeader>Event Id</Table.ColumnHeader>
                                            <Table.ColumnHeader>Description</Table.ColumnHeader>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>

                                        {rows.map((each) =>
                                            <Table.Row _hover={{ bg: colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey" }} bg={each.id === selectedWorkflow ? "backgroundLightGrey" : "backgroundDarkGrey"} borderColor={"AbleBlue"}
                                                borderWidth={each.id === selectedWorkflow ? "2px" : "0px"}>
                                                <Table.Cell w={"300px"} padding={"10px"}>{each.name}</Table.Cell>
                                                <Table.Cell w={"500px"}>{each.event_id}</Table.Cell>
                                                <Table.Cell>{each.description}</Table.Cell>
                                                <Table.Cell>
                                                    <Button onClick={() => handleNavigate(each.id)} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} size={"sm"}>
                                                        View</Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )}
                                    </Table.Body>
                                </InfiniteScroll>


                            </Table.Root>

                        </>

                        {/* <ScaleFade initialScale={0.1} in={defaultView}>
                        </ScaleFade> */}

                    </Flex>
                </>

            </Flex>
        </>
    )
}
