import { createListCollection, Group, InputAddon, SelectItem, useDisclosure } from "@chakra-ui/react";
import { Table, Spinner, TableCaption, Box, Input, Flex, Image, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall, BiSearch } from "react-icons/bi";
import "../../App.css"
import { IVectorMapOptions, Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import EditUserMobile from "../../components/user/editUserMobile"
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import { create, forEach } from "mathjs";
import { BsChevronDoubleDown } from "react-icons/bs";
import TableControls from "../../components/table/tableControls";
import Refresh from "../../components/refresh";
import NewKey from "../../components/user/newKey";
import { useColorMode } from "../../components/ui/color-mode";
import { Toaster, toaster } from "../../components/ui/toaster";
import { Button } from "../../components/ui/button";
import { SelectContent, SelectLabel, SelectRoot, SelectTrigger, SelectValueText } from "../../components/ui/select";
import TableControls2 from "../../components/table/tableControlsComponent";

export interface User {
    id: string,


    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}

export const UsersPage = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<User[]>([]);
    const [nextPage, setNextPage] = useState<User[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const { colorMode, toggleColorMode } = useColorMode();


    const { open, onToggle } = useDisclosure();
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();


    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setPage(0);

    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        console.log(event)
        setRowsPerPage(event.value[0]);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {

        }
    }, [])

    function goToNextPage(pageToLoad: number) {
        webHelpers.GetAsync('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (pageToLoad + 1) + '/' + search, "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.users)
                data.users.forEach((element: User) => {
                    setRows(rows => [...rows, element])
                })
                console.log(rows)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }

    function searchUsers(search: string) {
        //@ts-ignore
        webHelpers.GetAsync('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (page + 1) + '/' + search, "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setRows(data.users)
                setMaxPages(data.max_pages)
                setLoading(false);
            }
        });
    }
    function getUserTypes() {
        webHelpers.GetAsync('/api/usertypes', "auth").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("data undefined or null ");
            }
            else {
                setUserTypes(data);
            }
        }).catch((error) => {
            console.log("error bad API call, error message : ", error)
        });
    }

    useEffect(() => {
        setNextPageNo(page + 1);
    }, [page]);

    useEffect(() => {
        getUserTypes();
    }, [])

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchUsers(search)
            }, 100);
            return () => clearTimeout(requestTimeout);
        } else {
            searchUsers(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    const refresh = () => {
        setIgnore(true);
        searchUsers(search);
        setLoading(true);
        setNextPageNo(page);
        userTypes.map((workflow: IUserTypes) => (
            console.log(workflow.id, workflow.name)
        ))
    }
    const removeFromRows = (id: string) => {
        // @ts-ignore
        let newRows = [];
        rows.forEach(element => {
            if (element.id === id) {
                console.log("user to be disabled is : ", element.id);
            }
            else {
                newRows.push(element);
            }
            //@ts-ignore
            setRows(newRows);
        });
    }
    const handleStaffClick = () => {
        navigate("/staff");
    }
    const handleKeyDown = (event: any) => {

    }

    function sortList(a: IWorkResult, b: IWorkResult) {
        if (a.RouteIndex < b.RouteIndex) {
            return -1;
        }
        if (a.RouteIndex > b.RouteIndex) {
            return 1;
        }
        return 0;
    }
    const handleProfileChange = (event: any) => {
        setLoading(true);
        setSelectedType(event.value[0]);
        console.log(event.value[0]);
        setPage(0);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        console.log("env change event = ", event);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
    }
    useEffect(() => {
        onToggle();
    }, [loading]);

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    useEffect(() => {
        console.log("pageNo = ", page);
        console.log("nextPageNo = ", nextPageNo);
        console.log("max pages = ", maxPages)
    }, [page, nextPageNo])

    const enviromentCollection = React.useMemo(() => {
        let test = createListCollection({
            items: [

            ]
        })
        EnvironmentContext.environments.map((each: IEnvironment) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })
        console.log("environemnt collection ", test.items);
        return (test)
        // test.items.push({label: "test", value: "string"})

        // return createListCollection({
        //     items: [
        //         EnvironmentContext.environments.map((each: any) => {
        //             test['items'] = [...test['items'],  { label: each.name, value: each.id } ]
        //         })
        //     ]
        // })
    }, [EnvironmentContext])

    const userTypesCollection = React.useMemo(() => {
        let test = createListCollection({ items: [] })

        userTypes.map((each: IUserTypes) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })

        return test
    }, [userTypes])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })
    const syncHandler = () => {
        setIsSyncing(true);
        const promise = new Promise<void>((resolve, reject) => {
            webHelpers.PostAsync('/api/workers/roles/sync', "zeus-api", {}).then((data: any) => {
                console.log("data: ", data);
                if (data === null) {
                    setIsSyncing(false);
                    resolve();
                    toaster.create({
                        title: "Successfully synced with Twilio",
                        type: "success",
                    })

                }
                else {
                    setIsSyncing(false);
                    reject();
                    toaster.create({
                        title: "Sync with Twilio failed",
                        type: "error",
                    })
                }
            }).catch((error) => {
                console.log("sync error : ", error)
                reject();
                setIsSyncing(false);
            })
        });
        toaster.promise(promise, {
            success: {
                title: "Successfully Synced to Twilio!",
                type: "success",

            },
            error: {
                title: "Sync Failed",
                type: "error",
            },
            loading: { title: "Syncing with Twilio..." }
        })

    };
    return (

        // <ScaleFade initialScale={1} in={isPageOpen}>

        <Flex width={"100%"} justifyContent={"center"} direction={"column"}>
            <Toaster />
            <Heading marginTop={"20px"} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
            >User Management</Heading>

            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                {/* @ts-ignore */}
                <TableControls2 showTwilioSync={true} showUserTypes={true} handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={handleProfileChange} syncHandler={() => console.log("")} search={search} selectedType={selectedType}
                    userTypes={userTypes} handleLoadingChange={setLoading} handleRowsPerPageChange={handleChangeRowsPerPage} refresh={refresh} page={page} maxPage={maxPages} setPage={setPage} rowsPerPage={rowsPerPage} />
                {/* <NewUser cb={() => {refresh();}} /> */}
                <Flex display={{ base: "flex", lg: "none" }}>
                    <TableControls handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={handleProfileChange} syncHandler={syncHandler} search={search} selectedType={selectedType}
                        userTypes={userTypes} />

                </Flex>
                {selectedType === "77be288b-5caf-4d55-86a2-59b51db66dae" ?
                    <NewKey cb={() => { refresh() }} /> :
                    <NewUser cb={() => { refresh() }} />
                }
            </Flex>
            <Flex direction={'row'} h={{ base: 400 * ((rows.length / rowsPerPage) + 1) + 10 + "px", lg: 500 * ((rows.length / rowsPerPage) + 1) + 10 + "px" }} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                {/* table to show list of users */}
                <>
                    {!loading ?
                        // <ScaleFade initialScale={1} in={!loading} reverse={loading}>

                        <InfiniteScroll

                            dataLength={rows.length}
                            next={() => {
                                if (nextPageNo < page) {
                                    goToNextPage(page + 1)
                                    setNextPageNo(page + 1);
                                }
                                else {
                                    goToNextPage(nextPageNo)
                                    setNextPageNo(nextPageNo + 1);
                                }
                            }}
                            hasMore={nextPageNo < maxPages}
                            loader={
                                <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                    <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                    {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                </Flex>
                            }
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                </p>
                            }
                            scrollThreshold={0.9}
                        >
                            <Table.Root variant={"line"} w={"90vw"} size={"lg"} height={"100%"}>


                                <TableCaption>
                                    <Text>
                                        {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                    </Text>
                                </TableCaption>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColumnHeader display={{ base: "revert" }}>Name</Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Department</Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }} >Email</Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }} ></Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }} ></Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }} ></Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "none", lg: "revert" }} ></Table.ColumnHeader>
                                        <Table.ColumnHeader display={{ base: "revert", lg: "none" }} w={"25%"} ></Table.ColumnHeader>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body w={"100vw"}>
                                    {rows.map((each) =>
                                        <Table.Row height={{ base: "50px", lg: "auto" }}>
                                            <Table.Cell display={{ base: "revert" }}>{each.full_name}</Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}>{each.department}</Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}>{each.email_address}</Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}><UserAppPermissionsModal user={each} cb={refresh} /></Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}><PasswordReset {...each} /></Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}><EditUserModal user={each} cb={refresh} /></Table.Cell>
                                            <Table.Cell display={{ base: "none", lg: "revert" }}><EnableDisable user={each} cb={removeFromRows} /></Table.Cell>
                                            <Table.Cell display={{ base: "flex", lg: "none" }} w={"100%"} ><EditUserMobile user={each} cb={refresh} /></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table.Root>
                        </InfiniteScroll>

                        // </ScaleFade>
                        :
                        // <ScaleFade initialScale={0.5} in={isOpen} reverse={!loading}>
                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"}>
                            <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"300px"}></Spinner>
                            {/* {colorMode === 'light' ?
                                <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                                :
                                <Spinner color="AbleYellow" marginTop={"100px"} marginBottom={"100px"} size={"xl"} />
                            } */}
                        </Flex>
                        // {/* </ScaleFade> */}

                    }
                </>

                {/* <ScaleFade initialScale={0.1} in={defaultView}>
                    </ScaleFade> */}

            </Flex>
        </Flex>

        // </ScaleFade>
    )
}
