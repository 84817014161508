import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { RiEdit2Line } from "react-icons/ri";
import { GrRefresh } from "react-icons/gr";
import { IoMdRefresh } from "react-icons/io";
import "./style.css"
import { Button } from "../ui/button";

interface RefreshProps {
    cb: Function,
}
export default function Refresh(props: RefreshProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [animate, setAnimate] = useState<boolean>(false);

    const webHelpers = useWebHelper();
    
    const handleClick = () => {
        setAnimate(true);
        props.cb();
        setTimeout((() => setAnimate(false)), 750);
    }

    return (
        <>
            <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                _hover={{ bg: "AbleYellow", color: "white" }}
                _active={{ bg: "#ECB500" }} onClick={handleClick}>
                <Text fontSize={"20px"}><IoMdRefresh className={animate ? "icn-spinner" : "blank"}/> </Text>
            </Button>
        </>
    );
}
