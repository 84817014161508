import { createListCollection, TableColumnHeader, useDisclosure } from "@chakra-ui/react";
import { Spinner, Table, Select, Input, Flex, Image, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateWorkflow from "../../components/workflows/createWorkflow";
import EditWorkflow from "../../components/workflows/editWorkflow";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import CreateOutcome from "../../components/outcomes/createOutcome";
import EditOutcome from "../../components/outcomes/editOutcome";
import Refresh from "../../components/refresh";
import { useColorMode } from "../../components/ui/color-mode";
import { Button } from "../../components/ui/button";
import TableControls2 from "../../components/table/tableControlsComponent";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IWorkflows {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
}
interface IOutcomes {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
export const OutcomesPage = () => {
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<IOutcomes[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
    const [nextPage, setNextPage] = useState<IWorkflows[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const { colorMode, toggleColorMode } = useColorMode();

    const { open, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();

    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {
            console.log("environment is null?")
        }
        document.title = "Viabl | Outcome Management"
    }, [])
    function goToNextPage(pageToLoad: number, searchQuery: string) { //used for infinite scrolling
        webHelpers.GetAsync('/api/config/outcomes?page=' + (pageToLoad + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                setNextPage(data.workflows)
                data.workflows.forEach((element: IOutcomes) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(Math.ceil(Number((data.workflows.length / rowsPerPage))));
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function searchOutcomes(searchQuery: string) {
        if (searchQuery !== '') {
            webHelpers.GetAsync('/api/config/outcomes?page=' + (page + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                    // setRows(defaultWorkflow);
                }
                else {
                    setRows(data);
                    if (Math.ceil(Number((data.length / rowsPerPage))) === 0) {
                        setMaxPages(1);
                    }
                    else {
                        setMaxPages(Math.ceil(Number((data.length / rowsPerPage))));
                    }

                    setLoading(false);
                }
            }).catch((error) => {
                console.log("error fetching workflows (with search), error message: ", error);
            })
        }
        else {
            webHelpers.GetAsync('/api/config/outcomes?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data === undefined || data === null || data.status === 400) {
                    console.log('unable to fetch workflows', { 'variant': 'error' })
                }
                else {
                    setRows(data);
                    if (Math.ceil(Number((data.length / rowsPerPage))) === 0) {
                        setMaxPages(1);
                    }
                    else {
                        setMaxPages(Math.ceil(Number((data.length / rowsPerPage))));
                    }
                    setLoading(false);
                }
            }).catch((error) => {
                console.log("error fetching workflows, error message : ", error);
                setLoading(false);
            })
        }

    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchOutcomes(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchOutcomes(search)
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);

    const refresh = () => {
        setIgnore(true);
        searchOutcomes("");
        setLoading(true);
        setNextPageNo(page);

    }

    const handleProfileChange = (event: any) => {
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
    }
    const handleNavigate = (target: string) => {
        navigate("/workflows/view/" + EnvironmentContext.selectedEnvironment.id + "/" + target);
    }

    useEffect(() => {
        setRows([])
    }, [EnvironmentContext.selectedEnvironment])

    const enviromentCollection = React.useMemo(() => {
        return createListCollection({
            items: [
                EnvironmentContext.environments.map((each: IEnvironment) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [EnvironmentContext])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })
    return (

        // <ScaleFade initialScale={1} in={!isOpen}>

        <>
            <Heading marginTop={"20px"} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
            >Outcome Management</Heading>
            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                <TableControls2 handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={() => console.log("")} syncHandler={() => console.log("")} search={search} selectedType={selectedType}
                    userTypes={undefined} showTwilioSync={false} showUserTypes={false} handleLoadingChange={setLoading} handleRowsPerPageChange={handleChangeRowsPerPage} refresh={Refresh} page={page} maxPage={maxPages} setPage={setPage} rowsPerPage={rowsPerPage} />

                <CreateOutcome cb={() => { setLoading(true); searchOutcomes(search) }} />
            </Flex>
            <Flex direction={'row'} h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                {/* table to show list of users */}
                {rows.length === 0 ?
                    // <ScaleFade initialScale={1} in={!loading}>
                    <>
                        {!loading &&
                            <Text marginTop={"150px"} fontSize={"xl"}>No Outcomes Found!</Text>
                        }
                    </>
                    // </ScaleFade>
                    :
                    <>
                        {/* <ScaleFade initialScale={1} in={!loading}> */}
                        <Table.Root variant={"line"} justifyContent={"center"} display={"flex"} w={"90%"}>
                            <InfiniteScroll
                                dataLength={rows.length}
                                next={() => {

                                    if (nextPageNo < page) {
                                        setNextPageNo(page + 1);
                                        goToNextPage(page + 1, search)
                                    }
                                    else {
                                        goToNextPage(nextPageNo + 1, search)
                                        setNextPageNo(nextPageNo + 1);
                                    }
                                }}
                                hasMore={nextPageNo <= maxPages - 2}
                                loader={
                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                        <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                        {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                    </Flex>
                                }
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                    </p>
                                }
                                scrollThreshold={1}
                            >
                                <Table.Caption>
                                    <Text>
                                        {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                    </Text>
                                </Table.Caption>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColumnHeader>ID</Table.ColumnHeader>
                                        <Table.ColumnHeader>Friendly ID</Table.ColumnHeader>
                                        <Table.ColumnHeader>Name</Table.ColumnHeader>
                                        <Table.ColumnHeader>Description</Table.ColumnHeader>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>

                                    {rows.map((each) =>
                                        <Table.Row>
                                            <Table.Cell paddingRight={"20px"}>{each.id}</Table.Cell>
                                            <Table.Cell paddingRight={"20px"}>{each.friendly_id}</Table.Cell>
                                            <Table.Cell paddingRight={"20px"}>{each.name}</Table.Cell>
                                            <Table.Cell paddingRight={"20px"}>{each.description}</Table.Cell>
                                            <Table.Cell>
                                                <EditOutcome cb={() => searchOutcomes(search)} outcome={each} />
                                            </Table.Cell>
                                        </Table.Row>
                                    )}

                                </Table.Body>
                            </InfiniteScroll>


                        </Table.Root>
                        {/* </ScaleFade> */}

                    </>
                }
                {/* <ScaleFade initialScale={0.1} in={defaultView}>
                    </ScaleFade> */}

            </Flex>
        </>

        // </ScaleFade>
    )
}
