import * as React from "react";
import { useState, useEffect } from "react";
import { IWebHelperErrorResponse, useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Table, Select, Input, Flex, Text, useDisclosure, Spinner,
    Fieldset,
    createListCollection
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import { useColorMode } from "../ui/color-mode";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogActionTrigger, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { SearchBox } from "../searchBox";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";

interface Event {
    id: string,
    name: string,
}
interface CreateWorkflowProps {
    cb: Function,
}
export default function CreateWorkflow(props: CreateWorkflowProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [selectedEvent, setSelectedEvent] = useState<string>("");
    const [trueMaxPage, setTrueMaxPage] = useState<number>(0);


    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const webHelpers = useWebHelper();

    const { colorMode, toggleColorMode } = useColorMode();

    const handleClickOpen = () => {
        setName("");
        setDescription("");
        setSelectedEvent("");
        setPage(0);
        setSearch("");
        searchEvents("");
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const handleSubmit = () => {
        let payload = {
            'name': name,
            'description': description,
            'event_id': selectedEvent,
            'code': '// start coding here',
            'steps': [
                {
                    'name': 'Default Step Name',
                    'description': 'Default Description',
                    'tag': 'default',
                    'cooldown_time': '00:01:00',
                    'estimated_length': '00:01:00',
                    'execution_type': 'CodeBased',
                    'execution_code': `//Event Code`,
                    'events': [],
                }
            ]
        }
        webHelpers.PostAsync('/api/workflow/create', 'helios-api', payload).then((data: any) => {
            if (data.status === 400 || data.status === 404 || data.status === 500 || data.status === 401) {
                console.log(`${data.status}: Some error ocurred processing this request.`, { 'variant': 'error' });
            }
            else {
                handleClose();
                props.cb();
                toaster.create({

                    title: `Workflow ${payload.name} successfully created!`,
                    type: "success",

                });

            }
        })
    }
    // useEffect(() => {
    //     searchEvents();
    // }, [search]);

    const searchEvents = (searchString: string) => {
        if (searchString !== '') {
            webHelpers.GetAsync('/api/events/?filter=' + searchString, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    // toaster.create({
                    //     
                    //     title: `Unable to fetch permissions with this name, please try another`,
                    //     type: "error",
                    //     
                    // });
                    setEvents([]);
                    setMaxPages(1);
                }
                else {

                    if (data.length / rowsPerPage < 1) {

                        setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);

                    }
                    else {

                        setMaxPages(Math.floor((data.length / rowsPerPage)));
                    }
                    setEvents(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setLoading(false);
                }
            }).catch((error: IWebHelperErrorResponse) => {
                // toaster.create({
                //     
                //     title: `Unable to fetch permissions with this name, please try another`,
                //     type: "error",
                //     
                // });
            });
        }
        else {
            setMaxPages(trueMaxPage);
            webHelpers.GetAsync('/api/events/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, 'helios-api').then((data: any) => {
                if (data.length === 0 || data.response === 400) {
                    console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
                }
                else {

                    console.log(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                    setEvents(data)
                    setLoading(false);
                }
            }).catch((error: IWebHelperErrorResponse) => {
                console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
            });
        }

    }
    useEffect(() => {

        searchEvents(search === '' ? '' : search);
    }, [page, rowsPerPage])

    useEffect(() => {
        webHelpers.GetAsync('/api/events/', 'helios-api').then((data: any) => {
            if (data.length === 0 || data.response === 400) {
                console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
            }
            else {
                if (data.length / rowsPerPage < 1) {

                    setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);

                }
                else {

                    setMaxPages(Math.floor((data.length / rowsPerPage)) + 1);
                }
                setTrueMaxPage((Math.floor((data.length / rowsPerPage)) + 1));
                setLoading(false);
            }
        }).catch((error: IWebHelperErrorResponse) => {
            console.log('Unable to fetch permissions with this name, please try another', { 'variant': 'error' });
        });
    }, [])

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        searchEvents(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        setRowsPerPage(event.value[0]);
        setPage(0);
    };
    const handleEventClick = (id: string) => {
        setSelectedEvent(id);
    }

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    return (
        <>
            <Toaster />
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginLeft={"30px"}
                onClick={handleClickOpen} >
                <BiPlus />
                Create Workflow
            </Button>
            <DialogRoot
                open={open}

                onOpenChange={onClose}
                size={"xl"}
                aria-describedby="alert-dialog-slide-description"
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Create New Workflow"}</DialogTitle>
                    <DialogBody padding={"10px"}>

                        <Fieldset.Root marginBottom={"20px"}>
                            <Fieldset.HelperText color={"textPrimaryInverted"}>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>
                            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"lg"}>
                                <Input padding={"5px"} bg={"backgroundDarkGrey"} placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>
                            </Flex>
                            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"lg"} marginTop={"5px"}>
                                <Input padding={"5px"} bg={"backgroundDarkGrey"} type="text" autoComplete="new-password" placeholder="Description" value={description} onChange={handleDescriptionChange}></Input>
                            </Flex>
                        </Fieldset.Root>
                        <Text as={"i"} marginTop={"20px"} fontSize={"md"}>Use the table below to select an Event for the new Workflow</Text>
                        <Flex direction={"row"}>

                            <SearchBox search={search} handleSearchChange={handleSearchChange} />
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Rows per Page:</Text>
                            <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                    <SelectTrigger w={"50px"}>
                                        <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent display={"absolute"} portalled={false}>
                                        {rowsCollection.items.map((each) =>
                                            <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                        )}
                                    </SelectContent>
                                </SelectRoot>
                            </Flex>
                            <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"}>Page {page + 1} of {maxPages} </Text>
                            <Button bgColor={"AbleBlue"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== 0 && setPage(page - 1);
                                setLoading(true);
                            }} disabled={page === 0}><AiFillCaretLeft /></Button>
                            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                page !== maxPages - 1 && setPage(page + 1)
                                setLoading(true);
                            }} disabled={page === maxPages - 1}><AiFillCaretRight /></Button>
                        </Flex>
                        {events.length === 0 ?
                            <Flex justifyContent={"center"}>
                                <Text marginTop={"50px"} fontSize={"xl"}>No Workflows Found!</Text>
                            </Flex>
                            :

                            <>
                                {!loading ?
                                    <Table.Root variant={"line"}>
                                        <Table.Header>
                                            <Table.Row bg={"backgroundLightGrey"}>
                                                <Table.ColumnHeader>Name</Table.ColumnHeader>
                                                <Table.ColumnHeader>ID</Table.ColumnHeader>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {events.map((each) =>
                                                <Table.Row _hover={{ bg: colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey" }} onClick={() => handleEventClick(each.id)} borderColor={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} borderWidth={each.id === selectedEvent ? "3px" : "0px"}
                                                    bg={each.id === selectedEvent ? (colorMode === 'light' ? "whitesmoke" : "backgroundLightGrey") : "backgroundDarkGrey"} pointerEvents={"visibleFill"} cursor={"pointer"} height={"35px"}>
                                                    <Table.Cell>{each.name}</Table.Cell>
                                                    <Table.Cell>{each.id}</Table.Cell>
                                                </Table.Row>
                                            )}

                                        </Table.Body>

                                    </Table.Root>
                                    :
                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                                        <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} />
                                    </Flex>

                                }
                            </>
                        }

                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => handleSubmit()}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create Workflow</Button>
                        <DialogActionTrigger>
                            <Button colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogActionTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
