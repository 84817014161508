import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Select, Flex, useDisclosure,
    createListCollection,
    Text
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { IoMan } from "react-icons/io5"
import { IoMdAdd } from "react-icons/io"
import { useDictionary } from "../../scripts/useDictionary";
import GetUserConfirm from "../confirm";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "./addSkillRow";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../../components/ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger } from "../ui/select";



interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
    handleAddedSkills: Function,
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}
interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};

interface IAddSkillMobileProps {
    levels: Level[],
    handleAddedSkills: Function,
    name: string,
    skill: Skill,

}
export default function AddSkillMobile(props: Skill) {
    const { open, onOpen, onClose } = useDisclosure();
    const CacheContext = useCache();
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [label, setLabel] = useState<string>(props.levels[0].id);
    const [level, setLevel] = useState<Level>(props.levels[0]);

    const [maxPages, setMaxPages] = useState<number>(1);

    const webHelpers = useWebHelper();



    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])

    useEffect(() => {
        onClearSkills();
    }, [open])
    const handleLevelChange = (e: any) => {
        setLabel(e.target.value);
        props.levels.forEach(element => {
            if (element.id === e.target.value) {
                setLevel(element);
            }

        });
    }
    const addSkill = () => {
        if (level !== undefined) {
            const addedSkill: ISkillToAdd = {
                skill_id: props.id,
                level_id: level.id,
                name: props.name,
                level_name: level.name,
            }
            props.handleAddedSkills(addedSkill)
        } else {
            console.log(`Please choose a level before clicking Add`, { 'variant': 'warning' });
        }
    }
    const test = React.useMemo(() => {
        return createListCollection({
            items: [
                props.levels.map((each: Level) => {
                    return { label: each.name, value: each.id, order: each.order, skillId: each.skill_id, key: each.key }
                })
            ]
        })
    }, [props.levels])

    return (
        <Flex>
            <Button
                bg={"AbleBlue"}
                color={"white"}
                display={{ base: "revert", lg: "none" }}
                marginLeft={"-40px"}
                onClick={() => {
                    onOpen();
                    setPage(0)
                    populateSkills();
                }}>
                +
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "full", lg: "xl" }}

            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Add Skill: " + props.name}</DialogTitle>
                    <DialogBody>
                        <SelectRoot collection={test} value={[label]} onValueChange={handleLevelChange} defaultValue={[props.levels[0].name]} display={{ base: "none", lg: "revert" }} zIndex={100}>
                            <SelectTrigger defaultValue={props.levels[0].name}>
                                <Text>{label} </Text>
                            </SelectTrigger>
                            <SelectContent portalled={false}>
                                {test.items.map((each: any) =>
                                    <SelectItem item={each}>{each.label}</SelectItem>
                                )}
                            </SelectContent>
                        </SelectRoot>
                        <Flex direction={"column"} justifyContent={"space-around"} marginTop={"20px"}>
                            <Button bg={"AbleBlue"}
                                color={"white"}
                                display={{ base: "revert", lg: "none" }}
                                marginBottom={"20px"}
                                onClick={addSkill}>Add Skill</Button>
                            <Button colorScheme={"red"}
                                onClick={onClose}>Cancel</Button>
                        </Flex>
                    </DialogBody>
                    <DialogFooter>

                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </Flex>
    );
}

