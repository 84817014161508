import { createSystem, defaultConfig } from "@chakra-ui/react";

export const system = createSystem(defaultConfig, {
    theme: {
        // config: {
        //     initialColorMode: {value: "system"},
        // },
        semanticTokens: {
            //useSystemColorMode: "true",
            colors: {
                accent: {
                    value: {base: '#0173C7', _dark: '#66b3ff'}
                },
                // accent: {
                //     default: '#0173C7',
                //     _dark: '#66b3ff',
                // },
                AbleBlue: {
                    value: {base: '#06579c', _dark: '#043662'}
                },
                AbleYellow: {
                    value: {base: "#ffc107", _dark: "#cc9900"}
                },
                accentLight: {
                    value: {base: "#ceebfd", _dark: "#004080"}
                },
                accentDark: {
                    value: {base: "#014a7f", _dark: "#99ccff"}
                },
                backgroundGrey: {
                    value: {base: "#F8F8F8", _dark: "gray.700"}
                },
                backgroundDarkGrey: {
                    value: {base: "#ffffff", _dark: "#1a1a1a"}
                },
                backgroundLightGrey: {
                    value: {base: "#ffffff", _dark: "#333333"}
                },
                backgroundHighlight: {
                    value: {base: "#4d4d4d", _dark: "#4d4d4d"}
                },
                primary: {
                    value: {base: "#ccffcc", _dark: "#353d4b"}
                },
                wrapping: {
                    value: {base: "#ffcc66", _dark: "#664200"}
                },
                dialPadBg: {
                    value: {base: "#38a169", _dark: "#00c543"}
                },
                dialPadBgHover: {
                    value: {base: "#2e8456", _dark: "#00e64d"}
                },
                dialPadBgActive: {
                    value: {base: "#1b4b31", _dark: "1aff66"}
                },
                primaryText: {
                    value: {base: "white", _dark: "gray.800"}
                },
                primaryTextInverted: {
                    value: {base: "gray.800", _dark: "white"}
                },
                hangUpBg: {
                    value: {base: "#ff3333", _dark: "#ff2323"}
                },
                hangUpHoverBg: {
                    value: {base: "#ff1a1a", _dark: "#ff4d4d"}
                },
                hangUpActiveBg: {
                    value: {base: "#cc0000", _dark: "#ff8080"}
                },
                greenBackground: {
                    value: {base: "#c4f0cd", _dark: "#5d9769"}
                    // _dark: "#2d833f"
                },
                greenForeground: {
                    value: {base: "#5d9769", _dark: "#c4f0cd"}
                    // _dark: "#55ff77"
                },
                yellowBackground: {
                    value: {base: "#ffeb9c", _dark: "c79d43"}
                },
                yellowForeground: {
                    value: {base: "#c79d43", _dark: "#ffeb9c"}
                },
                redBackground: {
                    value: {base: "#ffc7ce", _dark: "#b45349"}
                },
                redForeground: {
                    value: {base: "#b45349", _dark: "#ffc7ce"}
                },
            }
        },

        layerStyles: {
            secondaryBackground: {
                bg: {value: 'backgroundGrey'}
            }
        },

    }
}
)