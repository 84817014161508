import { Input, Flex, Image, Box, Heading, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { useAuth } from '../../context/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { InputGroup } from '../../components/ui/input-group';
import { Checkbox } from '../../components/ui/checkbox';
import { Button } from '../../components/ui/button';

export const SignIn = () => {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [userName, setUserName] = useState<string>("");
    const [passWord, setPassWord] = useState<string>("");
    const [isWorking, setIsWorking] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [remember, setRemember] = useState<boolean>(true);
    const [name, setName] = useState<string>("");
    const authContext = useAuth();



    useEffect(() => {
        if (authContext.signInState === true && authContext.user?.user_id) {
            navigate("/staff/users");
        }
    }, []);

    const handleSubmit = async () => {
        setIsWorking(true);
        setIsError(false);

        try {
            let response = await authContext.signIn(userName, passWord, remember);
            if (response) {
                let returnUrl = searchParams.get("returnUrl");

                if (returnUrl != null) {
                    navigate(decodeURIComponent(returnUrl));
                } else {
                    navigate("/staff/users");
                }
            } else {
                setIsError(true);
            }
        } catch (error) {
            setIsError(true);
        } finally {
            setIsWorking(false);
        }
    };

    if (authContext.loading) {
        return <h1>Loading ...</h1>
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }
    return (
        <>
            <Flex direction={"column"} width={"100%"} height={"100%"}>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} marginTop={"10%"} >
                    <Image src={viablLogo} width={"10%"} align={"center"} display={"flex"}></Image>
                    <Box marginTop={"1%"} paddingLeft={'10%'} paddingRight={"10%"}>

                        <Heading color='var(--Able-blue)' marginBottom={"4%"}> Welcome to the Able Group Management Tools</Heading>
                        <Flex direction={"column"}>
                            <InputGroup marginBottom={"1%"} borderColor={"primaryTextInverted"} borderWidth={"2px"} borderRadius={"lg"} startElement={<Flex paddingLeft={"5px"} paddingRight={"5px"}>
                                <AiOutlineUser color='primaryTextInverted' /></Flex>} bg={"backgroundGrey"}>
                                <Input onKeyDown={handleKeyDown} placeholder='Username' value={userName} onChange={(e) => { setUserName(e.target.value) }} marginRight={"-20px"} />
                            </InputGroup>
                            <InputGroup marginBottom={"2%"} borderColor={"primaryTextInverted"} borderWidth={"2px"} borderRadius={"lg"} startElement={<Flex paddingLeft={"5px"} paddingRight={"5px"}><BiLockOpenAlt color={"textPrimaryInverted"} /></Flex>} bg={"backgroundGrey"}>
                                <Input onKeyDown={handleKeyDown} placeholder='Password' value={passWord} onChange={(e) => { setPassWord(e.target.value) }} type='password' marginRight={"-20px"} />

                            </InputGroup>

                            <Flex direction={"row"} justifyContent={"center"}>
                                {/* @ts-ignore */}
                                <Checkbox checked={remember} onChange={e => setRemember(e.target.checked)}>Stay logged in?</Checkbox>

                            </Flex>
                            <Flex marginLeft={"-5px"} w={"100%"}>
                            <Button onClick={handleSubmit} loading={isWorking} background='var(--Able-blue)' color={"white"} w={"100%"} marginTop={"2%"} loadingText="Submitting">Log In</Button>
                            </Flex>
                        </Flex>
                        {isError &&
                            <Alert.Root status='error' width={"100%"} justifyContent={"center"} marginTop={"1%"}>
                                <Alert.Indicator />
                                <AlertTitle> Error Signing In</AlertTitle>
                                <AlertDescription>Incorrect Email Address / Password combination</AlertDescription>
                            </Alert.Root>
                        }
                    </Box>

                </Flex>


            </Flex>
            <Flex direction={"column"} position={"absolute"} left={0} bottom={-550} right={0} display={"flex"} marginTop={"auto"}>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} marginTop={"8%"} backgroundColor={"AbleYellow"} >
                    <Box width={"100%"} color={"AbleYellow"} backgroundColor={"AbleBlue"} boxSize={"2"}>
                        TEST
                    </Box>
                </Flex>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"} backgroundColor={"AbleBlue"}>
                    <Box width={"100%"} color={"AbleBlue"} backgroundColor={"AbleBlue"}>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>
                        .
                        <br></br>

                    </Box>
                </Flex>
            </Flex>
        </>
    )
}