import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Flex,
    Table,
    Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import { useDictionary } from "../../scripts/useDictionary";
import { FaArrowRight } from "react-icons/fa";
import { TfiControlPlay } from "react-icons/tfi";
import { AiFillDelete } from "react-icons/ai"
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { BiChevronRight } from "react-icons/bi"
import { BiChevronsRight } from "react-icons/bi";
import { BsFillCheckSquareFill } from "react-icons/bs"
import SkillsView from "../skills/skillsView";
import EditRoleMobile from "./editRoleMobile";
import CloneRole from "./cloneRole";
import EditOutbound from "./editOutbound";
import { useColorMode } from "../ui/color-mode";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";



interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}

interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}



// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],
// export const dummyRoles: UserRole[] = [{
//     'id': 'dummy_id',
//     'name': 'dummy_name',
//     'worker_id': 'dummy_worker_id',
//     'allow_aux': true,
//     'auto_answer': false,
//     'outbound_rule': 'dummy_outbound_rule',
//     'outbound_number_id': 'dummy_outbound_number_id',
//     'details': [{
//         'id': 'dummy_details_id',
//         'level': {
//             'id': 'dummy_level_id',
//             'key': 'dummy_level_key',
//             'name': 'dummy_level_name',
//             'order': 100,
//             'skill_id': 'dummy_level_skill_id',
//         },
//         'level_id': 'dummy_level_id_top_level',
//         'role_id': 'dummy_role_id',
//         'skill': {
//             'id': 'dummy_skill_id',
//             'key': 'dummy_skill_key',
//             'name': 'dummy_kill_name',
//             'levels': [{
//                 'id': 'dummy_skill_level1',
//                 'key': 'dummy_level_key',
//                 'name': 'dummy_level_name',
//                 'order': 100,
//                 'skill_id': 'dummy_level_skill_id',
//             }],
//         },
//         'skill_id': 'dummy_skill_id',
//         'worker_id': 'dummy_worker_id'
//     }],
// }]
interface RoleProps {
    userId: string,
    role: UserRole,
    handleDelete: Function,
    handleSetActive: Function,
    activeRoleId?: string,
    second_cb: Function,
    cb: Function,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}

export default function RoleRow(props: RoleProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const { colorMode, toggleColorMode } = useColorMode();

    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [activeRole, setActiveRole] = useState<string>();
    const [activeWorker, setActiveWorker] = useState();
    const [workerExists, setWorkerExists] = useState(false);
    const [activeWorkerId, setActiveWorkerId] = useState<string>('');
    const [role, setRole] = useState<UserRole>(props.role);

    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();

    const webHelpers = useWebHelper();

    function handleClone(role_name: string) {
        let newRole: UserRoleUpdate = {
            id: role.id,
            name: role_name,
            worker_id: role.worker_id,
            allow_aux: role.allow_aux,
            auto_answer: role.auto_answer,
            skills: [],
            outbound_rule: role.outbound_rule,
            outbound_number_id: role.outbound_number_id
        };

        role.details.forEach(s => {
            newRole.skills.push({ skill_id: s.skill_id, level_id: s.level_id })
        });
        webHelpers.PutAsync('/api/worker/role', 'zeus-api', newRole).then((data: any) => {
            if (data.status === undefined) {
                props.cb();
                toaster.create({

                    title: `New role successfully created`,
                    type: "success",

                })

            }
            else {
                toaster.create({

                    title: `Unable to create new role`,
                    type: "error",

                })
            }
        })
    }
    function handleAuxChange(aux_value: boolean) {
        let newRole = role;
        newRole.allow_aux = !aux_value;
        setRole(newRole);

        updateRole();
    }
    function handleAutoAnswerChange(auto_answer: boolean) {
        let newRole = role;
        newRole.auto_answer = !auto_answer;
        setRole(newRole)
        updateRole();
    }

    function submitAddedSkills(additionalSkills: { [skill_id: string]: ISkillToAdd }) {
        Object.values(additionalSkills).forEach(skill => {
            onUpdateSkill(skill.skill_id, skill);
        });
        updateRole();
    }

    function removeSkill(id: string) {
        onClearSkill(id)
        updateRole();
    }
    function handleSetActive(id: string) {
        webHelpers.PostAsync('/api/worker/role/' + id + '/set', 'zeus-api', {}).then((data: any) => {
            if (data.status === undefined) {
                props.second_cb();
            }
        }).catch((error) => {
            console.log("error occurred ", error)
        }
        )
    }
    function updateRole() {
        let r: UserRoleUpdate = {
            ...role,
            skills: Object.values(skills)
        };
        webHelpers.PostAsync('/api/worker/role', 'zeus-api', r).then((data: any) => {
            setRole(data);
        });
    }

    useEffect(() => {
        onClearSkills();

        role.details.forEach(detail => {
            onUpdateSkill(detail.skill_id, { skill_id: detail.skill_id, level_id: detail.level_id });
        });
    }, [role]);

    return (
        <Table.Row key={role.id} bgColor={props.activeRoleId === role.id ? "AbleBlue" : "backgroundDarkGrey"} >
            <Toaster />
            <Table.ColumnHeader style={{ width: 160 }} scope="row" align="left" color={props.activeRoleId === role.id ? "backgroundDarkGrey" : "AbleBlue"}>
                <Flex direction={"row"} className="icon-name-container">
                    {props.activeRoleId !== role.id &&
                        (
                            <td color="red">
                                <Button aria-label="delete" size="xs" onClick={() => handleSetActive(role.id)} backgroundColor={"backgroundDarkGrey"} overrideBgColor={true}>
                                    <Text color={colorMode === "light" ? "AbleBlue" : "white"}>
                                        <BiChevronRight color="AbleYellow" onClick={() => handleSetActive(role.id)} fontSize={"20px"} />
                                    </Text>
                                </Button>
                            </td>
                        )
                    }
                    <Text marginLeft={props.activeRoleId === role.id ? "20px" : "0px"} marginTop={props.activeRoleId === role.id ? "0px" : "10px"} color={props.activeRoleId === role.id ? "white" : colorMode === 'light' ? "AbleBlue" : "white"}>{role.name}</Text>

                </Flex>

            </Table.ColumnHeader>

            <Table.ColumnHeader style={{ width: 160 }} align="left" color={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "none", lg: "revert" }}>
                {skills !== undefined &&
                    <>

                        {props.activeRoleId === role.id ?
                            <SkillsView role={role} submitAddedSkills={submitAddedSkills} removeSkill={removeSkill} userId={props.userId} refreshRoles={props.cb} isSelected={true} />
                            :
                            <SkillsView role={role} submitAddedSkills={submitAddedSkills} removeSkill={removeSkill} userId={props.userId} refreshRoles={props.cb} isSelected={false} />
                        }
                    </>
                }
            </Table.ColumnHeader>
            <Table.ColumnHeader style={{ width: 160 }} align="left" color={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "none", lg: "revert" }}>

                <>

                    {props.activeRoleId === role.id ?
                        <EditOutbound role={role} isSelected={true} userId={props.userId} />
                        :
                        <EditOutbound role={role} isSelected={false} userId={props.userId} />
                    }
                </>
            </Table.ColumnHeader>

            <Table.ColumnHeader style={{ width: 80 }} align="left" display={{ base: "none", lg: "revert" }}>
                <Switch
                    checked={role.auto_answer}
                    onChange={() => handleAutoAnswerChange(role.auto_answer)}
                    borderWidth={"2px"}
                    borderColor={"backgroundDarkGrey"}
                    borderRadius={"2xl"}
                />
            </Table.ColumnHeader>

            <Table.ColumnHeader style={{ width: 80 }} align="left" display={{ base: "none", lg: "revert" }}>
                <Switch
                    borderWidth={"2px"}
                    borderColor={"backgroundDarkGrey"}
                    borderRadius={"2xl"}
                    checked={role.allow_aux}
                    onChange={() => handleAuxChange(role.allow_aux)}
                />
            </Table.ColumnHeader>
            <Table.ColumnHeader style={{ width: 10 }} align="right" color={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "none", lg: "revert" }}>
                {/* <CloneRoleDialog cloneRoleFunction={handleClone} /> */}
                {props.activeRoleId === role.id ?
                    <CloneRole cloneRoleFunction={handleClone} isSelected={true} />
                    :
                    <CloneRole cloneRoleFunction={handleClone} isSelected={false} />
                }
            </Table.ColumnHeader>
            <Table.ColumnHeader style={{ width: 5 }} align="right" color={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "none", lg: "revert" }}>
                <Button aria-label="delete" size="lg" onClick={() => props.handleDelete(role.id)} bg={props.activeRoleId !== role.id ? "backgroundDarkGrey" : "AbleBlue"} overrideBgColor={true}>
                    <Text color={props.activeRoleId == role.id ? "white" : colorMode === "light" ? "AbleBlue" : "white"} >
                        <AiFillDelete />
                    </Text>
                </Button>
            </Table.ColumnHeader>
            <Table.ColumnHeader style={{ width: 5 }} align="right" color={props.activeRoleId === role.id ? "white" : "AbleBlue"} display={{ base: "revert", lg: "none" }}>
                <EditRoleMobile {...props} />
            </Table.ColumnHeader>


        </Table.Row>
    )
}
