import { createListCollection, SelectValueText, useDisclosure } from "@chakra-ui/react";
import { Table, Spinner, TableCaption, Select, Flex, Image, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreateGroup from "../../components/groups/createGroup";
import EditGroupModal from "../../components/groups/editGroup";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import TableControlsPermissions from "../../components/table/tableControlsPermissions";
import Refresh from "../../components/refresh";
import UpdateLateInvoiceDate from "./update";
import CreateLateInvoiceDate from "./create";
import GetUserConfirm from "../../components/confirm";
import { useColorMode } from "../../components/ui/color-mode";
import { Toaster, toaster } from "../../components/ui/toaster";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger } from "../../components/ui/select";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}

interface IGroups {
    id: string,
    is_security: boolean,
    name: string,
}
export interface ILateInvoiceDate {
    id: string,
    name: string,
    end_date: string,
    target_date: string,
    start_date: string,
    class_id: string
    cb: Function
}
export const LateInvoiceDatePage = () => {
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<ILateInvoiceDate[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(30);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [isSyncing, setIsSyncing] = useState<boolean>(false);
    const [nextPage, setNextPage] = useState<IGroups[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(1);
    const { colorMode, toggleColorMode } = useColorMode();

    const { open, onToggle } = useDisclosure()
    const auth = useAuth();
    const CacheContext = useCache();
    const EnvironmentContext = useEnvironment();


    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    let navigate = useNavigate();

    useEffect(() => {

    }, [engineerList]);

    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setLoading(true);
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        if (EnvironmentContext.selectedEnvironment === null || EnvironmentContext.selectedEnvironment === undefined) {

        }
    }, [])
    function goToNextPage(pageToLoad: number) {

        console.log("test")
    }

    function searchLateInvoiceDates() {
        webHelpers.GetAsync("/api/accounting/claims/lateinvoicing/dates", 'sales').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                toaster.create({

                    title: `Couldn't process GET request for Late Invoice Dates, do you have the correct permissions?`,
                    type: "warning",

                });
            }
            else {
                setRows(data);
                setLoading(false);
                console.log(data);
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            setLoading(false);
            toaster.create({

                title: `Couldn't process GET request for Late Invoice Dates, do you have the correct permissions?`,
                type: "warning",

            });

        });
    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchLateInvoiceDates()
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchLateInvoiceDates()
        }
    }, [search, page, rowsPerPage, selectedType, EnvironmentContext.selectedEnvironment]);


    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);


    const refresh = () => {
        setIgnore(true);
        searchLateInvoiceDates();
        //setLoading(true);
        setNextPageNo(page + 1);
    }
    const handleProfileChange = (event: any) => {
        setLoading(true);
        setSelectedType(event.target.value);
    }

    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
        console.log("env change was ", isSuccess)
    }
    useEffect(() => {
        onToggle();
    }, [loading]);

    const handleDelete = (id: string) => {
        webHelpers.DeleteAsync("/api/accounting/claims/lateinvoicing/delete/" + id, "sales", {}).then((res) => {
            console.log("succesfully deleted the Late Invoice Date", res);
        })
    }
    const enviromentCollection = React.useMemo(() => {
        let x = createListCollection({
            items: []
        })
        EnvironmentContext.environments.map((each: IEnvironment) => {
            //@ts-ignore
            x.items.push({ label: each.name, value: each.id })
        })
        return (x)
    }, [EnvironmentContext])
    return (

        // <ScaleFade initialScale={1} in={true}> 

        <>
        <Toaster/>
            <Heading marginTop={"20px"} color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
            >Late Invoice Dates</Heading>

            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"} >
                <TableControlsPermissions handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} handleSearchChange={handleSearchChange} search={search} />
                <SelectRoot size={"sm"} w={"137px"} collection={enviromentCollection} onValueChange={(value) => handleEnvChange(value)} value={[EnvironmentContext.selectedEnvironment.id]} defaultValue={["live"]} zIndex={100}
                    paddingLeft={"5px"} marginTop={"-3px"}>

                    <SelectTrigger w={"130px"}>
                        <SelectValueText placeholder="Select Env"></SelectValueText>
                    </SelectTrigger>
                    <SelectContent w={"100px"}><>
                        {/* @ts-ignore */}
                        {enviromentCollection.items.map((each: any) =>
                            <SelectItem item={each} key={each.id}>{each.label}</SelectItem>
                        )}
                    </>

                    </SelectContent>
                </SelectRoot>
                <Refresh cb={() => refresh()} />
                <CreateLateInvoiceDate cb={searchLateInvoiceDates} />
            </Flex>
            {!loading ?
                <Flex direction={'row'} h={650 * ((rows.length / rowsPerPage) + 1) + 10 + "px"} justifyContent={"center"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                    {/* <ScaleFade initialScale={1} in={!isOpen} reverse={loading}> */}
                    <>
                        <Table.Root variant={"line"} width={"90%"} display={rows.length > 0 ? "flex" : "none"} justifyContent={"center"}>

                            <InfiniteScroll
                                dataLength={rows.length}
                                next={() => {
                                    if (nextPageNo < page) {
                                        setNextPageNo(page);
                                        goToNextPage(page + 1)
                                    }
                                    else {
                                        goToNextPage(nextPageNo)
                                        setNextPageNo(nextPageNo + 1);
                                    }


                                }}
                                hasMore={nextPageNo < maxPages - 2}
                                loader={
                                    <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                        <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                        {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                    </Flex>
                                }
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                    </p>
                                }
                                scrollThreshold={1}
                            >
                                <TableCaption>
                                    <Text>
                                        {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                    </Text>
                                </TableCaption>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.ColumnHeader>Name</Table.ColumnHeader>
                                        <Table.ColumnHeader>End Date</Table.ColumnHeader>
                                        <Table.ColumnHeader>Target Date</Table.ColumnHeader>
                                        <Table.ColumnHeader>ClassId</Table.ColumnHeader>
                                        <Table.ColumnHeader>Start Date</Table.ColumnHeader>
                                        <Table.ColumnHeader></Table.ColumnHeader>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {rows.map((each: ILateInvoiceDate) =>
                                        <Table.Row key={each.id}>
                                            <Table.Cell paddingRight={{ base: "10px", lg: "30px" }}>{each.name}</Table.Cell>
                                            <Table.Cell paddingRight={{ base: "10px", lg: "30px" }}>
                                                <Text>{each.end_date.split("T")[0].split("-")[2] + "/"}{each.end_date.split("T")[0].split("-")[1] + "/"}{each.end_date.split("T")[0].split("-")[0]}</Text>
                                            </Table.Cell>
                                            <Table.Cell paddingRight={{ base: "10px", lg: "30px" }}>{each.target_date.split("T")[0].split("-")[2] + "/"}{each.target_date.split("T")[0].split("-")[1] + "/"}{each.target_date.split("T")[0].split("-")[0]}</Table.Cell>
                                            <Table.Cell paddingRight={{ base: "10px", lg: "30px" }}>{each.class_id}</Table.Cell>
                                            <Table.Cell paddingRight={{ base: "10px", lg: "30px" }}>{each.start_date === null ? "N/A" : each.start_date.split("T")[0].split("-")[2] + "/" + each.start_date.split("T")[0].split("-")[1] + "/" + each.start_date.split("T")[0].split("-")[0]}</Table.Cell>
                                            <Table.Cell><UpdateLateInvoiceDate {...each} cb={searchLateInvoiceDates} /></Table.Cell>
                                            <Table.Cell><GetUserConfirm confirmFunction={() => handleDelete(each.id)} declineFunction={() => console.log("test")} buttonText="Delete" buttonWarning="Are you sure you want to remove this entry? (THIS CANNOT BE UNDONE)" /></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </InfiniteScroll>


                        </Table.Root>
                    </>
                    {/* </ScaleFade> */}
                </Flex>

                :
                <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"backgroundDarkGrey"}>
                    {colorMode === 'light' ?
                        <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                        :
                        <Spinner color="AbleYellow" marginTop={"100px"} marginBottom={"100px"} size={"xl"} />
                    }
                </Flex>
                // {/* </ScaleFade> */}

            }

            {/* <ScaleFade initialScale={0.1} in={defaultView}>
                </ScaleFade> */}
        </>
        // </ScaleFade>

    )
}
