import * as React from 'react';
import { useState } from 'react';


import {
    createListCollection,
    Dialog, Select, Text,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";

import { validateLocaleAndSetLanguage } from "typescript";
import { BiAddToQueue } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { Button } from '../ui/button';
import { DialogBody, DialogContent, DialogRoot, DialogTitle, DialogTrigger } from '../ui/dialog';
import { SelectContent, SelectItem, SelectTrigger, SelectValueText } from '../ui/select';



interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface IAssembly {
    assembly_name: string;
    is_default: boolean;
}
const defaultWorkflow: IAssembly[] = [
    {
        assembly_name: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
interface AssemblyModalProps {
    cb: Function,
}
export default function AssembliesModal(props: AssemblyModalProps) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<IAssembly[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Assembly");
    const webHelpers = useWebHelper();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)

    const test = React.useMemo(() => {
        return createListCollection({
            items: [
                rows2.map((each: IAssembly) => {
                    return { label: each.assembly_name, value: each.assembly_name, is_default: each.is_default }
                })
            ]
        })
    }, [rows2])

    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.GetAsync('/api/code/config/assemblies', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                console.log('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                //@ts-ignore
                setRows2(Object.values(data));
                setOpen(true);
                //setMaxPages(data.maximum_pages)
            }
        })

        // webHelpers.get('/api/worker/roles/' + props.id, environment, 'zeus-api', token, (data: any) => {
        //     if (data === undefined || data === null || data.status !== undefined) {
        //         console.log('bad fetch call');

        //         enqueueSnackbar(`${data.status}: Unable to get information for this user`, {'variant': 'error'})
        //         // Some fallback ui needed here.
        //     }
        //     else {
        //         setRoles(data);
        //         setOpen(true);
        //     }
        // });
    };
    function handleClose() {
        setOpen(false);
    }
    const handleProfileChange = (event: any) => {
        setNewNamespace(event.value[0]);
    }
    const handleSubmit = () => {
        props.cb(newNamespace);
        setOpen(false);
    }

    const assemblyCollecion = React.useMemo(() => {
        let x = createListCollection({
            items: [
            ]
        })
        rows2.map((each: IAssembly) => {
            //@ts-ignore
            x.items.push({ label: each.assembly_name, value: each.assembly_name, default: each.is_default })
        })
        console.log(x)
        return x
    }, [rows2])

    return (
        <>

            <DialogRoot

                onOpenChange={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Dialog.Backdrop />
                <DialogTrigger>
                    <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                        className='button-test' onClick={handleOpen}><BiAddToQueue />Add new Assembly</Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>
                        <div className="modal-header">
                            <Text >
                                Add New Assembly
                            </Text>
                            {/* Add an edit department bit here at some point */}


                        </div>

                        <Text as={"i"} fontSize={"sm"}>
                            Select a new assembly from the drop down box below
                        </Text>
                    </DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Text id="demo-simple-select-label">Assembly</Text>
                        <Select.Root
                            collection={assemblyCollecion}
                            id="demo-simple-select"
                            value={[newNamespace]}
                            onValueChange={handleProfileChange}
                            disabled={assemblyCollecion.items.filter((x: any) => x.default === false).length === 0}
                        >
                            <SelectTrigger>
                                <SelectValueText placeholder={assemblyCollecion.items.filter((x: any) => x.default === false).length === 0 ? "No Assemblies to add" : "Select an Assembly"}></SelectValueText>
                            </SelectTrigger>
                            <SelectContent portalled={false}>
                                {assemblyCollecion.items.map((workflow: any) => (
                                    (workflow.default === false &&
                                        <SelectItem item={workflow} key={workflow.value}>{workflow.label}</SelectItem>
                                    )))}
                            </SelectContent>
                        </Select.Root>
                        {/* above probably wont work, but 'tis a test to see how the new selects work  */}
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                            className="modal-button-submit" onClick={handleSubmit} variant="solid" marginTop={"10px"} disabled={newNamespace === "New Assembly"}>Add to Code</Button>
                    
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        </>
    );
}