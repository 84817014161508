import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Input, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { Button } from "../ui/button";
import { DialogBody, DialogCloseTrigger, DialogContent, DialogFooter, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";

interface newOutcomeProps {
    cb: Function,
}

export default function CreateOutcome(props: newOutcomeProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    //form fields
    const [friendlyId, setFriendlyId] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setFriendlyId("");
        setName("");
        setDescription("");
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        if (friendlyId !== undefined && name !== undefined && description !== undefined) {
            const payload = {
                "friendly_id": friendlyId.replaceAll(" ", "").toLowerCase(),
                "name": name,
                "description": description,
            }
            console.log(payload)
            webHelpers.PutAsync('/api/config/outcome', 'helios-api', payload).then((data: any) => {

            })
            props.cb();
            handleClose();
        }
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !(friendlyId === "" || name === "" || description === "")) {
            handleSubmit();
        }
    }
    const handleFriendlyIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFriendlyId(e.currentTarget.value)
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <DialogRoot
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "lg", lg: "md" }}
            >
                <Dialog.Backdrop />
                <DialogTrigger marginTop={"10px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}

                        onClick={handleClickOpen}>
                        <BiPlus /> Create Outcome
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New Outcome"}</DialogTitle>
                    <DialogBody padding={"10px"} >

                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>

                            <Input onKeyDown={handleKeyDown} bg={"backgroundDarkGrey"} placeholder="Friendly Id" type="text" autoComplete="new-password" value={friendlyId} onChange={handleFriendlyIdChange} required></Input>
                            <Input onKeyDown={handleKeyDown} bg={"backgroundDarkGrey"} marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Name" value={name} onChange={handleNameChange} required></Input>
                            <Input onKeyDown={handleKeyDown} bg={"backgroundDarkGrey"} marginTop={"10px"} type="text" autoComplete="new-password" placeholder="Description" value={description} onChange={handleDescriptionChange} required></Input>
                        </Fieldset.Root>


                    </DialogBody>
                    <DialogFooter>

                        <DialogCloseTrigger>

                        </DialogCloseTrigger>
                        <Button onClick={() => handleSubmit()}
                            disabled={friendlyId === "" || name === "" || description === ""}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Create Outcome</Button>
                        <DialogTrigger>
                            <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
