import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import AddSkillRow from "../skills/addSkillRow";

import { useDictionary } from "../../scripts/useDictionary";
import { StringMappingType } from "typescript";
import { objectTypeSpreadProperty } from "@babel/types";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTrigger } from "../../components/ui/dialog";


interface NewRoleProps {
    userId: string,
    cb: Function,
}
interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
    isSelected: boolean,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}
export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
export interface SkillTest {
    skill_id: string,
    level_id: string,
    level_name: string,
    name: string,
}
export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: SkillTest[],
}
export interface OutboundNumbers {
    id: string,
    name: string,
    phoneNumber: string,
}
const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: SkillTest }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
interface SelectedSkillsForRoleProps {
    skills: SkillTest[]
}

export default function SelectedSkillsForRole(props: SelectedSkillsForRoleProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [text, setText] = useState<string>();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [pageSkills, setPageSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPages, setMaxPages] = useState<number>(1);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [outboundNumbers, setOutboundNumbers] = useState<OutboundNumbers[]>([]);
    const [selectedOutbound, setSelectedOutbound] = useState<string>();
    const [pageSkills2, setPageSkills2] = useState<SkillTest[]>(props.skills);

    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();

    function addSkills(additionalSkills: SkillTest[]) {
        let test_object = {
            skill_id: "",
            level_id: "",
            name: "",
            level_name: "",
        }
        // @ts-ignore
        test_object.skill_id = Object.values(additionalSkills)[0];
        // @ts-ignore
        test_object.level_id = Object.values(additionalSkills)[1];
        // @ts-ignore
        test_object.name = Object.values(additionalSkills)[2];
        // @ts-ignore
        test_object.level_name = Object.values(additionalSkills)[3];
        skills[test_object.skill_id] = test_object;

    }


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        populateSkills();
        getOutboundNumbers();
        console.log(props.skills)
        console.log(pageSkills2[0])
    }

    const handleClose = () => {
        onClose();
    }
    const handleChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSelectedOutboundChange = (event: any) => {
        setSelectedOutbound(event.target.value);
    }

    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        setText(e.currentTarget.value);
    }
    const getOutboundNumbers = () => {
        webHelpers.GetAsync('/api/hosted/outboundnumbers', 'zeus-api').then((data: any) => {
            if (data != undefined && data != null) {
                setOutboundNumbers(data)
            }
            else {
                console.log(`${data.status}: unable to fetch outbound numbers`)
            }
        })
    }
    const populateSkills = () => {
        webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
            setAllSkills(data)
            if (data.length % rowsPerPage === 0) {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)));
            }
            else {
                setMaxPages(Number((data.length / rowsPerPage).toPrecision(1)) + 1);
            }
            setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        });
    }
    useEffect(() => {
        if (open && allSkills === undefined) {
            webHelpers.GetAsync('/api/skills/definitions', 'zeus-api').then((data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        console.log("skills : ", skills)

    }, [page])

    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        setPage(0);
        populateSkills();
    }, [rowsPerPage])
    useEffect(() => {
        //this will reset the table whenever the rowsPerPage is changed
        console.log(pageSkills2)
    }, [])

    return (
        <>
            <Button bg={"AbleBlue"} color={"white"} _hover={{ color: "AbleYellow" }} marginTop={"5px"} size={"sm"}
                onClick={handleClickOpen}>
                Selected Skills [{props.skills.length}]
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"xl"}
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTrigger>{"Selected Skills"}</DialogTrigger>
                    <DialogBody>
                        <>
                            {props.skills.map((skill) => 
                                <Text> - {skill.level_name}</Text>
                            )}
                        </>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={handleClose} colorScheme="red">Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
