import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Table,
    Select, Input, Flex, Text, Accordion, AccordionItem, useDisclosure, Tabs, Spinner,
    AccordionItemTrigger,
    createListCollection
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import RoleManagement from "../role/rolesComponent";
import PermissionManagement from "../permissions/permissionComponent";
import GroupManagement from "../groups/groupsComponent";
import { TSExportAssignment } from "@babel/types";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import GetUserConfirm from "../confirm";
import TableControlsPermissions from "../table/tableControlsPermissions";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogActionTrigger, DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle } from "../ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";
import { SearchBox } from "../searchBox";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IUserPermissions {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
interface EditGroupProps {
    id: string,
    cb: Function,
    name: string,
}
interface UserGroups {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
export default function EditGroupModal(props: EditGroupProps) {
    const { open, onOpen, onClose, onToggle } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    // const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>();
    const [rows, setRows] = useState<IPermission[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userGroups, setUserGroups] = useState<UserGroups[]>([]);
    const [userGroupsFiltered, setUserGroupsFiltered] = useState<UserGroups[]>([]);
    const [loading, setLoading] = useState<boolean>(true);


    const webHelpers = useWebHelper();

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    const handleClickOpen = () => {
        setLoading(true);
        onOpen();
        setPage(0);
        searchPermissions("");
        getGroupPermissions();
    };

    const onHandleClose = () => {
        onClose();
        props.cb();
    }

    function searchPermissions(search: string) {
        //pulls down all permissions
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + "/" + search, 'auth').then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                // @ts-ignore 
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
                setLoading(false);

            }
            else {
                console.log(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            setLoading(false);
        });
    }
    const getGroupPermissions = () => {
        webHelpers.GetAsync("/api/management/group/" + props.id + "/permissions", "auth").then((data: any) => {
            console.log("permissions for this group = ", data)
            if (data.status === 200 || data.status === undefined) {
                setUserGroups(data)
                setLoading(false);

                setLoading(false);
            }
        })
    }
    useEffect(() => {
        searchPermissions(search);
    }, [page, rowsPerPage]);

    useEffect(() => {
        setUserGroupsFiltered([]);
        // @ts-ignore 
        let test = [];

        userGroups.forEach((each) => {
            if (!(each.revoked)) {
                // setUserPermissionsFiltered(userPermissionsFiltered => [...userPermissionsFiltered, each]);
                test.push(each);
            }
        })
        if (Math.floor(test.length / rowsPerPage) === 0) {
            setMaxPagesFirstTab(Math.ceil(test.length / rowsPerPage));
            // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
        }
        else {
            setMaxPagesFirstTab(Math.ceil(test.length / rowsPerPage));
            // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
        }
        // @ts-ignore 
        setUserGroupsFiltered(test.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
        //setLoading(false);

    }, [userGroups, page]);

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    function handleDelete(permission_id: string) {
        setLoading(true);
        webHelpers.PostAsync('/api/management/permission/revoke/' + permission_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully revoked', { 'variant': 'success' });
                setPage(0);
                searchPermissions(search);
                getGroupPermissions();
            }
            else {
                toaster.create({

                    title: `Unable to revoke this permission [${data.status}]`,
                    type: "error",

                })
            }
        })
        props.cb();

        return (

            toaster.create({

                title: `permission ${permission_id} has been removed from user ${props.id}`,
                type: "success",

            })
        )
    }
    function handleAssign(name: string) {
        const data = {
            'permission': name
        };
        webHelpers.PostAsync('/api/management/permission/assign/' + props.id, 'auth', data).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
                searchPermissions(search);
            }
            else {
                console.log(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' });
            }
        })

        props.cb();
        getGroupPermissions();
        return (
            toaster.create({

                title: `permission ${name} has been added to user ${props.id}`,
                type: "success",

            })
        )
    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchPermissions(event.target.value);
        setPage(0);
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true)
        console.log(event)
        setRowsPerPage(event.value[0]);
        setPage(0);

    };

    return (
        <>
            <Toaster />
            <Button onClick={handleClickOpen} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"}>
                <MdOutlineSettingsApplications />Edit
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"lg"}

            >
                <DialogBackdrop />
                <DialogContent>
                    <DialogTitle>{"Edit " + props.name}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        {/* roles / permissions / groups */}
                        <>
                            {rows !== null && rows !== undefined ?

                                <div>
                                    <Tabs.Root marginTop={"10px"} position="relative" defaultValue={"Active Permissions"} variant={"subtle"} size={"lg"} onValueChange={() => {
                                        // populateSkills();
                                        //onToggle();
                                        setPage(0);
                                    }
                                    }>
                                        <Tabs.List>
                                            <Tabs.Trigger padding={"10px"} borderRightRadius={0} value={"Active Permissions"} bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Active Permissions</Tabs.Trigger>
                                            <Tabs.Trigger padding={"10px"} borderLeftRadius={0} value={"Add Permissions"} bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Add Permissions</Tabs.Trigger>
                                        </Tabs.List>
                                        <Tabs.Content value="Active Permissions">
                                            {/* <ScaleFade initialScale={1} in={!isOpen}> */}
                                            <Flex direction={"row"} justifyContent={"right"}>
                                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                                    <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                                        <SelectTrigger w={"50px"}>
                                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                                        </SelectTrigger>
                                                        <SelectContent display={"absolute"} portalled={false}>
                                                            {rowsCollection.items.map((each) =>
                                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                                            )}
                                                        </SelectContent>
                                                    </SelectRoot>
                                                </Flex>
                                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPageFirstTab} </Text>
                                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                    page !== 0 && setPage(page - 1);
                                                    setLoading(true);
                                                }} disabled={page === 0 || maxPageFirstTab - 1 === 0}><AiFillCaretLeft /></Button>
                                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                    page !== maxPageFirstTab - 1 && setPage(page + 1)
                                                    setLoading(true);
                                                }} disabled={page === maxPageFirstTab - 1 || maxPageFirstTab === 0}><AiFillCaretRight /></Button>
                                            </Flex>
                                            {!loading ?
                                                <Table.Root variant={"line"} w={"100%"}>
                                                    {userGroups.length === 0 ?
                                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                                        :
                                                        <>
                                                            <Table.Header>
                                                                <Table.Row bg={"backgroundDarkGrey"}>
                                                                    {/* this will show all skill that are possible to see */}
                                                                    <Table.ColumnHeader textAlign={"left"}>Permission Name</Table.ColumnHeader>
                                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>

                                                                {userGroupsFiltered.map((permission) =>
                                                                    <Table.Row display={{ base: "none", lg: "revert" }} bg={"rgba(0,0,0,0)"}>
                                                                        <Table.Cell>{permission.name}</Table.Cell>
                                                                        <Table.Cell></Table.Cell>
                                                                        <Table.Cell w={"150px"} >
                                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                                <GetUserConfirm
                                                                                    confirmFunction={() => handleDelete(permission.id)}
                                                                                    declineFunction={() => console.log("")}
                                                                                    buttonText="Remove"
                                                                                    buttonWarning={"Are you sure you want to remove this permission from " + props.id} />
                                                                                {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                                            </Flex>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )}

                                                            </Table.Body>
                                                            <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                                {userGroupsFiltered.map(permission => (
                                                                    <Accordion.Item value={permission.name}>
                                                                        <Accordion.ItemTrigger>
                                                                            <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                <Table.Cell display={{ base: "revert", lg: "none" }}>
                                                                                    {permission.name}
                                                                                </Table.Cell>
                                                                                {/* <AccordionIcon /> */}
                                                                            </Flex>
                                                                        </Accordion.ItemTrigger>
                                                                        <Accordion.ItemContent>
                                                                            <Table.Cell display={{ base: "revert", lg: "none" }} marginLeft={"40px"} >
                                                                                <GetUserConfirm
                                                                                    confirmFunction={() => handleDelete(permission.id)}
                                                                                    declineFunction={() => console.log("")}
                                                                                    buttonText="Remove"
                                                                                    buttonWarning={"Are you sure you want to remove this permission from " + props.id} />
                                                                            </Table.Cell>
                                                                        </Accordion.ItemContent>
                                                                    </Accordion.Item>
                                                                ))}
                                                            </Accordion.Root>
                                                        </>
                                                    }
                                                </Table.Root>
                                                :
                                                <Flex width={"100%"} justifyContent={"center"}>
                                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                                </Flex>
                                            }
                                            {/* </ScaleFade> */}
                                        </Tabs.Content>
                                        <Tabs.Content value={"Add Permissions"}>
                                            {/* <ScaleFade initialScale={1} in={isOpen}> */}
                                            <Flex direction={"row"} justifyContent={"right"}>
                                                <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                                                <SearchBox search={search} handleSearchChange={handleSearchChange} />
                                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                                    <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                                        <SelectTrigger w={"50px"}>
                                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                                        </SelectTrigger>
                                                        <SelectContent display={"absolute"} portalled={false}>
                                                            {rowsCollection.items.map((each) =>
                                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                                            )}
                                                        </SelectContent>
                                                    </SelectRoot>
                                                </Flex>
                                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages === 0 ? 1 : maxPages} </Text>
                                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                    page !== 0 && setPage(page - 1);
                                                    setLoading(true);
                                                }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                                    page !== maxPages - 1 && setPage(page + 1);
                                                    setLoading(true);
                                                }} disabled={page === maxPages - 1 || maxPages === 0}><AiFillCaretRight /></Button>
                                            </Flex>
                                            {/* need to have a section or method of showing already selected skills */}
                                            {!loading ?
                                                <Table.Root variant={"line"} size={"sm"} className="table-tiny">
                                                    {rows.length === 0 ?
                                                        <Text textAlign={"center"} whiteSpace={"nowrap"}>No Permissions Found! </Text>
                                                        :
                                                        <>
                                                            <Table.Header>
                                                                <Table.Row backgroundColor={"backgroundDarkGrey"}>
                                                                    {/* this will show all skill that are possible to see */}
                                                                    <Table.ColumnHeader textAlign={"left"}>Permission Name</Table.ColumnHeader>
                                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Description</Table.ColumnHeader>
                                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-19px"}>
                                                                    {rows.map(permission => (
                                                                        <Accordion.Item value={permission.id}>
                                                                            <Accordion.ItemTrigger>
                                                                                <Flex justifyContent={"space-between"} w={"100%"}>
                                                                                    <Table.Cell display={{ base: "revert", lg: "none" }}>
                                                                                        {permission.id}
                                                                                        <br />
                                                                                        <Text as={"i"} fontSize={"2xs"}>{permission.name}</Text> </Table.Cell>
                                                                                    {/* <AccordionIcon /> */}
                                                                                </Flex>
                                                                            </Accordion.ItemTrigger>
                                                                            <Accordion.ItemContent>
                                                                                <Table.Cell display={{ base: "revert", lg: "none" }} >
                                                                                    <Button
                                                                                        onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                        bg={"AbleBlue"}
                                                                                        color={"white"}
                                                                                        marginLeft={"30px"}
                                                                                        _hover={{ color: "AbleYellow" }}
                                                                                        loading={loading}>Assign Permission</Button></Table.Cell>
                                                                            </Accordion.ItemContent>
                                                                        </Accordion.Item>
                                                                    ))}
                                                                </Accordion.Root>
                                                                {rows.map((permission) =>
                                                                    <Table.Row display={{ base: "none", lg: "revert" }} background={"backgroundGrey"}>
                                                                        <Table.Cell width={"440px"}>
                                                                            <Text>
                                                                                {permission.id}
                                                                            </Text></Table.Cell>
                                                                        <Table.Cell >
                                                                            <Text marginLeft={"0px"} flexWrap={"wrap"} width={"fit"}>
                                                                                {permission.name}
                                                                            </Text>
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                                <Button
                                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                                    bg={"AbleBlue"}
                                                                                    color={"white"}
                                                                                    _hover={{ color: "AbleYellow" }}
                                                                                    loading={loading}>+ Assign</Button>
                                                                            </Flex>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )}
                                                            </Table.Body>
                                                        </>
                                                    }
                                                </Table.Root>
                                                :
                                                <Flex width={"100%"} justifyContent={"center"}>
                                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                                </Flex>
                                            }
                                            {/* </ScaleFade> */}
                                        </Tabs.Content>
                                    </Tabs.Root>
                                </div>

                                :
                                <Flex>
                                    <Text>No Permissions Found !</Text>
                                </Flex>
                            }
                        </>
                    </DialogBody>
                    <DialogFooter>
                        <DialogActionTrigger>
                            <Button onClick={onHandleClose}>Finish</Button>
                            {/* <Button onClick={onHandleClose}>Finish</Button> */}
                        </DialogActionTrigger>

                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
