import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Flex, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import StepsComponent from "./stepsComponent";
import { BsCodeSlash } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import AddEvent from "./addEvent";
import { CodeEditor } from "../code/codeEditor";
import GetUserConfirm from "../confirm";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
}
interface Event {
    code_id: string,
    event_id: string,
    id: string,
}
export default function ManageEvents(props: StepsProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);
    const [timeprofiles, setTimeprofiles] = useState<Timeprofiles[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const CacheContext = useCache();

    const webHelpers = useWebHelper();

    const handleClickOpen = () => {
        onOpen();
        getEvents();
    }
    const handleClose = () => {
        onClose();
    }

    function getEvents() {
        webHelpers.GetAsync('/api/workflow/step/' + props.step.id + '/events', 'helios-api').then((data: any) => {
            if (data === undefined || data.response === 400) {
                toaster.create({
                    
                    title: `Unable to fetch events for this Step`,
                    type: "error",
                    
                }); 
            }
            else {
                setEvents(data);
            }
        })
    }
    const removeEvent = (event_id: string) => {
        webHelpers.DeleteAsync("/api/workflow/step/" + props.step.id + "/event?event=" + event_id, "helios-api", {}).then((data: any) => {
            if (data === undefined || data.response === 400) {
                toaster.create({
                    
                    title: `Unable to remove event`,
                    type: "error",
                    
                }); 
            }
            else {
                setEvents(data);
            }
        }).catch((error) => {
            getEvents();
        })
    }
    return (
        <>
        <Toaster/>
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                onClick={handleClickOpen}
            >
                <MdOutlineSettingsApplications />
                Manage Events
            </Button>
            <DialogRoot
                open={open}
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={"xl"}
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>{"Managing Events for: " + props.step.name}<br />
                        <Text as={"i"} marginBottom={"1px"} fontSize={"sm"}>Listed below are all of the events for this step of the workflow.</Text></DialogTitle>
                    <DialogBody>
                        <AddEvent {...props} refreshEventsFunction={getEvents} />
                        {events.map((event) =>
                            <Flex direction={"row"} justifyContent={"space-between"} marginTop={"20px"}>
                                <Text fontSize={"15px"} marginTop={"15px"}>{event.event_id}</Text>
                                {/* button for now is a placeholder that will open a coding window  */}
                                <Flex justifyContent={"right"}>
                                    <CodeEditor stepFriendlyName={event.event_id} code_id={event.code_id} buttonText={"Edit"} mode="" intital_value={undefined} cb={getEvents}  quick_outcome_code_id={null} />
                                    {/* this button will remove the event from the workflow step */}
                                    <Button  bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginLeft={"10px"}
                                    onClick={() => removeEvent(event.event_id)} marginTop={"5px"}>
                                        <AiFillDelete fontSize={"40px"} />
                                    </Button>
                                </Flex>
                            </Flex>
                        )}
                    </DialogBody>
                    <DialogFooter>
                        <Flex justifyContent={"space-between"}>
                            <Button bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                                onClick={handleClose}>Finish</Button>
                            
                        </Flex>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
