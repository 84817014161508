import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Table, Select, Input, Flex, Text, Accordion, AccordionItem, useDisclosure, Tabs, Spinner,
    createListCollection,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone, AiFillCaretLeft, AiFillCaretRight, AiOutlineMinus } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "../role/roleRow";
import NewRole from "../role/newRole";
import { StringMappingType } from "typescript";
import SkillsView from "../skills/skillsView";
import GetUserConfirm from "../confirm";
import { title } from "process";
import "../permissions/permissionStyles.css";
import TableControlsPermissions from "../table/tableControlsPermissions";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";
import { SearchBox } from "../searchBox";
import { useColorMode } from "../ui/color-mode";



export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IUserPermissions {
    id: string,
    sid: string,
    name: string,
    revoked: boolean,
}
export default function PermissionManagement(props: RoleManagementProps) {
    const CacheContext = useCache();
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [rows, setRows] = useState<IPermission[]>();
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [userPermissions, setUserPermissions] = useState<IUserPermissions[]>([]);
    const [userPermissionsFiltered, setUserPermissionsFiltered] = useState<IUserPermissions[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const { open, onToggle } = useDisclosure();

    const { colorMode, toggleColorMode } = useColorMode();

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })

    const webHelpers = useWebHelper();

    function searchPermissions(search: string) {
        webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/1/' + search, 'auth').then((data: any) => {
            if (data.status === 200 || data.status === undefined) {
                // @ts-ignore 
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
            }
            else {
                toaster.create({

                    title: `No permissions matched search criteria "${search}"`,
                    type: "warning",

                })
            }
        }).catch((error) => {
            setRows([]);
            setMaxPages(0);
            if (maxPages !== 0) {
                toaster.create({

                    title: `Error fetching permissions [${error.status}]`,
                    type: "error",

                })
            }
        });
    }
    useEffect(() => {
        if (search !== "") {
            webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + "/" + search, 'auth',).then((data: any) => {
                if (data.status === 200 || data.status === undefined) {
                    setRows(data.permissions);
                    setMaxPages(data.max_pages);
                }
                else {
                    console.log(`${data.status}: Unable to find permissions under name`, { 'variant': 'error' })
                }
            })
        }
        else {
            webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1), 'auth',).then((data: any) => {
                if (data.status === 200 || data.status === undefined) {
                    setRows(data.permissions);
                    setMaxPages(data.max_pages);
                }
                else {
                    console.log(`${data.status}: Unable to find permissions under name`, { 'variant': 'error' })
                }
            })
        }

        updatePermissions();
    }, [page, rowsPerPage]);

    useEffect(() => {
        setUserPermissionsFiltered([]);
        // @ts-ignore 
        let test = [];
        userPermissions.forEach((each) => {
            if (!each.revoked) {
                // setUserPermissionsFiltered(userPermissionsFiltered => [...userPermissionsFiltered, each]);
                test.push(each);
            }
        })
        // @ts-ignore 
        setUserPermissionsFiltered(test.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))));
        setLoading(false);

    }, [userPermissions]);

    function updatePermissions() {
        webHelpers.GetAsync('/api/management/user/' + props.user_id + '/permissions', 'auth').then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                if (maxPages !== 0) {
                    toaster.create({

                        title: `No permissions found for user "${props.user_name}"`,
                        type: "warning",

                    })
                }
            }
            else {
                if (Math.floor(data.length / rowsPerPage) === 0) {
                    setMaxPagesFirstTab(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                else {
                    setMaxPagesFirstTab(Math.ceil(data.length / rowsPerPage));
                    // setMaxPagesFirstTab(Number((data.length / rowsPerPage).toPrecision(1)));
                }
                // setUserPermissions(data.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
                setUserPermissions(data);
            }

        })
    }

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    function handleDelete(permission_id: string) {
        webHelpers.PostAsync('/api/management/permission/revoke/' + permission_id, 'auth', {}).then((data: any) => {
            if (data.status === 200) {
                updatePermissions();
                toaster.create({

                    title: `Permission ${permission_id} has been removed from user ${props.user_name}`,
                    type: "success",

                })
            }
            else {
                toaster.create({

                    title: `There was an error trying to revoke this permissions [${data.status}]`,
                    type: "error",

                })

            }
        })
    }


    function handleAssign(name: string) {
        const data = {
            permission: name
        };
        webHelpers.PostAsync('/api/management/permission/assign/' + props.user_id, 'auth', data).then((data: any) => {
            if (data.status === 200) {
                console.log('Permission successfully assigned', { 'variant': 'success' });
                setLoading(false);
                updatePermissions();
            }
            else {
                toaster.create({

                    title: `There was an error trying to assign this permissions [${data.status}]`,
                    type: "error",

                })
            }
        })
        setLoading(false);
        // props.cb();
        return (
            toaster.create({

                title: `permission ${name} has been added to user ${props.user_name}`,
                type: "success",

            })
        )

    };

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        searchPermissions(event.target.value);
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true);
        setRowsPerPage(event.value[0]);
        setPage(0);
    };
    return (
        <>
            <Toaster />
            {rows !== null && rows !== undefined ?

                <div>
                    <Tabs.Root position="relative" defaultValue={"Active Permissions"} size={"lg"} variant={"subtle"} onValueChange={() => {
                        // populateSkills();
                        onToggle();
                        setPage(0);

                    }
                    }>
                        <Tabs.List>
                            <Tabs.Trigger padding={"10px"} borderRightRadius={0} value={"Active Permissions"} bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Active Permissions</Tabs.Trigger>

                            <Tabs.Trigger padding={"10px"} borderLeftRadius={0} value={"Add Permissions"} bg={"AbleBlue"} color={"white"} _selected={{ color: "AbleYellow" }} as={"b"} fontSize={{ base: "sm", lg: "lg" }}>Add Permissions</Tabs.Trigger>

                        </Tabs.List>

                        <Tabs.Content value="Active Permissions">
                            {/* <ScaleFade initialScale={1} in={!isOpen}> */}
                            <Flex direction={"row"} justifyContent={"right"}>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                    <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                        <SelectTrigger w={"50px"}>
                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                        </SelectTrigger>
                                        <SelectContent display={"absolute"} portalled={false}>
                                            {rowsCollection.items.map((each) =>
                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </SelectRoot>
                                </Flex>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPageFirstTab} </Text>
                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} disabled={page === 0 || maxPageFirstTab - 1 === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPageFirstTab - 1 && setPage(page + 1)
                                    setLoading(true);
                                }} disabled={page === maxPageFirstTab - 1}><AiFillCaretRight /></Button>
                            </Flex>
                            {!loading ?
                                <Table.Root variant={"line"} w={"100%"}>
                                    {userPermissions.length === 0 ?
                                        <Text textAlign={"center"}>No Permissions Found! </Text>
                                        :
                                        <>
                                            <Table.Header>
                                                <Table.Row bg={"backgroundDarkGrey"}>
                                                    {/* this will show all skill that are possible to see */}
                                                    <Table.ColumnHeader textAlign={"left"}>Permission Name</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>

                                                {userPermissionsFiltered.map((permission) =>
                                                    <Table.Row display={{ base: "none", lg: "revert" }} bg={"rgba(0,0,0,0)"} key={permission.id}>
                                                        <Table.Cell>{permission.name}</Table.Cell>
                                                        <Table.Cell></Table.Cell>
                                                        <Table.Cell w={"150px"} >
                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                <GetUserConfirm
                                                                    confirmFunction={() => handleDelete(permission.id)}
                                                                    declineFunction={() => console.log("")}
                                                                    buttonText="Remove"
                                                                    buttonWarning={"Are you sure you want to remove this permission from " + props.user_name} />
                                                                {/* <Button leftIcon={<AiOutlineMinus/>} onClick={() => handleDelete(permission.id)}>Remove</Button> */}
                                                            </Flex>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}

                                            </Table.Body>
                                            <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-30px"}>
                                                {userPermissionsFiltered.map(permission => (
                                                    <Accordion.Item value={permission.name} key={permission.id}>
                                                        <Accordion.ItemTrigger>
                                                            <Flex justifyContent={"space-between"} w={"100%"}>
                                                                <Table.Cell display={{ base: "revert", lg: "none" }}>
                                                                    {permission.name}
                                                                </Table.Cell>
                                                                {/* <AccordionIcon /> */}
                                                            </Flex>
                                                        </Accordion.ItemTrigger>
                                                        <Accordion.ItemContent>
                                                            <Table.Cell display={{ base: "revert", lg: "none" }} marginLeft={"40px"} >
                                                                <GetUserConfirm
                                                                    confirmFunction={() => handleDelete(permission.id)}
                                                                    declineFunction={() => console.log("")}
                                                                    buttonText="Remove"
                                                                    buttonWarning={"Are you sure you want to remove this permission from " + props.user_name} />
                                                            </Table.Cell>
                                                        </Accordion.ItemContent>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion.Root>
                                        </>
                                    }
                                </Table.Root>
                                :
                                <Flex width={"100%"} justifyContent={"center"}>
                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"250px"} />
                                </Flex>
                            }
                            {/* </ScaleFade> */}
                        </Tabs.Content>
                        <Tabs.Content value={"Add Permissions"}>
                            {/* <ScaleFade initialScale={1} in={isOpen}> */}
                            <Flex direction={"row"} justifyContent={"right"}>
                                <TableControlsPermissions search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
                                <SearchBox search={search} handleSearchChange={handleSearchChange} />
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} display={{ base: "none", lg: "revert" }} fontSize={{ base: "xs", lg: "md" }}>Rows per Page:</Text>
                                <Flex borderColor={"textPrimary"} borderWidth={"2px"} borderRadius={"sm"} height={"35px"} marginTop={"5px"}>
                                    <SelectRoot collection={rowsCollection} onValueChange={(e) => handleChangeRowsPerPage(e)} value={[String(rowsPerPage)]} width={"30px"} marginRight={"20px"} marginLeft={"5px"} marginTop={"-5px"} size={"md"}>
                                        <SelectTrigger w={"50px"}>
                                            <SelectValueText placeholder={String(rowsPerPage)}></SelectValueText>
                                        </SelectTrigger>
                                        <SelectContent display={"absolute"} portalled={false}>
                                            {rowsCollection.items.map((each) =>
                                                <SelectItem item={each} key={each.value}>{each.label}</SelectItem>
                                            )}
                                        </SelectContent>
                                    </SelectRoot>
                                </Flex>
                                <Text marginLeft={"10px"} marginTop={"7px"} marginRight={"5px"} fontSize={{ base: "xs", lg: "md" }}>Page {page + 1} of {maxPages === 0 ? 1 : maxPages} </Text>
                                <Button bgColor={"AbleBlue"} mt={"2px"} size={"sm"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== 0 && setPage(page - 1);
                                    setLoading(true);
                                }} disabled={page === 0}><AiFillCaretLeft /></Button>
                                <Button size={"sm"} mt={"2px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} color={"white"} onClick={() => {
                                    page !== maxPages - 1 && setPage(page + 1);
                                    setLoading(true);
                                }} disabled={page === maxPages - 1 || maxPages === 0}><AiFillCaretRight /></Button>
                            </Flex>
                            {/* need to have a section or method of showing already selected skills */}
                            {!loading ?
                                <Table.Root variant={"line"} size={"sm"} className="table-tiny">
                                    {rows.length === 0 ?
                                        <Text textAlign={"center"} whiteSpace={"nowrap"}>No Permissions Found! </Text>
                                        :
                                        <>
                                            <Table.Header>
                                                <Table.Row backgroundColor={"backgroundDarkGrey"}>
                                                    {/* this will show all skill that are possible to see */}
                                                    <Table.ColumnHeader textAlign={"left"}>Permission Name</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Description</Table.ColumnHeader>
                                                    <Table.ColumnHeader display={{ base: "none", lg: "revert" }}></Table.ColumnHeader>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Accordion.Root multiple={false} display={{ base: "revert", lg: "none" }} marginLeft={"-19px"}>
                                                    {rows.map(permission => (
                                                        <Accordion.Item value={permission.id} key={permission.id}>
                                                            <Accordion.ItemTrigger>
                                                                <td>
                                                                    <Flex justifyContent={"space-between"} w={"100%"}>
                                                                        <Table.Cell display={{ base: "revert", lg: "none" }}>
                                                                            {permission.id}
                                                                            <br />
                                                                            <Text as={"i"} fontSize={"2xs"}>{permission.name}</Text> </Table.Cell>
                                                                        {/* <AccordionIcon /> */}
                                                                    </Flex>
                                                                </td>
                                                            </Accordion.ItemTrigger>
                                                            <Accordion.ItemContent>
                                                                <Table.Cell display={{ base: "revert", lg: "none" }} >
                                                                    <Button
                                                                        onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                        bg={"AbleBlue"}
                                                                        color={"white"}
                                                                        marginLeft={"30px"}
                                                                        _hover={{ color: "AbleYellow" }}
                                                                        loading={loading}>Assign Permission</Button></Table.Cell>
                                                            </Accordion.ItemContent>
                                                        </Accordion.Item>
                                                    ))}
                                                </Accordion.Root>
                                                {rows.map((permission) =>
                                                    <Table.Row display={{ base: "none", lg: "revert" }} background={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} key={permission.id}>
                                                        <Table.Cell width={"440px"}>
                                                            <Text>
                                                                {permission.id}
                                                            </Text></Table.Cell>
                                                        <Table.Cell >
                                                            <Text marginLeft={"0px"} flexWrap={"wrap"} width={"fit"}>
                                                                {permission.name}
                                                            </Text>
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            <Flex justifyContent={"right"} paddingRight={"10px"}>
                                                                <Button
                                                                    onClick={() => { handleAssign(permission.id); setLoading(true) }}
                                                                    bg={"AbleBlue"}
                                                                    color={"white"}
                                                                    _hover={{ color: "AbleYellow" }}
                                                                    loading={loading}>+ Assign</Button>
                                                            </Flex>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </>
                                    }
                                </Table.Root>
                                :
                                <Flex width={"100%"} justifyContent={"center"}>
                                    <Spinner color={"AbleBlue"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                                </Flex>
                            }
                            {/* </ScaleFade> */}
                        </Tabs.Content>
                    </Tabs.Root>
                </div>

                :
                <Flex>
                    <Text>No Permissions Found !</Text>
                </Flex>
            }
        </>
    );
}
