import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Input, Flex, Text, useDisclosure, useClipboard,
    Fieldset,
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { re } from "mathjs";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";

import { Tooltip } from "../ui/tooltip";
import { DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { InputGroup } from "../ui/input-group";

interface newUserProps {
    cb: Function,
}
interface IKeyResponse {
    expires: string,
    token: string,
}

export default function NewKey(props: newUserProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [token, setToken] = useState<string>("");
    const [expires, setExpires] = useState<string>("");
    const [showFirstPage, setShowFirstPage] = useState<boolean>(true);
    const CacheContext = useCache();
    const [name, setName] = useState<string>("");
    const { copy, value, setValue, copied } = useClipboard();


    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
        setName("");
        setShowFirstPage(true);
    }

    const handleClose = () => {
        onClose();
    }

    //const handleForcePasswordChange = () => setForcePassword(!forcePassword);


    const handleSubmit = () => {
        if (name !== undefined && name !== "") {
            webHelpers.PutAsync<IKeyResponse, unknown>("/api/management/key", "auth", { name: name }).then((res) => {

                console.log("successfully added key, response: ", res);
                if (res !== null) {
                    setShowFirstPage(false);
                    if (res.token !== "" && res.token !== undefined) {
                        setToken(res.token);
                        setValue(res.token)
                    }
                    if (res.expires !== "" && res.expires !== null && res.expires !== undefined) {
                        setExpires(res.expires)
                    }
                }
            }).catch((error) => {
                console.log("error creating key, error: ", error);
                toaster.create({

                    title: `An error occurred while trying to create your key`,
                    type: "error",

                });
            })
        }
        props.cb();
    }

    const handleCopy = () => {
        setValue(token);
        copy();

    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }

    return (
        <>
            <Toaster />
            <DialogRoot
                open={open}
                // onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "xl", lg: "md" }}
            >
                <Dialog.Backdrop />
                <DialogTrigger asChild marginTop={"14px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}

                        onClick={handleClickOpen} >
                        <BiPlus /> Create Key
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New Key"}</DialogTitle>
                    <DialogBody padding={"10px"}>

                        {showFirstPage ?
                            <Fieldset.Root>
                                <Fieldset.HelperText>Please enter a name for the new key.</Fieldset.HelperText>

                                <Input placeholder="Name" type="text" autoComplete="new-password" value={name} onChange={handleNameChange}></Input>

                            </Fieldset.Root>
                            :
                            <Flex direction={"column"}>
                                <Text marginBottom={"10px"}>Here is your generated key, make sure to copy it and store it somewhere safe!</Text>
                                <Input value={token} zIndex={5} paddingLeft={"0px"}></Input>
                                <Button onClick={handleCopy} size='lg' cursor={"pointer"}>
                                    <Text>{copied ? "Copied" : "Copy"}</Text>
                                </Button>
                                <br />
                                <Text>Expires: {expires} </Text>
                            </Flex>
                        }


                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => handleSubmit()}
                            disabled={name === ""}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}
                            display={showFirstPage ? "flex" : "none"}
                        >Create Key</Button>
                        <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>{showFirstPage ? "Cancel" : "Close"}</Button>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
