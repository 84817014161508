import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Select, Flex, Text, useDisclosure,
    createListCollection,
    Input,
    Box
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import ManageEvents from "./manageEvents";
import { CodeEditor } from "../code/codeEditor";
import ViewOutcomes from "./viewOutcomes";
import { LuCode2 } from "react-icons/lu";
import { Toaster, toaster } from "../ui/toaster";
import { Button } from "../ui/button";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from "../ui/select";
import { ITimeProfile } from "@/pages/timeProfiles";



interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
interface IWorkflow {
    description: string,
    event_id: string,
    id: string,
    initializer_code_id: string,
    name: string,
    cb: Function
}

export interface IWorkflowStep {
    id: string;
    created: string;
    created_by_id: string;
    created_by_name: string;
    updated: string;
    updated_by_id: string;
    updated_by_name: string;
    name: string;
    description: string;
    definition_id: string;
    index: number;
    cooldown_time: string;
    estimated_length: string | null;
    maximum_length: string | null;
    tag: string | null;
    execution_type: string;
    code_id: string;
    events: event[];
    twilio_task_type: string | null;
    twilio_task_type_key: string | null;
    time_profile_id: string;
    quick_outcome_code_id: string | null;
}
export interface event {
    event_id: string;
    step_id: string;
    code_definition: string;
}
interface Timeprofiles {
    id: string,
    is_bank_holiday_aware: boolean,
    name: string,
    days: Day[]
}
interface Day {
    day: string,
    end_time: string,
    id: string,
    start_time: string,
}
interface StepsProps {
    timeprofiles: Timeprofiles[],
    step: IWorkflowStep,
    workflow_id: string,
    cb: Function,
}
export default function StepsComponent(props: StepsProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState<boolean>(false);
    const [changes, setChanges] = useState<any[]>([]);
    const [initialOpen, setInitialOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
    const [steps, setSteps] = useState<IWorkflowStep[]>();
    const [value, setValue] = useState(1);

    const CacheContext = useCache();

    const [name, setName] = useState<string>(props.step.name);
    const [description, setDescription] = useState<string>(props.step.description);
    const [cooldown, setCooldown] = useState<string>(props.step.cooldown_time);
    const [execution_type, setExecutionType] = useState<string>(props.step.execution_type);
    const [timeprofile, setTimeprofile] = useState<string>(props.step.time_profile_id);
    const [twilioTaskType, setTwilioTaskType] = useState<string | null>(props.step.twilio_task_type);
    const [twilioTaskTypeKey, setTwilioTaskTypeKey] = useState<string | null>(props.step.twilio_task_type_key);
    const [hours, setHours] = useState<string>(props.step.cooldown_time.slice(0, 2));
    const [minutes, setMinutes] = useState<string>(props.step.cooldown_time.slice(3, 5));
    const [seconds, setSeconds] = useState<string>(props.step.cooldown_time.slice(6, 8));
    const [tag, setTag] = useState<string | null>(props.step.tag);

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value);
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value);
    }
    const handleCooldownChange = (e: React.FormEvent<HTMLInputElement>) => {
        setCooldown(e.currentTarget.value);
    }
    const handleExecutionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setExecutionType(e.currentTarget.value);
    }
    const handleTimeProfileChange = (e: any) => {
        console.log(e);
        // setTimeprofile(e.target.value);
    }
    const handleTwilioTaskType = (e: React.FormEvent<HTMLInputElement>) => {
        setTwilioTaskType(e.currentTarget.value);
    }
    const handleTwilioTaskTypeKey = (e: React.FormEvent<HTMLInputElement>) => {
        setTwilioTaskTypeKey(e.currentTarget.value);
    }
    const handleHoursChange = (e: React.FormEvent<HTMLInputElement>) => {
        setHours(e.currentTarget.value.slice(0, 2));
    }
    const handleMinutesChange = (e: React.FormEvent<HTMLInputElement>) => {
        setMinutes(e.currentTarget.value.slice(0, 2));
    }
    const handleSecondsChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSeconds(e.currentTarget.value.slice(0, 2));
    }
    const handleTagChange = (e: React.FormEvent<HTMLInputElement>) => {
        setTag(e.currentTarget.value);
    }



    const webHelpers = useWebHelper();

    function buildTwilioStep() {

        if (twilioTaskTypeKey === '' || twilioTaskType === '') {
            toaster.create({

                title: `Please ensure you supply a TwilioSid and Twilio Task Type`,
                type: "warning",

            });
        } else {
            let payload = {
                'workflow_id': props.workflow_id,
                'name': name,
                'description': description,
                'execution_type': execution_type,
                'cooldown_time': hours + ":" + minutes + ":" + seconds,
                'twilio_task': {
                    'workflow_sid': twilioTaskTypeKey,
                    'echo_interaction_type': twilioTaskType
                },
                'tag': tag,
                'events': [],
            }
            createStep(payload);
        }
    }
    function createStep(payload: any) {
        webHelpers.PutAsync('/api/workflow/step', 'helios-api', payload).then((data: any) => {
            if (data.response === 400) {
                toaster.create({

                    title: `Unable to create step`,
                    type: "error",

                });
            }
            else {
                toaster.create({

                    title: `Successfully created step`,
                    type: "success",

                });

            }
        })
    }
    const
        saveChanges = () => {
            setLoading(true);
            // api call to update info 
            let oldProfile = props;
            let payload: object;
            console.log("timeprofile = ", timeprofile)
            if (timeprofile !== null && timeprofile !== undefined && timeprofile !== "") {
                if (execution_type == 'CodeBased') {
                    payload = {
                        'id': props.step.id,
                        'name': name,
                        'description': description,
                        'execution_type': execution_type,
                        "time_profile_id": timeprofile,
                        'tag': tag,
                        'cooldown_time': hours + ":" + minutes + ":" + seconds,
                    }
                }
                else {
                    payload = {
                        'id': props.step.id,
                        'name': name,
                        'description': description,
                        'execution_type': execution_type,
                        'twilio_task': {
                            'echo_interaction_type': twilioTaskType,
                            'echo_interaction_type_key': twilioTaskTypeKey,
                        },
                        "time_profile_id": timeprofile,
                        'tag': tag,
                        'cooldown_time': hours + ":" + minutes + ":" + seconds,
                    }
                }
                if (payload) {
                    console.log(payload)
                    webHelpers.PostAsync('/api/workflow/step/' + props.step.id, 'helios-api', payload).then((data: any) => {
                        if (data.status === 400 || data.status === 500) {
                            setLoading(false);
                            props.cb();
                            return (
                                toaster.create({

                                    title: `Could not update workflow ${props.workflow_id}`,
                                    type: "error",

                                })
                            )
                        }
                        else {
                            props.cb();
                            setLoading(false);
                            return (
                                toaster.create({

                                    title: `Workflow ${props.workflow_id} has been updated!`,
                                    type: "success",

                                })
                            )

                        }

                    })
                }
            }
            else {
                setLoading(false);
                return (
                    toaster.create({

                        title: "Could not update workflow step (timeprofile_id is null)",
                        type: "error",
                    })
                )

            }
        }





    function getStepsDefinition() {
        webHelpers.GetAsync("/api/workflow/" + props.step.id + "/steps", "helios-api").then((data) => {
            if (data === undefined || data == null) {
                console.log("Unable to fetch steps from API", {
                    variant: "error",
                });
            } else {
                setSteps(Object.values(data));
                setCurrentStep(Object.values(data)[value - 1])
            }
        }
        );
    }

    const timeProfilesCollection = React.useMemo(() => {
        let test = createListCollection({ items: [] })

        props.timeprofiles.map((each: Timeprofiles) => {
            //@ts-ignore
            test.items.push({ label: each.name, value: each.id })
        })

        return test
    }, [props.timeprofiles])

    const collection = React.useMemo(() => {
        return createListCollection({
            items: [
                props.timeprofiles.map((each: any) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [props.timeprofiles])

    return (
        <>
        <Toaster/>
            <Flex direction="column">
                <Flex direction="row" marginBottom={"10px"} justifyContent={"space-around"}>
                    <Text marginTop={"7px"} marginLeft={"40px"} marginRight={"5px"} >Name:</Text>
                    <Box w={"full"} borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                        <Input value={name} onChange={handleNameChange} placeholder={"Name"} colorPalette={"red"} />
                    </Box>
                </Flex>
                <Flex direction="row" marginBottom={"10px"} justifyContent={"space-around"}>
                    <Text marginTop={"7px"} marginRight={"5px"}>Description:</Text>
                    <Box w={"full"} borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                        <Input value={description} onChange={handleDescriptionChange} placeholder="Description"></Input>
                    </Box>
                </Flex>
                <Flex direction={"row"} marginBottom={"10px"} justifyContent={"space-around"}>
                    <Text marginRight={"5px"} marginLeft={"8px"} marginTop={"7px"}>Cooldown:</Text>
                    <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                        <Input value={hours} width={"100px"} textAlign={"center"} onChange={handleHoursChange}  ></Input>
                    </Box>
                    <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>

                        <Input value={minutes} width={"100px"} textAlign={"center"} onChange={handleMinutesChange}></Input>
                    </Box>
                    <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                        <Input value={seconds} width={"100px"} textAlign={"center"} onChange={handleSecondsChange}></Input>
                    </Box>
                </Flex>
                <ManageEvents {...props} />
                <ViewOutcomes stepId={props.step.id} cb={getStepsDefinition} />
                <Flex direction="row" justifyContent={"center"} >
                    <Text marginTop={"5px"} marginRight={"4px"} w={"65px"} marginLeft={"50px"}>Tag : </Text>
                    <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                        <Input value={tag !== null ? tag : ""} w={"fill"} placeholder="Enter a tag" onChange={handleTagChange} />
                    </Box>
                </Flex>
                <Flex direction={"row"} marginTop={"10px"}>
                    <Text w={"125px"} marginTop={"5px"} marginRight={"5px"}>Time Profile:</Text>
                    <SelectRoot collection={timeProfilesCollection} value={[timeprofile]} onValueChange={handleTimeProfileChange} marginBottom={"10px"} placeholder="Please select a time profile">
                        <Box borderWidth={"1px"} borderColor={"backgroundLightGrey"} bg={"backgroundDarkGrey"} borderRadius={"lg"} paddingLeft={"5px"}>
                            <SelectTrigger>
                                <SelectValueText placeholder="Select a time profile"></SelectValueText>
                            </SelectTrigger>
                        </Box>
                        <SelectContent>
                            {timeProfilesCollection.items.map((each: any) =>
                                <SelectItem onClick={() => setTimeprofile(each.value)} item={each} key={each.value}> {each.label}</SelectItem>
                            )}
                        </SelectContent>
                    </SelectRoot>
                </Flex>
                {/*Not needed anymore since Gav made it so all workflow steps are inheritantly code based now*/}
                {/* <Flex direction={"row"} marginBottom={"10px"} display={"none"}> 
                    <Text>Execution Type: {execution_type === "TwilioTask" ? " Twilio-Based" : "Code-Based"}</Text>
                    {execution_type === "TwilioTask" ?
                        <Switch isChecked={execution_type === "TwilioTask"} onChange={() => setExecutionType("CodeBased")} marginTop={"2px"} marginLeft={"10px"} />
                        :
                        <Switch isChecked={execution_type === "TwilioTask"} onChange={() => setExecutionType("TwilioTask")} marginTop={"2px"} marginLeft={"10px"} />
                    }
                </Flex> */}

                {execution_type === "TwilioTask" ?
                    <>
                        <Flex direction="row" justifyContent={"left"} marginBottom={"10px"}>
                            <Text marginTop={"5px"}>Twilio Task Type Key: </Text>
                            <Input value={twilioTaskTypeKey !== null ? twilioTaskTypeKey : "N/A"} onChange={handleTwilioTaskTypeKey} width={"175px"} marginLeft={"25px"} />
                        </Flex>
                        <Flex direction="row" justifyContent={"left"}>
                            <Text marginTop={"5px"}>Twilio Task Type: </Text>
                            <Input value={twilioTaskType !== null ? twilioTaskType : "N/A"} onChange={handleTwilioTaskType} width={"175px"} marginLeft={"55px"} />
                        </Flex>
                    </>
                    :
                    <>
                        {execution_type !== "TwilioTask" &&
                            <Flex height={"50%"}>
                                <Flex width={"50%"} marginLeft={"-10px"}>
                                    <CodeEditor code_id={props.step.code_id} stepFriendlyName={props.step.name} buttonText={"Edit Code"} mode={"edit-step"} cb={undefined} intital_value={undefined} quick_outcome_code_id={props.step.quick_outcome_code_id} />
                                </Flex>
                                {props.step.quick_outcome_code_id !== null &&
                                    <Flex width={"50%"} marginLeft={"10px"}>
                                        <CodeEditor code_id={props.step.code_id} stepFriendlyName={props.step.name} buttonText="Edit Outcome" mode={"edit-outcome"} cb={undefined} intital_value={undefined} quick_outcome_code_id={props.step.quick_outcome_code_id} />
                                    </Flex>
                                }
                            </Flex>
                        }
                    </>
                }
                <Flex justifyContent={"center"}>
                    <Button marginLeft={"10px"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"20px"} onClick={saveChanges} loading={loading}
                        disabled={
                            (name === props.step.name && description === props.step.description && tag === props.step.tag && timeprofile === props.step.time_profile_id)
                            ||
                            (timeprofile === null || timeprofile === "")
                        }
                    >Update Step</Button>
                </Flex>
            </Flex>
        </>
    );
}
