import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Input, Flex, useDisclosure,
    Select,
    createListCollection,
    Fieldset, Dialog,
    SelectValueText
} from '@chakra-ui/react';
import * as JsonData from "../../assets/countryCodes.json";

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import e from "express";
import { Toaster, toaster } from "../ui/toaster";
import { css } from "@emotion/react";
import { Button } from "../ui/button";
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";
import { SelectContent, SelectItem, SelectRoot, SelectTrigger } from "../ui/select";

interface BlockNumberProps {
    cb: Function,
}
interface CountryCode {
    name: string;
    dial_code: string;
    code: string;
}

export default function BlockNumber(props: BlockNumberProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [number, setNumber] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [forcePassword, setForcePassword] = useState<boolean>(false);
    const [isSecurity, setIsSecurity] = useState<boolean>(false);
    const [type, setType] = useState<string[]>(["0"]);
    const [countryCode, setCountryCode] = useState(["GB +44"]);

    const webHelpers = useWebHelper();
    const AllCodes = Object.values(JsonData);

    const handleClickOpen = () => {
        setNumber("");
        setReason("");
        setType(["0"])
        onOpen();
        setLoading(false);
        toaster.create({
            title: `Please fill out every field in the form before submitting`,
            type: "warning",
            

        });
    }

    const handleClose = () => {
        onClose();
    }
    const handleTypeChange = (event: any) => {
        setType(event.value);
    };

    const handleMenuSelect = (event: any) => {
        setCountryCode([event.value[0] as string]);

    };

    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setNumber(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setReason(e.currentTarget.value)
    }
    const validateNumber = () => {
        let dirty = false;

        if (type[0] == "3" || reason === "" || number === "") {
            dirty = true;
            setLoading(false);
            toaster.create({

                title: `Please fill out every field in the form before submitting`,
                type: "warning",

            });

        }
        if (/^[0-9]+$/.test(number) === false) {
            dirty = true;
            setLoading(false);
            toaster.create({

                title: `Only enter digits into the number field, \nif you need to use a country code be sure to select whole number from the drop-down menu.`,
                type: "warning",

            });

        }
        if (type[0] == "0" && (number.length < 3 || number.length > 10)) {
            dirty = true;
            setLoading(false);
            toaster.create({

                title: `Please enter a number that is between 3 and 10 digits long`,
                type: "warning",

            });

        }
        if (
            (type[0] == "1" || type[0] == "2") &&
            (number.length < 3 || number.length > 10)
        ) {
            dirty = true;
            setLoading(false);
            toaster.create({

                title: `Please enter a number that is between 3 and 10 digits long`,
                type: "warning",

            });
        } else if (dirty === false) {
            blockNumber();
        }
    }

    const blockNumber = () => {
        let fullNumber = countryCode.slice(3) + number;
        if (type[0] === "0" || type[0] === "1") {
            fullNumber.replace("+", "");
        }
        let payload = {
            id: fullNumber,
            reason: reason,
            type: type[0],
        }
        webHelpers.PutAsync("/api/call/control/block", "zeus-api", payload).then((data: any) => {
            setLoading(false);
            if (data.id === fullNumber) {
                handleClose();

                props.cb();
                toaster.create({

                    title: `Phone Number ${data.id} added to Blocklist`,
                    type: "success",

                });
            }
            else {
                toaster.create({

                    title: `Phone Number ${data.id} could not be added to Blocklist`,
                    type: "error",

                });
            }
        }).catch((error) => {
            setLoading(false);
            if (error.status === 409) {
                toaster.create({

                    title: `Phone Number ${fullNumber} could not be added to Blocklist (Already Present in Table)`,
                    type: "error",

                });
            }
            console.log("error blocking phone number " + fullNumber + ". Reason: ", error);
        })
    }
    const collection = createListCollection({
        items: [
            { label: "Whole Number", value: "0" },
            { label: "Starts With", value: "1" },
            { label: "Ends With", value: "2" }
        ]
    })

    const phoneNumberCollection = React.useMemo(() => {
        let x = createListCollection({
            items: []
        })
        AllCodes.map((each: CountryCode) => {
            //@ts-ignore
            x.items.push({ label: each.name, value: each.code + " " + each.dial_code, code: each.code })
        })
        return x
    }, [AllCodes])

    return (
        <>
        <Toaster/>
            <DialogRoot
                open={open}
                // onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                onInteractOutside={onClose}
            >
                <DialogBackdrop />
                
                <DialogTrigger marginTop={"9px"}>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={"30px"}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        onClick={handleClickOpen}>
                        <BiPlus />
                        Add Number
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Create New Permission"}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>
                            <SelectRoot
                                id="number-type"
                                collection={collection}
                                value={type}
                                onValueChange={handleTypeChange}
                                marginBottom={"10px"}
                                defaultValue={["0"]}
                            >
                                <SelectTrigger>
                                    <SelectValueText placeholder="Select Item"></SelectValueText>
                                </SelectTrigger>
                                <SelectContent portalled={false}>
                                    {collection.items.map((each) =>
                                        <SelectItem item={each} key={each.value}>
                                            {each.label}
                                        </SelectItem>
                                    )}
                                </SelectContent>
                            </SelectRoot>
                            <Flex direction="row" marginBottom={"10px"}>
                                <SelectRoot
                                    defaultValue={["+44"]}
                                    id="countryCode"
                                    value={countryCode}
                                    style={{ marginRight: '1rem' }}
                                    onValueChange={handleMenuSelect}
                                    disabled={String(type[0]) === "3" || type[0] === "2"}
                                    collection={phoneNumberCollection}
                                    w={"100px"}
                                >
                                    <SelectTrigger>
                                        <SelectValueText placeholder="Select Country Code">{countryCode}</SelectValueText>
                                    </SelectTrigger>
                                    <SelectContent portalled={false} w={"300px"}>
                                        {phoneNumberCollection.items.map((code: any) =>
                                            <SelectItem item={code} key={code.label}>
                                                {code.label + " "} {code.value.split(" ")[1]}
                                            </SelectItem>
                                        )}
                                    </SelectContent>
                                </SelectRoot>
                                <Input placeholder="Number" type="text" autoComplete="new-password" value={number} onChange={handleNameChange} width={"250px"}></Input>
                            </Flex>
                            <Input placeholder="Reason" type="text" autoComplete="new-password" value={reason} onChange={handleDescriptionChange}></Input>

                        </Fieldset.Root>


                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => {
                            setLoading(true);
                            validateNumber();
                        }}
                            loading={loading}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Block Number</Button>
                        <DialogTrigger>
                            <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
