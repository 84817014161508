import * as React from 'react';
import { useState } from 'react';


import {
    createListCollection,
    Dialog, Select, Text
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";


// Custom Components
// import RoleManagement from '../modal-role-management/roles-table.component';
// import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
// import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useWebHelper } from "../../hooks/UseWebHelper";



import { BiAddToQueue, BiCloset } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { Toaster, toaster } from '../ui/toaster';
import { Button } from '../ui/button';
import { DialogBody, DialogContent, DialogRoot, DialogTitle } from '../ui/dialog';
import { SelectContent, SelectItem, SelectTrigger, SelectValueText } from '../ui/select';






interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface INamespace {
    name_space: string;
    is_default: boolean;
}
const defaultWorkflow: INamespace[] = [
    {
        name_space: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};
interface NamespceModalProps {
    cb: Function
}

export default function NamespaceModal(props: any) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    // const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<INamespace[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("New Namespace");

    const webHelpers = useWebHelper();


    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)


    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.GetAsync('/api/code/config/namespaces', 'helios-api').then((data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                toaster.create({

                    title: `Unable to fetch workflows [${data.status}]`,
                    type: "success",

                })
            }
            else {
                setRows2(Object.values(data));
                setOpen(true);
                //setMaxPages(data.maximum_pages)
            }
        })
    };

    const test = React.useMemo(() => {
        return createListCollection({
            items: [
                rows2.map((each: INamespace) => {
                    return { label: each.name_space, value: each.name_space, is_default: each.is_default }
                })
            ]
        })
    }, [rows2])


    function handleClose() {
        setOpen(false);
    }
    const handleProfileChange = (event: any) => {
        setNewNamespace(event.value[0]);
    }
    const handleSubmit = () => {
        props.cb(newNamespace);
        setOpen(false);
    }

    const namespaceCollection = React.useMemo(() => {
        let x = createListCollection({
            items: [

            ]
        })
        rows2.map((each: INamespace) => {
            //@ts-ignore
            x.items.push({ label: each.name_space, value: each.name_space, default: each.is_default })
        })
        return x
    }, [rows2])

    return (
        <>
            <Toaster />
            <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginBottom={"10px"}
                className='button-test' onClick={handleOpen}><BiAddToQueue />Add new Namespace</Button>
            <DialogRoot
                open={open}
                onOpenChange={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Dialog.Backdrop />
                <DialogContent>
                    <DialogTitle>
                        <div className="modal-header">
                            <Text>
                                Add New Namespace:
                            </Text>
                        </div>

                        <Text as={"i"} fontSize={"sm"}>
                            Select a new namespace from the drop down box below
                        </Text>
                    </DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Select.Root
                            collection={namespaceCollection}
                            id="demo-simple-select"
                            value={[newNamespace]}
                            onValueChange={handleProfileChange}
                            disabled={namespaceCollection.items.filter((x: any) => x.default === false).length === 0}
                        >
                            <SelectTrigger>
                                <SelectValueText placeholder={namespaceCollection.items.filter((x: any) => x.default === false).length === 0 ? "No Namespaces to add" : 'Select a namespace'}></SelectValueText>
                            </SelectTrigger>
                            <SelectContent portalled={false}>
                                {namespaceCollection.items.map((workflow: any) => (
                                    (workflow.default === false &&
                                        <SelectItem item={workflow} key={workflow.value}>{workflow.label}</SelectItem>
                                    )))}
                            </SelectContent>
                        </Select.Root>
                        <Button size={"sm"} bgColor={"AbleBlue"} colorScheme={"blue"} _hover={{ bgColor: "AbleYellow" }} _active={{ bg: "#ECB500" }} color={"white"} marginTop={"10px"}
                            className="modal-button-submit" onClick={handleSubmit} disabled={newNamespace === "New Namespace"}><BiAddToQueue />Add to Code</Button>
                        {/* might need to add a variant to the button above  */}

                    </DialogBody>
                </DialogContent>
            </DialogRoot >
        </>
    );
}