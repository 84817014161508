import { createListCollection, useDisclosure } from "@chakra-ui/react"
import { Table, Spinner, TableCaption, Select, Input, Flex, Heading, Text, } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt, BiPhoneCall } from "react-icons/bi";
import "../../App.css"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { useAuth } from "../../context/AuthContext";
import loadingGif from "../../assets/loadingIcon.gif";
import errorImg from "../../assets/loadingErrorScreen.png";
import useOverFlowHidden from "../../hooks/UseOverFlowHidden";
import e from "express";
import { useCache } from "../../context/CacheContext";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { AiFillLock } from "react-icons/ai";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosGitNetwork } from "react-icons/io"
import { RiTimerFill } from "react-icons/ri"
import { GrNetwork } from "react-icons/gr";
import { BsPerson } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { TbIdBadge2 } from "react-icons/tb"
import { BsPersonBadge } from "react-icons/bs";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSync } from "react-icons/ai";
import UserAppPermissionsModal from "../../components/user/userAppPermissionsModal"
import PasswordReset from "../../components/user/passwordReset";
import EnableDisable from "../../components/user/enableDisable";
import EditUserModal from "../../components/user/editUser";
import { IEnvironment, useEnvironment } from "../../context/EnvironmentContext";
import NewUser from "../../components/user/newUser";
import CreatePermission from "../../components/permissions/createPermission";
import BlockNumber from "../../components/blocklist/blockNumber";
import GetUserConfirm from "../../components/confirm";
import GoBack from "../../components/goBack";
import InfiniteScroll from 'react-infinite-scroll-component';
import TableControlsPermissions from "../../components/table/tableControlsPermissions";
import Refresh from "../../components/refresh";
import { useColorMode } from "../../components/ui/color-mode";
import { Toaster, toaster } from "../../components/ui/toaster";
import { Button } from "../../components/ui/button";
import TableControls2 from "../../components/table/tableControlsComponent";

export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}

export interface Type {
    id: string,
    name: string,
}
interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}

interface IAllocationModel {
    accepted: boolean,
    appointment_id: string
}
interface IUserTypes {
    id: string,
    name: string,
}
interface IPermission {
    id: string,
    name: string,
}
interface IBlocklist {
    id: string,
    created: string,
    createdById: string,
    createdByName: string,
    expires: string,
    reason: string,
    type: number,
}
export const BlockedNumbersPage = () => {
    const CacheContext = useCache();
    const [rows, setRows] = useState<IBlocklist[]>([]);
    const [maxPages, setMaxPages] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [maxPageFirstTab, setMaxPagesFirstTab] = useState<number>(1);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const { open, onToggle } = useDisclosure();
    const [isPageOpen, setIsPageOpen] = useState<boolean>(true);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [engineerList, setEngineerList] = useState<any[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [tableHeight, setTableHeight] = useState('5100px')
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    const [nextPage, setNextPage] = useState<IBlocklist[]>([]);
    const [nextPageNo, setNextPageNo] = useState<number>(0);
    const { colorMode, toggleColorMode } = useColorMode();




    const webHelpers = useWebHelper();

    const EnvironmentContext = useEnvironment();



    // function searchPermissions(search: string) {
    //     webHelpers.GetAsync('/api/management/permissions/' + rowsPerPage + '/' + (page + 1) + '/' + search, 'auth').then((data: any) => {
    //         if (data.status === 200 || data.status === undefined) {
    //             // @ts-ignore 
    //             setRows(Object.values(data.permissions));
    //             setMaxPages(data.max_pages);
    //             setLoading(false);
    //         }
    //         else {
    //             console.log(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
    //         }
    //     }).catch((error) => {
    //         setRows([]);
    //         setMaxPages(0);
    //         console.log("no permissions match search criteria \"" + search + "\"");
    //     });
    // }
    function goToNextPage(pageToLoad: number) { //used for infinite scrolling
        webHelpers.GetAsync("/api/call/control/block/?limit=" + rowsPerPage + "&page=" + (pageToLoad + 1) + "&filter=" + search, "zeus-api").then((data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
                setLoading(false);
            }
            else {
                console.log(data);
                setNextPage(data.results)
                data.results.forEach((element: IBlocklist) => {
                    setRows(rows => [...rows, element])
                })

                console.log(rows)
                setMaxPages(data.maxPage)
                setLoading(false);
            }
        });
    }
    useEffect(() => {
        setNextPageNo(1);
    }, [page]);

    function updateTable() {
        setLoading(true)
        setNextPageNo(page);
        webHelpers.GetAsync(
            "/api/call/control/block/?limit=" + rowsPerPage + "&page=" + (page + 1) + "&filter=" + search, "zeus-api").then((data: any) => {
                if (data === undefined) {
                    toaster.create({

                        title: `${data.status}: Unable to fetch numbers from the API`,
                        type: "error",

                    });
                } else {
                    setRows(data.results);
                    setMaxPages(data.maxPage);
                    setLoading(false);
                }
            }
            ).catch((error) => {
                setLoading(false);
                toaster.create({

                    title: `${error.status}: Unable to fetch numbers from the API`,
                    type: "error",

                });
            });
    }

    const unblockNumber = (id: string) => {
        let number_id = id;
        number_id = number_id.replace('+', '$');
        webHelpers.DeleteAsync("/api/call/control/block/?id=" + number_id, "zeus-api", {}).then((data: any) => {
            if (data.id === id) {
                //success
                setLoading(true);
                updateTable();
                toaster.create({

                    title: `Phone Number ${data.id} has been unblocked`,
                    type: "success",

                });
            }
            else {
                setLoading(true);
                toaster.create({

                    title: `Phone Number ${data.id} could not be unblocked`,
                    type: "error",

                });
            }

        }).catch((error) => {
            setLoading(true);
            updateTable();
            toaster.create({

                title: `Phone Number ${id} could not be unblocked [${error.status}]`,
                type: "error",

            });
        })
    }

    useEffect(() => {
        updateTable();
    }, [page, rowsPerPage]);

    useEffect(() => {
        //this will run whenever the user changes page, changing the list to whatever the new page is
        // setPageSkills(allSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
        // setPageUserSkills(userSkills.slice((page * rowsPerPage), (((page + 1) * rowsPerPage))))
    }, [page]);

    const handleSearchChange = (event: any) => {
        setSearch(event.target.value);
        updateTable();
    }
    const handleChangeRowsPerPage = (event: any) => {
        setLoading(true)
        setRowsPerPage(event.value[0]);
        setPage(0);
    };

    useEffect(() => {
        setRows([])
        updateTable();
    }, [EnvironmentContext.selectedEnvironment])
    const handleEnvChange = (event: any) => {
        setLoading(true);
        let isSuccess: boolean = EnvironmentContext.setEnvironmentById(event.value[0]);
    }

    const enviromentCollection = React.useMemo(() => {
        return createListCollection({
            items: [
                EnvironmentContext.environments.map((each: IEnvironment) => {
                    return { label: each.name, value: each.id }
                })
            ]
        })
    }, [EnvironmentContext])

    const rowsCollection = createListCollection({
        items: [
            { label: "5", value: 5 },
            { label: "10", value: 10 },
            { label: "15", value: 15 }
        ]
    })
    return (
        <Flex direction={"column"}>
            <Toaster/>
            <Heading marginTop={"20px"} color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} as={"i"} justifySelf={"center"} display={"block"} fontWeight={"lighter"}
            >Blocklist Management</Heading>
            <Flex direction={"row"} justifyContent={"center"} marginTop={"10px"}>
                <div>
                    {/* <ScaleFade initialScale={1} in={true}> */}
                    <Flex direction={"row"} justifyContent={"center"}>
                        <TableControlsPermissions handleChangeRowsPerPage={handleChangeRowsPerPage} search={search} handleSearchChange={handleSearchChange} rowsPerPage={rowsPerPage} />
                        <TableControls2 handleEnvChange={handleEnvChange} handleSearchChange={handleSearchChange} handleProfileChange={() => console.log("")} syncHandler={() => console.log("")} search={search} selectedType={selectedType}
                            userTypes={undefined} showTwilioSync={false} showUserTypes={false} handleLoadingChange={setLoading} handleRowsPerPageChange={handleChangeRowsPerPage} refresh={updateTable} page={page} maxPage={maxPages} setPage={setPage} rowsPerPage={rowsPerPage} />
                        <BlockNumber cb={updateTable} />
                    </Flex>
                    {/* need to have a section or method of showing already selected skills */}
                    {!loading ?
                        <>
                            {rows.length > 0 ?
                                // <ScaleFade initialScale={1} in={!loading}>
                                <Flex h={750 * ((rows.length / rowsPerPage) + 1) + 10 + "px"}>
                                    <>

                                        <Table.Root variant={"line"}>
                                            {rows.length === 0 ?
                                                <Text textAlign={"center"}>No Permissions Found! </Text>
                                                :
                                                <>

                                                    <InfiniteScroll
                                                        dataLength={rows.length}
                                                        next={() => {

                                                            if (nextPageNo < page) {
                                                                setNextPageNo(page + 1);
                                                                goToNextPage(page + 1)
                                                            }
                                                            else {
                                                                goToNextPage(nextPageNo + 1)
                                                                setNextPageNo(nextPageNo + 1);
                                                            }
                                                        }}
                                                        hasMore={nextPageNo <= maxPages - 2}
                                                        loader={
                                                            <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={colorMode === "light" ? "backgroundDarkGrey" : "backgroundGrey"} marginTop={{ base: "170px", lg: "100px" }}>
                                                                <Spinner color={colorMode === "light" ? "AbleBlue" : "AbleYellow"} marginTop={"10px"} marginBottom={"100px"} size={"xl"} display={(nextPageNo < maxPages) && nextPageNo !== 1 ? "flex" : "none"}></Spinner>
                                                                {/* <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} /> */}
                                                            </Flex>
                                                        }
                                                        endMessage={
                                                            <p style={{ textAlign: 'center' }}>
                                                                <b>{rows.length < rowsPerPage ? " " : "End of Results"}</b>
                                                            </p>
                                                        }
                                                        scrollThreshold={1}
                                                    >
                                                        <TableCaption>
                                                            <Text>
                                                                {rows.length < rowsPerPage ? " " : "Scroll Down to Load More Results"}
                                                            </Text>
                                                        </TableCaption>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                {/* this will show all skill that are possible to see */}
                                                                <Table.ColumnHeader textAlign={"Left"}>Phone Number</Table.ColumnHeader>
                                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Created</Table.ColumnHeader>
                                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Created By</Table.ColumnHeader>
                                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Expires</Table.ColumnHeader>
                                                                <Table.ColumnHeader display={{ base: "none", lg: "revert" }}>Reason</Table.ColumnHeader>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {rows.map((number) =>
                                                                <Table.Row>
                                                                    <Table.Cell paddingRight={"100px"}>{number.id}</Table.Cell>
                                                                    <Table.Cell paddingRight={"100px"} textOverflow={"ellipsis"} display={{ base: "none", lg: "revert" }}>{
                                                                        number.created.slice(8, 10) + "/" +
                                                                        number.created.slice(5, 7) + "/" +
                                                                        number.created.slice(0, 4) + " " + number.created.slice(11)
                                                                    }</Table.Cell>
                                                                    <Table.Cell paddingRight={"100px"} display={{ base: "none", lg: "revert" }}>{number.createdByName}</Table.Cell>
                                                                    <Table.Cell paddingRight={"100px"} textOverflow={"ellipsis"} display={{ base: "none", lg: "revert" }}>{
                                                                        number.expires.slice(8, 10) + "/" +
                                                                        number.expires.slice(5, 7) + "/" +
                                                                        number.expires.slice(0, 4) + " " + number.expires.slice(11)
                                                                    }</Table.Cell>
                                                                    <Table.Cell paddingRight={"100px"} display={{ base: "none", lg: "revert" }}>{number.reason}</Table.Cell>
                                                                    <Table.Cell>
                                                                        <GetUserConfirm buttonText="Unblock" buttonWarning="Are you sure you want to unblock this number?"
                                                                            declineFunction={() => console.log("")} confirmFunction={() => unblockNumber(number.id)} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </InfiniteScroll>

                                                </>
                                            }
                                        </Table.Root>
                                    </>
                                </Flex>
                                // </ScaleFade>


                                :
                                <Flex justifyContent={"center"} marginTop={"100px"}>
                                    <Text>No Blocked Numbers Found !</Text>
                                </Flex>
                            }

                        </>

                        :

                        <Flex width={"100%"} justifyContent={"center"}>
                            <Spinner color={colorMode === 'light' ? "AbleBlue" : "AbleYellow"} size={"xl"} marginTop={"100px"} marginBottom={"100px"} />
                        </Flex>
                    }
                    {/* </ScaleFade> */}
                </div>




            </Flex>
        </Flex >
    )
}
