import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Table, Flex, Text, useDisclosure,
    TableRoot,
    TableHeader,
    TableRow,
    TableColumnHeader,
    TableBody
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { AiFillPhone } from "react-icons/ai";
import { BsFillSpeakerFill } from "react-icons/bs"
import { FaUserSlash } from "react-icons/fa";
import RoleRow from "./roleRow";
import NewRole from "./newRole";
import GetUserConfirm from "../confirm";
import { StringMappingType } from "typescript";
import { Toaster, toaster } from "../ui/toaster";
import { Tooltip } from "../ui/tooltip";


interface Application {
    id: string;
    ttl: number;
    enabled: boolean | undefined;
}
interface UserAppsDialogProps {
    user: User;
    cb: Function;
}
export interface Type {
    id: string,
    name: string,
}
export interface User {
    id: string,
    account_id: string,
    applications: []
    created: string,
    created_by_id: string
    department: string
    email_address: string,
    first_name: string,
    last_name: string,
    full_name: string,
    groups: [],
    initiated: boolean,
    status: number,
    type: Type,
    type_id: string,
    user_type_id: string,
}
export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}
interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}
interface IWorker {
    id: string,
    twilio_sid: string,
    user_sid: string,
    name: string,
    activity_sid: string,
    avtivity_name: string,
    activity_last_updated: string,
    role_id: string,
    queues: string,
    channels: [],
    role: string,
}
// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],

export default function RoleManagement(props: RoleManagementProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [applications, setApplications] = useState<Application[]>([
        { id: "echo", ttl: 1235, enabled: false },
    ]);
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [activeRole, setActiveRole] = useState<string>();
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [activeWorker, setActiveWorker] = useState();
    const [workerExists, setWorkerExists] = useState(false);
    const [activeWorkerId, setActiveWorkerId] = useState<string>('');

    const webHelpers = useWebHelper();

    function getActiveRole() {
        webHelpers.GetAsync<any>('/api/worker/' + props.user_id, 'zeus-api').then((data: any) => {
            setActiveRole(data.role_id === undefined || data.role_id === null ? "" : data.role_id)
        }).catch(() => {
            console.log("NO ACTIVE ROLE HAS BEEN SET FOR THIS ROLE!")
        })
        // }).catch((error) => {
        //     console.log("getActiveRole Error : ", error);

        // })
    }
    function getWorker() {
        console.log("checking user " + props.user_id + " for existing workers")
        webHelpers.GetAsync<IWorker>("/api/worker/" + props.user_id, "zeus-api").then((res: any) => {
            //worker does not exist for this user
            if (res.length === 0 || res === undefined || res === null) {
                setWorkerExists(false);
            }
            else {
                if (res.name !== undefined) {
                    setWorkerExists(true);
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    useEffect(() => {
        getActiveRole();
        getWorker();

    }, [])

    function getRoles() {
        setRoles([]);
        getActiveRole();
        if (workerExists) {
            webHelpers.GetAsync('/api/worker/roles/' + props.user_id, 'zeus-api').then((data: any) => {

                if (data === undefined || data === null || data.status !== undefined) {
                    console.log(`${data.status}: Unable to fetch roles`, { 'variant': 'error' })
                }
                else {
                    findWorker(props.user_id);
                    setRoles(data);

                }
            }).catch((error) => {
                toaster.create({

                    title: `An error occurred while fetching roles [${error.status}]`,
                    type: "error",

                })
            })
        }
    }
    // Takes changes applied to a locally stored role and pushes them to the database
    function handleSetActive(id: string) {

        webHelpers.PostAsync('/api/worker/role/' + id + '/set', 'zeus-api', {}).then((data: any) => {
            if (data.status === undefined) {

            }
        })
        getRoles();
    }
    // Removing a role
    function handleDelete(id: string) {
        if (roles.length == 1) {
            toaster.create({

                title: `Cannot delete a user's last role!`,
                type: "warning",

            })
        } else {
            webHelpers.DeleteAsync('/api/worker/role/' + id, 'zeus-api', {}).then((data: any) => {
                if (data.status != 200) {
                    toaster.create({

                        title: `Unable to remove this role [${data.status}]`,
                        type: "error",

                    })
                }
                else {
                    getRoles();
                    props.cb();
                    toaster.create({

                        title: `Role successfully deleted`,
                        type: "success",

                    })

                }
            }).catch((error) => {
                console.log("error occurred , ", error);
                getRoles();
                props.cb();
            }
            )
        }

    }
    function findWorker(id: string) {
        //testing to see if I can get active Role
        // webHelpers.GetAsync('/api/worker/' + id, 'zeus-api').then((data: any) => {
        //     console.log("worker test result = ", data);
        //     setActiveRole(data.role_id)

        // })
        webHelpers.GetAsync('/api/worker/roles/' + id, 'zeus-api').then((data: any) => {
            if (data !== null && data !== undefined && data.length !== 0) {
                setActiveWorker(data);
                setActiveWorkerId(data.id);
                return true;

            }
            else {
                // No worker exists
                setWorkerExists(false);
                return false;
            }

        }).catch((error) => {
            console.log("find worker error", error);
            console.log("no workers found error")
        })

    }
    function createWorker() {
        webHelpers.PutAsync("/api/worker", "zeus-api", { user_id: props.user_id, name: props.user_name }).then((res: any) => {
            setWorkerExists(true);
        })
    }
    useEffect(() => {
        if (!workerExists) {
            findWorker(props.user_id);
        }
    }, [])

    if (!workerExists) {
        return (
            <Flex justifyContent={"center"} direction={"column"}>
                <Flex justifyContent={"center"} direction={"row"}>
                    <Text mt={"3px"}><FaUserSlash color="gray" /></Text>
                    <Text ml={"10px"}>No Worker Found!</Text>
                </Flex>
                <GetUserConfirm buttonText="Create Worker" confirmFunction={createWorker} declineFunction={() => console.log("cancelled")} buttonWarning="Are you sure you want to Create a new Worker for this user?" />
                <Flex justifyContent={"center"} w={"150px"}>

                    <div className="button-tray"></div>
                </Flex>
            </Flex >
        );
    }
    if ((Object.values(props.roles).length === 0 || activeWorkerId === '') && workerExists) {

        return (
            <>
                <Flex justifyContent={"center"} direction={"column"}>
                    <Flex justifyContent={"center"} direction={"row"}>

                        <Text mt={"3px"}><FaUserSlash color="gray" /></Text>
                        <p>User has a worker but no roles! This shouldn't happen! Please create one.</p>

                    </Flex>
                    <NewRole userId={props.user_id} cb={getRoles} />
                    <div className="button-tray">

                        {/* Needs to be a dialog component, force creation of one role */}
                        {/* <CreateRole worker_id={activeWorkerId} cb={getRoles} /> */}
                    </div>
                </Flex>
            </>

        )
    }

    else {
        // Check which role is active by pinging the worker
        return (
            <div>
                <Toaster />
                <TableRoot style={{ minWidth: '100%' }} size='sm' aria-label="custom pagination table">
                    <TableHeader>
                        <TableRow bg={"backgroundGrey"}>
                            <TableColumnHeader align="left"><strong>Role</strong></TableColumnHeader>
                            <TableColumnHeader align="left" display={{ base: "none", lg: "revert" }}><strong>Skills</strong>
                            </TableColumnHeader>
                            <TableColumnHeader align="left" display={{ base: "none", lg: "revert" }}><strong>Outbound Number</strong>
                            </TableColumnHeader>
                            <TableColumnHeader align="left" display={{ base: "none", lg: "revert" }}>
                                <Tooltip content='Auto Answer'>
                                    <Text w={"10px"}>
                                        <AiFillPhone />
                                    </Text>
                                </Tooltip>
                            </TableColumnHeader>
                            <TableColumnHeader align="left" display={{ base: "none", lg: "revert" }}>
                                <Tooltip content='Allow Aux'>
                                    <Text w={"10px"}>
                                        <BsFillSpeakerFill />
                                    </Text>
                                </Tooltip>
                            </TableColumnHeader>
                            <TableColumnHeader align="left"></TableColumnHeader>
                            <TableColumnHeader align="left">

                            </TableColumnHeader>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Object.values(roles).map((role) => (
                            <RoleRow key={role.id} role={role} handleDelete={handleDelete} handleSetActive={handleSetActive} activeRoleId={activeRole} cb={getRoles} userId={props.user_id} second_cb={getActiveRole} />
                        ))}
                        {/* <NewRole userId={props.user_id} cb={getRoles}/> */}
                    </TableBody>
                    <NewRole userId={props.user_id} cb={getRoles} />
                </TableRoot>
                <div className="button-tray">

                    {/* <CreateRole worker_id={roles[0].worker_id} cb={getRoles} /> */}
                </div>
            </div>
        );
    }
}