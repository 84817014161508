import * as React from "react";
import { useState, useEffect } from "react";
import { useWebHelper } from "../../hooks/UseWebHelper";
import {
    Dialog, Fieldset, Input, Text, useDisclosure
} from '@chakra-ui/react';

import { useCache } from "../../context/CacheContext";
import { MdOutlineSettingsApplications } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import { isValid } from "date-fns";
import { RiEdit2Line } from "react-icons/ri";
import { Button } from "../ui/button";
import { DialogBody, DialogContent, DialogFooter, DialogRoot, DialogTitle, DialogTrigger } from "../ui/dialog";

interface IOutcome {
    id: string,
    friendly_id: string,
    name: string,
    description: string,
}
interface editOutcomeProps {
    outcome: IOutcome,
    cb: Function,
}

export default function EditOutcome(props: editOutcomeProps) {
    const { open, onOpen, onClose } = useDisclosure();
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState<any[]>([]);
    const CacheContext = useCache();
    //form fields
    const [id, setId] = useState<string>(props.outcome.id)
    const [friendlyId, setFriendlyId] = useState<string>(props.outcome.friendly_id);
    const [name, setName] = useState<string>(props.outcome.name);
    const [description, setDescription] = useState<string>(props.outcome.description);
    const [outcome, setOutcome] = useState<IOutcome>();

    const webHelpers = useWebHelper();
    const handleClickOpen = () => {
        onOpen();
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        if (friendlyId !== undefined && name !== undefined && description !== undefined) {
            const payload = {
                "id": id,
                "friendly_id": friendlyId,
                "name": name,
                "description": description,
            }
            webHelpers.PostAsync('/api/config/outcome', 'helios-api', payload).then((data: any) => {
                console.log("submit new outsome response : ", data)
            })
            props.cb();
            handleClose();
        }
    }
    const handleIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setId(e.currentTarget.value)
    }
    const handleFriendlyIdChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFriendlyId(e.currentTarget.value)
    }
    const handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
        setName(e.currentTarget.value)
    }
    const handleDescriptionChange = (e: React.FormEvent<HTMLInputElement>) => {
        setDescription(e.currentTarget.value)
    }

    return (
        <>
            <DialogRoot
                onOpenChange={onClose}
                aria-describedby="alert-dialog-slide-description"
                size={{ base: "lg", lg: "md" }}
            >
                <Dialog.Backdrop />
                <DialogTrigger>
                    <Button bg={"AbleBlue"} color={"white"} size={"sm"} marginLeft={{ base: "10px", lg: "30px" }}
                        _hover={{ bg: "AbleYellow", color: "white" }}
                        _active={{ bg: "#ECB500" }}
                        onClick={handleClickOpen}>
                        <RiEdit2Line /> Edit
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTitle>{"Edit Outcome: " + name}</DialogTitle>
                    <DialogBody padding={"10px"}>
                        <Fieldset.Root>
                            <Fieldset.HelperText>Please complete the form, ensuring all fields are correct, before submitting.</Fieldset.HelperText>
                            <Text as={"b"} marginLeft={"10px"}>Id</Text>
                            <Input paddingLeft={"10px"}  background={"backgroundDarkGrey"} marginBottom={"10px"} placeholder="Id" type="text" autoComplete="new-password" value={id} onChange={handleIdChange} disabled></Input>
                            <Text as={"b"} marginLeft={"10px"}>Friendly Id</Text>
                            <Input paddingLeft={"10px"}  background={"backgroundDarkGrey"} marginBottom={"10px"} placeholder="Friendly Id" type="text" autoComplete="new-password" value={friendlyId} onChange={handleFriendlyIdChange} ></Input>
                            <Text as={"b"} marginLeft={"10px"}>Name</Text>
                            <Input  paddingLeft={"10px"} background={"backgroundDarkGrey"} marginBottom={"10px"} type="text" autoComplete="new-password" placeholder="Name" value={name} onChange={handleNameChange} ></Input>
                            <Text as={"b"} marginLeft={"10px"}>Description</Text>
                            <Input paddingLeft={"10px"} background={"backgroundDarkGrey"} type="text" autoComplete="new-password" placeholder="Description" value={description} onChange={handleDescriptionChange} ></Input>
                        </Fieldset.Root>
                    </DialogBody>
                    <DialogFooter>
                        <Button onClick={() => handleSubmit()}
                            disabled={friendlyId === "" || name === "" || description === ""}
                            bg={"AbleBlue"}
                            color={"white"}
                            _hover={{ color: "AbleYellow" }}>Save Changes</Button>
                        <DialogTrigger>
                            <Button onClick={handleClose} colorScheme="red" marginLeft={"10px"}>Cancel</Button>
                        </DialogTrigger>
                    </DialogFooter>
                </DialogContent>
            </DialogRoot>
        </>
    );
}
