import { Group, Input, InputAddon } from "@chakra-ui/react"
import { BiSearch } from "react-icons/bi";

interface SearchBoxProps {
    search: string;
    handleSearchChange: Function
}

export const SearchBox = (props: SearchBoxProps) => {
    return (
        <Group attached display={{ base: "none", lg: "flex" }} marginLeft={"10px"} verticalAlign={"middle"}>
            <InputAddon justifyContent={"center"} height={"30px"} w={"30px"} marginTop={"8.5px"} borderColor={"backgroundLightGrey"} background={"backgroundLightGrey"}><BiSearch size={"18px"} /></InputAddon>
            <Input bg={"backgroundDarkGrey"} height={"30px"} placeholder={" Search Bar"} paddingLeft={"10px"} value={props.search} onChange={(e) => props.handleSearchChange(e)} width={"200px"} ></Input>
        </Group>
    )
}